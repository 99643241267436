import React from 'react'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withStyles, makeStyles } from '@material-ui/styles'
import Moment from "moment";

import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import OwnerCouponsTable from './OwnerCouponsTable'
import CreateCoupon from './CreateCoupon'
import CouponIcon from '@material-ui/icons/MonetizationOn'
import CancelIcon from "@material-ui/icons/Cancel";

import { useRbacStore } from '../../state'
import { useUserStore } from '../../state'
import { useGroupStore } from '../../state'


function createData(id, groupName, couponCode, couponType, value, maxUses, timesUsed, expires) {
  if (!groupName) {
    groupName = "All";
  }
  if (!maxUses) {
    maxUses = "None";
  }
  if (couponType == "dollars") {
    value = "$" + value;
  } else {
    value += "%";
  }
  expires = Moment(expires).format("D MMM YYYY");
  return { id, groupName, couponCode, couponType, value, maxUses, timesUsed, expires };
}


const useStyles = makeStyles(theme => ({
  createCoupon: {
  flex: 1,
  textAlign: "right",
  color: "#3096ff",
  marginBottom: "1em"
},
couponIcon: {
  marginRight: 5
},
textButton: {
  fontFamily: "'Fredoka One', cursive;",
  fontSize: 16,
},
buttonNew: {
  borderRadius: "18px",
  float: "right",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#3096FF",
  },
},
}))


const OwnerAdmin = props => {
  const classes = useStyles();
  const rbacStore = useRbacStore();
  const groupStore = useGroupStore()
  const userStore = useUserStore();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [groupIds, setGroupIds] = React.useState([])
  const [groupNames, setGroupNames] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [rowsFiltered, setRowsFiltered] = React.useState([])
  const [filterString, setFilterString] = React.useState("")
  const [loadingRows, setLoadingRows] = React.useState(true);

  const [newCoupon, setNewCoupon] = React.useState(false);

  function updateRowsFiltered() {
    const filtered = rows.filter(row => row.couponCode.toLowerCase().startsWith(filterString.toString().toLowerCase())) 
    setRowsFiltered(filtered)
    setPage(0)
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof(a[orderBy]) === 'string') {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = []
      rowsFiltered.forEach((n) => {
        newSelecteds.push(n.id)
      })
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => { 
    const { currentUser } = userStore;

    const getGroupIds = async () => {
      try {
        const tempGroups = await groupStore.getGroupIdsUser(currentUser.id, ["admin"]);
        setGroupIds(tempGroups);
        if (tempGroups.length === 0) {
          setLoadingRows(false);
        }

        const tempNames = await groupStore.getGroupNames(tempGroups);
        setGroupNames(tempNames);
      } catch (err) {
        console.log(err);
        setLoadingRows(false);
      }
    }
    
    if (currentUser && (currentUser.id > 0)) {
      getGroupIds();
    } else {
      setGroupIds([]);
    }
  }, [])

  useEffect(() => { 
    const getCouponData = async () => {
      const tempCoupons = (rbacStore.canPerformSysAdminActions
        ? await groupStore.getCoupons([], true)
        : await groupStore.getCoupons(groupIds)
      );

      const tempRows = []
      tempCoupons?.forEach((coupon, i) => (
          tempRows.push(createData(
            coupon.id, 
            coupon.group?.name, 
            coupon.couponCode, 
            coupon.couponType, 
            coupon.value, 
            coupon.maxUses, 
            coupon.timesUsed, 
            coupon.expires))
        ));
      setRows(tempRows)
      setLoadingRows(false)
    }
    if (groupIds?.length || rbacStore.canPerformSysAdminActions) {
      getCouponData()
    } else {
      setRows([])
    }
  }, [groupIds])

  useEffect(() => { 
    updateRowsFiltered()
  }, [rows, filterString])

  const toggleNewCoupon = () => (
    setNewCoupon(!newCoupon)
  );

  const refreshCoupons = async () => {
    toggleNewCoupon();
    let groupIdsTemp = groupIds;
    setGroupIds([-1]);
    setGroupIds(groupIdsTemp); // trigger refresh
  };

  return (
    <>
      {newCoupon?
        <>
          <Button
            className={classes.buttonNew}
            variant='contained'
            startIcon={<CancelIcon />}
            onClick={toggleNewCoupon}
          >
            Cancel
          </Button>
          <CreateCoupon
            groupStore={groupStore}
            rbacStore={rbacStore}
            groupIds={groupIds}
            groupNames={groupNames}
            onFinished={refreshCoupons}
            ></CreateCoupon>
        </>
      : 
        <>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h3" style={{ margin: 0 }}>Coupons</Typography>
        <div className={classes.createCoupon}>
        <Button
          className={classes.textButton}
          color='primary'
          onClick={toggleNewCoupon}
        >
          <CouponIcon className={classes.couponIcon} />
          Create Coupon
          </Button>
        </div>
      </Grid>
    <OwnerCouponsTable 
      setFilterString = {setFilterString}
      updateRowsFiltered = {updateRowsFiltered}
      rowsPerPage = {rowsPerPage}
      rowsFiltered = {rowsFiltered}
      page = {page}
      selected = {selected}
      order = {order}
      orderBy = {orderBy}
      handleSelectAllClick = {handleSelectAllClick}
      handleRequestSort = {handleRequestSort}
      isSelected = {isSelected}
      handleClick = {handleClick}
      handleChangePage = {handleChangePage}
      handleChangeRowsPerPage = {handleChangeRowsPerPage}
      stableSort = {stableSort}
      getComparator = {getComparator}
      loadingRows = {loadingRows}
    />
    <Grid>
    <Typography variant="h3">Payment Settings</Typography>
    <Typography variant="h4">Successful Purchase Message</Typography>
    </Grid>
    </>
    }
  </>
)
}

export default observer(OwnerAdmin)