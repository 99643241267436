// logic imports
import React, { useEffect, useState } from "react";
// global state
import { 
  useUserStore 
} from "../../state";
// material-ui components
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  dashboardBlock: {
    marginBottom: 48,
  },
  text: {
    color: "#515873",
    marginBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  poster: {
    maxWidth: "100%"
  }
}));

/**main info section on parent dashboard page*/
const ParentDashboardMainSection = (props) => {
  const {groupStore,
    termStore,
    nextTerm,
    nextDate,
    endDate,
    startTime,
    endTime
  } = props;
  const classes = useStyles();
  const {
    currentClub,
  } = groupStore;
  // hooks
  const userStore = useUserStore();
  // local state
  const [students, setStudents] = useState();
  const [posterUrl, setPosterUrl] = useState("");
  const [posterAlt, setPosterAlt] = useState("");

  useEffect(() => {
    const getData = async () => {
      let myStudents = [];
      if (nextTerm?.id) {
        let temp = await termStore.getTermStudentsGuardian(nextTerm.id, userStore.currentUser.id);
        if (temp.length > 0) {
          myStudents = temp[0].termMembers;
        }
      }
      setStudents(myStudents);

      let posters = await groupStore.findDocs(groupStore.currentClub?.id, "poster", true);
      if (posters.length) {
        let poster = await groupStore.getDoc(posters[0].id);
        if (poster?.url) {
          const res = await axios.get(poster.url);
          setPosterUrl(res.data);
        }
        setPosterAlt(poster?.description);
      }

    };

    getData();
  }, [nextTerm]);

  // render statement
  return (
    <>
      <div className={classes.dashboardBlock}>
        <div className={classes.section}>
{/*        <Typography className={classes.text}>hi {userStore.currentUser.firstName} thanks for logging in</Typography>
*/}
          <Typography className={classes.text} style={{paddingTop: 12,}}>
            Game Dev Clubs are like Code Clubs but specifically designed around coding 
            games and preparing kids for a career in the games industry.
          </Typography>
          <Typography className={classes.text}>{currentClub.description}</Typography>
        </div>

      <div className={classes.section}>
        <Typography variant={"h4"} className={classes.text}>My Students</Typography>
        <Typography className={classes.text}>You have {students?.length} student{!(students?.length == 1) && "s"} registered for this term.</Typography>
        {!(students?.length > 0) &&
          <Typography className={classes.text}>If you have students registered for a past or future term, 
          there is currently no way to view them. Only the current term is visible. Stay tuned for updates!</Typography>
        }
      </div>

      {(students?.length > 0) &&
        <div className={classes.section}>
          <ul className={classes.text}>
          {students.map((student, i) => <li key={i}>{student.user?.firstName} {student.user?.lastName} ({student.user?.username})</li>)}
          </ul>
        </div>
      }

      <div className={classes.section}>
        <Typography variant={"h4"} className={classes.text}>Club Details</Typography>
        <Typography className={classes.text}><b>Where:</b> {currentClub.location}</Typography>
        { startTime && endTime &&
          <Typography className={classes.text}><b>When:</b> {startTime} - {endTime} </Typography>
        }
        { nextDate && endDate && 
          <Typography className={classes.text}><b>From:</b> {nextDate} to {endDate}</Typography>
        }
      </div>

      <div className={classes.section}>
        <Typography variant={"h4"} className={classes.text}>Club Contact</Typography>
        <Typography className={classes.text}>{currentClub.contactName}</Typography>
        <a href={`mailto:${currentClub.contactEmail}`}>{currentClub.contactEmail}</a>
        <br /><a href={`tel:${currentClub.contactPhone}`}>{currentClub.contactPhone}</a>
      </div>

      {posterUrl && 
          <img src={posterUrl} alt={posterAlt} className={classes.poster} />
        }
    </div>
    </>

  );
};

export default ParentDashboardMainSection;
