import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 6,
    marginBottom: 6,
    borderRadius: 3,
    overflow: "hidden",
    "& .MuiInputLabel-root": {
      paddingTop: 8,
      paddingLeft: 8,
    },
    "& .MuiInputBase-root": {
      paddingTop: 8,
      paddingLeft: 8,
      paddingRight: 8,
    },   
    "& .MuiFormHelperText-root": {
      marginTop: 0,
    },
    "& .MuiInputBase-input:read-only": {
      color: "#334499"
    }
  }
}))

const TextInput = ({
  type,
  className,
  id,
  label,
  error,
  value,
  onChange,
  icon,
  adornment,
  endAdornment,
  fullWidth,
  variant,
  bgcolor = false,
  helperbgcolor = false,
  step = null,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.container} fullWidth={fullWidth} style={{backgroundColor: bgcolor ? bgcolor : null}}>
      {((type === 'date' || type === 'time') ? 
        <InputLabel htmlFor={id} error={!!error} shrink={true}>{label}</InputLabel>
      :
        <InputLabel htmlFor={id} error={!!error} >{label}</InputLabel>
      )}
      {icon}
      
      <Input
        id={id}
        variant={variant}
        className={clsx(className, "text-input")}
        type={type}
        value={value || ''}
        onChange={onChange}
        error={!!error}
        {...props}
        startAdornment={adornment && <InputAdornment position="start">
          {adornment}
        </InputAdornment>}
        endAdornment={endAdornment && <InputAdornment position="end">
          {endAdornment}
      </InputAdornment>}
    />
      <FormHelperText style={{backgroundColor: helperbgcolor ? helperbgcolor : null}}>{error && error}</FormHelperText>
    </FormControl>
  )
}

export default TextInput
