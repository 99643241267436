import React, { useEffect, useState, lazy, Suspense } from "react";

import Nav from "../common/Nav";
import Header from '../common/Header'
import Container from "@material-ui/core/Container";
import Loading from "../common/Loading";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { useGroupStore, useUserStore, useRbacStore } from "../../state";
import DashTabs from "../common/DashTabs";
import OwnerClubDash from "./OwnerClubDash.js";
const OwnerClubTutors = lazy(() => import("./OwnerClubTutors"));
const ClubTerms = lazy(() => import("./OwnerClubTerms"));
const ClubAdmin = lazy(() => import("./OwnerClubAdmin"));


const useStyles = makeStyles(theme => ({
  container: { ...theme.container },
  tabContent: {
    padding: "2em 0px",
    width: "100%",
  },

}))


const OwnerClub = (props) => {
  const {groupId} = props;
  const groupStore = useGroupStore();
  const userStore = useUserStore();
  const rbacStore = useRbacStore();

  const classes = useStyles();

  const [role, setRole] = React.useState();
  const [tabs, setTabs] = React.useState([{ label: "Dashboard", color: "#3096ff" }]);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [clubName, setClubName] = useState("");

  useEffect(() => {
    const getData = async () => {
      await userStore.login();

      if (groupStore.publicClubs.length) {
        groupStore.setCurrentClub(groupId)
      } else {
        await groupStore.getGroupById(groupId);
      }

      if (groupStore.currentClub) {
        setClubName(groupStore.currentClub.name + " - People/Database");
        let sRole = await groupStore.getCurrentRole();
        if (!sRole) sRole = "none";
        setRole(sRole);
        }
    };

    getData();
  }, []);

  useEffect(() => {
    const roleReady = async () => {
      rbacStore.getAccessControl();
      rbacStore.setClubRole(role);
      if (role != "admin" && rbacStore.canPerformSysAdminActions) {
        setRole("admin");
      }

      if (groupStore.currentClub) {
        let newTabs = [{ label: "Dashboard", color: "#3096ff" }]
        if (role == "admin" || role == "owner") {
          newTabs.push({ label: "Terms", color: "#cb38fd" });
          newTabs.push({ label: "Tutors", color: "#d81b60" });
          // newTabs.push({ label: "Marketing", color: "#1ae15c" });
          // newTabs.push({ label: "Payments", color: "#ff6d15" });
          newTabs.push({ label: "Edit", color: "#ffc000" });
        }
        setTabs(newTabs);
      }
    };

    if (role) roleReady();
  }, [role]);



  return (
    <>
      <div style={{ paddingTop: 70 }}>
        <Header title={clubName} backButton={true} />
      </div>
      <div className={classes.container}>
        <DashTabs
          onChange={(val) => setActiveTab(val)}
          value={activeTab}
          tabs={tabs}
        />
        <Container className={classes.tabContent}>
          {activeTab === "Dashboard" && 
            <OwnerClubDash 
            groupId={groupId} 
            role={role}
          />}
          {activeTab === "Tutors" &&
            <Suspense fallback={<Loading />}>
              <OwnerClubTutors groupStore={groupStore} />
            </Suspense>
          }        
          {activeTab === "Terms" &&
            <Suspense fallback={<Loading />}>
              <ClubTerms
                groupId={groupId}
                location={groupStore.currentClub?.location}
                memberLimit={groupStore.currentClub?.memberLimit ? groupStore.currentClub.memberLimit : 25}
              />
            </Suspense>
          }
          {activeTab === "Payments" && <div>[Payments goes here]</div>}
          {activeTab === "Edit" &&
            <Suspense fallback={<Loading />}>
              <ClubAdmin />
            </Suspense>
          }    
          {/*hidden until content has been created*/}
          {/* {activeTab === "Marketing" && <div>[Marketing goes here]</div>} */}
        </Container>
      </div>

    </>
  );
};

export default OwnerClub;
