// logic imports
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
// material-ui component imports
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
//  image imports
import happyGirlImg from '../../img/home/happy-girl.jpg';

// component scope styles
const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: "#ffe400",
    padding: "30px 0" 
  },
  title: {
    fontSize: 30,
    color: "#505971",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    textAlign: "center",
    margin: "20px 0",
  },
  text: {
    color: "#505971",
    textAlign: "center",
    maxWidth: '90vw',
    margin: 'auto',
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 45,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  image: {
    borderRadius: 10,
    [theme.breakpoints.only('xs')]: {
      width: 270, 
    },
    [theme.breakpoints.only('sm')]: {
      width: 220,
      marginRight: 5,     
    },
    [theme.breakpoints.up('md')]: {
      width: 330,     
      marginRight: 20,
    }
  },
  listItem: {
    [theme.breakpoints.only('sm')]: {
      marginBottom: 12,
    },
    [theme.breakpoints.up('md')]:{
      marginBottom: 15,
    },
  },
  listItemSubtitle: {
    color: "#399aff",
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('md')]:{
      fontSize: 18,
    },
  },
  listItemText: {
    color: "#505971", 
    marginLeft: 5,
    [theme.breakpoints.only('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]:{
      fontSize: 16,
    },
  },
  quoteFlexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0",
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    },
  },
  button: {
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    fontSize: 18,
    padding: "8px 30px",
    marginLeft: 30,
    background: "#399bff",
    "&:hover": {
      background: "#399bff",
    },
  },
}))

/** react component: displays get involved section for use on the home page */
function GetInvolved() {
  // hokes
  const classes = useStyles();
  // render statement
  return (
    <div className={classes.background}>
      <Typography className={classes.title}>Get involved</Typography>
      <Typography className={classes.text}>
        You don't need to be a coding export or join the community, there are
        lots of ways to get involved
      </Typography>
      <div className={classes.flexWrapper}>
        <img src={happyGirlImg} className={classes.image}/>
        <ul>
          <li className={classes.listItem}>
            <Typography className={classes.listItemSubtitle}>
              <b>Get paid</b>
              <span className={classes.listItemText}>
                to become a Game Dev Club tutor
              </span>
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography className={classes.listItemSubtitle}>
              <b>Start</b>
              <span className={classes.listItemText}>
                a Game Dev Club at your school
              </span>
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography className={classes.listItemSubtitle}>
              <b>Sponsor a student</b>
              <span className={classes.listItemText}>
                sponsor the gift of learning
              </span>
            </Typography>
          </li>
        </ul>
      </div>
      <div className={classes.quoteFlexWrapper}>
        <Button variant="contained" className={classes.button} href="mailto:dan@gamefroot.com">
          Contact us for more information
        </Button>
      </div>
    </div>
  )
}

export default GetInvolved
