import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";

import { makeStyles } from "@material-ui/core/styles";
import ClubCard from "./ClubCard";
import CreateClub from "./CreateClub.js";
import ParentViewClubCard from "../parent_dashboard/ParentViewClubCard";

import {
  useUserStore,
  useGroupStore,
  useTermStore,
} from "../../state";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FrownIcon from "@material-ui/icons/SentimentDissatisfied";

import PulseLoader from "react-spinners/PulseLoader";

const useStyles = makeStyles((theme) => ({
  container: theme.container,
  clubCardContainer: {
    marginTop: 24,
    marginBottom: 24,
  },
  buttonNew: {
    borderRadius: "18px",
    float: "right",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3096FF",
    },
  },
  clubsLoading: {
    textAlign: "center",
    padding: "2em",
  },
}));

const OwnerClubs = (props) => {
  const {groupId, loading, role} = props;

  const groupStore = useGroupStore();
  const userStore = useUserStore();
  const termStore = useTermStore();
  const history = useHistory();

  const classes = useStyles();
  const [clubsCurrent, setClubsCurrent] = React.useState([]);
  const [clubsArchived, setClubsArchived] = React.useState([]);
  const [newClub, setNewClub] = React.useState(groupId == "new");
  const [orgs, setOrgs] = React.useState([]);
  const [showingEmptyCurrent, setShowingEmptyCurrent] = React.useState(false);
  const [showingEmptyArchived, setShowingEmptyArchived] = React.useState(false);
  const canCreate = (role === "admin" || role === "owner");

  const toggleNewClub = () => {
    if (newClub && (groupId == "new")) {
      history.push(`/dashboard`);
    }
    setNewClub(!newClub);
  }

  useEffect(() => {
    const getData = async () => {
      let tempCurrent = groupStore.myClubs.filter(club => club.status == "active").reverse();
      setClubsCurrent(tempCurrent);
      let tempArchived = groupStore.myClubs.filter(club => club.status == "archived").reverse();
      setClubsArchived(tempArchived);

      const tempOrgs = new Set;
      groupStore.myClubs.forEach((club, i) => {
          tempOrgs.add(club.organiserName);
        });
      if (tempOrgs.size > 0) {
        setOrgs([...tempOrgs]);
      }
      (!tempCurrent.length) && setShowingEmptyCurrent(true);
      (!tempArchived.length) && setShowingEmptyArchived(true);
    };

    !loading && getData();
  }, [loading]);

  const clubCreated = (groupId) => {
    history.replace(`/dashboard/${groupId}`);
  }

  return (
    <>
      {newClub ? (
        <>
          <Button
            className={classes.buttonNew}
            variant="contained"
            startIcon={<CancelIcon />}
            onClick={toggleNewClub}
          >
            Cancel
          </Button>
          <CreateClub
            orgs={orgs}
            groupStore={groupStore}
            termStore={termStore}
            userStore={userStore}
            onFinished={clubCreated}
          ></CreateClub>
        </>
      ) : (
        <>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h3" style={{ margin: 0 }}>
              Active Clubs
            </Typography>
            {canCreate && 
            <Button
              className={classes.buttonNew}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={toggleNewClub} //() => history.push("/new-club")}
              disabled={groupStore.loadingClubs}
            >
              Add New Club
            </Button>
          }
          </Grid>

          <Grid container spacing={2} className={classes.clubCardContainer}>
            {loading && (
              <Grid item xs={12} className={classes.clubsLoading}>
                <PulseLoader
                  loading={true}
                  color="rgb(67, 196, 255)"
                  width={180}
                />
              </Grid>
            )}

            {clubsCurrent.map((club) => (
              <Grid item xs={12} sm={6} key={club.id}>
                {(club.role === "guardian"
                  ?
//                  <ParentViewClubCard
                  <ClubCard
                  {...club}
                    />
                  :
                    <ClubCard
                      {...club}
                   />
                )}
              </Grid>
            ))}

            {showingEmptyCurrent && (
              <ListItem disableGutters>
                <ListItemIcon>
                  <FrownIcon />
                </ListItemIcon>
                <ListItemText primary="No clubs found." />
              </ListItem>
            )}
          </Grid>

          <Typography variant="h3" style={{ margin: 0 }}>
            Past Clubs
          </Typography>

          <Grid container spacing={2} className={classes.clubCardContainer}>
            {clubsArchived.map((club) => (
              <Grid item xs={12} sm={6} key={club.id}>
                {(club.role === "guardian"
                  ?
                    <ParentViewClubCard
                      {...club}
                    />
                  :
                    <ClubCard
                      {...club}
                   />
                )}
              </Grid>
            ))}
            {loading && 
              <Grid item xs={12} className={classes.clubsLoading}>
                <PulseLoader
                  loading={true}
                  color="rgb(67, 196, 255)"
                  width={180}
                />
              </Grid>
            }

            {showingEmptyArchived &&
              <ListItem disableGutters>
                <ListItemIcon>
                  <FrownIcon />
                </ListItemIcon>
                <ListItemText primary="No clubs found." />
              </ListItem>
            }
          </Grid>
        </>
      )}
    </>
  );
};

export default observer(OwnerClubs);
