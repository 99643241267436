import React, { useState } from "react";
import { motion } from "framer-motion";
import { makeStyles, Slide, useScrollTrigger } from "@material-ui/core";

import AvatarDropdown from "./AvatarDropdown";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { ReactComponent as Logo } from "../../img/home/gdc_logo.svg";

import { useUserStore } from "../../state";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import { useBreakpoint } from "../../hooks/use-breakpoint";

const useStyles = makeStyles({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background: "#6433ff",
  },
  toolbar: {
    height: 70,
    maxWidth: 860,
    width: '90vw',
  },
  loginButton: {
    fontFamily: "'Fredoka One', cursive",
    color: "#FFF",
    backgroundColor: "#64dd17",
    "&:hover": {
      backgroundColor: "#64dd17",
    },
  },
  loginWrapper: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  tabs: {
    flexGrow: 1,
  },
  label: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    fontSize: "1.1rem",
  },
  selected: {
    borderRadius: 5,
    color: "white",
  },
  tab: {
    padding: "6px 20px",
    minWidth: 0,
    opacity: "1 !important",
  },
  logo: {
    width: 200,
    marginRight: 20,
  },
  menuIcon: {
    color: '#FFFFFF',
    fontSize: 42,
  },
});

const tabNames = {
  home: "home",
  find: "find",
  involve: "involve",
};

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({ target: window });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const NavBar = ({ hideLogo }) => {
  const [activeTab, setActiveTab] = useState("home");
  const history = useHistory();
  const classes = useStyles();
  const userStore = useUserStore();
  const breakpoint = useBreakpoint();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (newValue) => {
    setAnchorEl(null);
    if(newValue != undefined){
      handleChange(null, newValue);
    };
  };

  const handleLogin = () => {
    userStore.setShowLogin(true);
  };

  const handleChange = (e, newValue) => {
    if (newValue === tabNames.home) {
      history.push("/");
    }
    if (newValue === tabNames.find) {
      history.push("/groups");
    }
    if (newValue === tabNames.involve) {
      history.push("/involve");
    }
    setActiveTab(newValue);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar className={classes.root} position="absolute">
          <Toolbar className={classes.toolbar}>
            {breakpoint.small && 
              <motion.div whileTap={{ scale: 0.95 }}>
                <IconButton onClick={handleMenuOpen}>
                  <MenuIcon className={classes.menuIcon}/>
                </IconButton>
              </motion.div>
            }
            {!hideLogo && <Logo className={classes.logo} />}
            {!breakpoint.small &&
              <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={handleChange}
              >
                <Tab
                  className={classes.tab}
                  classes={{
                    wrapper: classes.label,
                    selected: classes.selected,
                  }}
                  label="Home"
                  value={tabNames.home}
                />
                <Tab
                  className={classes.tab}
                  classes={{
                    wrapper: classes.label,
                    selected: classes.selected,
                  }}
                  label="Find Clubs"
                  value={tabNames.find}
                />
                <Tab
                  className={classes.tab}
                  classes={{
                    wrapper: classes.label,
                    selected: classes.selected,
                  }}
                  label="Get Involved"
                  value={tabNames.involve}
                />
              </Tabs>
            }
            {(userStore.currentUser && userStore.currentUser.id)  ? (
              <AvatarDropdown
                username={userStore.currentUser.username}
                initial={userStore.currentUser.username.charAt(0).toUpperCase()}
              />
            ) : (
              <div className={classes.loginWrapper}>
                <motion.div whileTap={{ scale: 0.95 }}>
                  <Button
                    onClick={handleLogin}
                    className={classes.loginButton}
                    variant="contained"
                  >
                    Login / Register
                  </Button>
                </motion.div>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => (handleMenuClose(tabNames.home))}>Home</MenuItem>
        <MenuItem onClick={() => (handleMenuClose(tabNames.find))}>Find Clubs</MenuItem>
        <MenuItem onClick={() => (handleMenuClose(tabNames.involve))}>Get Involved</MenuItem>
      </Menu>
    </>
  );
};

export default  observer(NavBar);
