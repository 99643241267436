import React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import OwnerMembersTable from "./OwnerMembersTable";

import { useGroupStore, useRbacStore } from "../../state";

const OwnerMembers = () => {
  const groupStore = useGroupStore();
  const rbacStore = useRbacStore();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [rows, setRows] = React.useState([]);
  const [rowsFiltered, setRowsFiltered] = React.useState([]);
  const [filterString, setFilterString] = React.useState("");
  const [filteredUnique, setFilteredUnique] = React.useState(0);

  useEffect(() => {
    const getData = async () => {
      if (rbacStore.canPerformSysAdminActions) {
        await groupStore.getGroupMembers();
      } else {
        if (groupStore.groupIds?.length > 0) {
          await groupStore.getGroupMembers(groupStore.groupIds);
        }
      }
      formatData();
    };

    if (!groupStore.myClubMembers.length) getData();
    else formatData();
  }, []);

  const formatData = () => {
    const tempRows = groupStore.myClubMembers.map((member, i) => {
      const name = member.user?.firstName.length
        ? `${member.user.firstName} ${member.user.lastName}`
        : member.user?.username;
      const clubName = member.group?.name;

      return ({
        id: member.id,
        userId: member.userId,
        name: name || "No name",
        club: clubName || "[No club]",
        payed: member.payed == 1 ? true : false
      });
    });

    setRows(tempRows)
  };

  function updateRowsFiltered() {
    const filtered = rows.filter((row) =>
      row.name.toLowerCase().startsWith(filterString.toString().toLowerCase())
    );
    setRowsFiltered(filtered);
    const uniqueUsers = [];
    filtered.map((n) => {
      if (uniqueUsers.indexOf(n.userId) < 0) {
        uniqueUsers.push(n.userId);
      }
    });
    setFilteredUnique(uniqueUsers.length);
    setPage(0);
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === "string") {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [];
      rowsFiltered.map((n) => {
        if (newSelecteds.indexOf(n.userId) < 0) {
          newSelecteds.push(n.userId);
        }
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, userId) => {
    const selectedIndex = selected.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (userId) => selected.indexOf(userId) !== -1;

  useEffect(() => {
    updateRowsFiltered();
  }, [rows, filterString]);

  return (
    <OwnerMembersTable
      setFilterString={setFilterString}
      updateRowsFiltered={updateRowsFiltered}
      rowsPerPage={rowsPerPage}
      rowsFiltered={rowsFiltered}
      filteredUnique={filteredUnique}
      page={page}
      selected={selected}
      order={order}
      orderBy={orderBy}
      handleSelectAllClick={handleSelectAllClick}
      handleRequestSort={handleRequestSort}
      isSelected={isSelected}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      stableSort={stableSort}
      getComparator={getComparator}
      loadingRows={groupStore.loadingMembers}
    />
  );
};

export default observer(OwnerMembers);
