import { Loader } from "google-maps";

export const getLatLong = async (mapAddress) => {
  if (mapAddress) {
    const API_KEY = "AIzaSyBZImHOLK7jrB8m6zHOkprqTfatcMYlhO8";
    const loader = new Loader(API_KEY, { libraries: ["places"] });
    const google = await loader.load();
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode( { 'address': mapAddress, 'region': 'nz' }, function(results, status) {
        if (status == 'OK') {
          let latlong = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
          resolve(latlong);
        } else {
          console.log('Geocode was not successful for the following reason: ' + status)
          reject(false);
        }
      })
    }) 
  } else {
    return { lat: null, lng: null }
  }  
}