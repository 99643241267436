import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 200,
    background: "#a3d9ff",
    borderRadius: 10,
  },
  select: {
    borderRadius: 10,
  },
  input: {
    padding: "10px 16px",
    color: "#505971",
    textAlign: "left",
  },
}));

export default function Dropdown({ handleChange, value, children, name }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          name={name}
          className={classes.select}
          variant="outlined"
          MenuProps={{
            transformOrigin: {
              vertical: -41,
              horizontal: -15
            },
          }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          inputProps={{
            className: classes.input,
          }}
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
}
