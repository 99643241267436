// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

// material-ui component imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";

// custom component imports
import TextInput from "../common/TextInput";

// image/SVG imports
import AddIcon from "@material-ui/icons/Add";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import waveImage from "../../img/registration/waving.png";

// component scope styles
const useStyles = makeStyles(theme => ({
  infoContainer: {
    border: "2px solid #EAEFF5",
    borderRadius: 5,
    padding: 16,
    paddingBottom: 8,
    position: "relative",
    "& div": {
      marginBottom: 8,
    },
  },
  infoHeader: {
    position: "absolute",
    top: -10,
    left: 10,
    backgroundColor: "white",
    paddingLeft: 6,
    paddingRight: 6,
  },
  attendees: {
    "& .MuiGrid-item:nth-child(even)": {
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
        paddingBottom: 16,
      },    
    },
  },
  imageIcon: {
    width: 40, 
    height: "auto", 
    marginBottom: -10, 
    marginLeft: 12,
  },
  sponsorContainer: {
    backgroundColor: "#479FEA",
    overflow: "hidden",
    borderRadius: 10,
    marginTop: 5,
    height: 50,
  },
  sponsorTextContainer: {
    backgroundColor: "#FEF187",
    marginLeft: 6,
    paddingLeft: 20,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
  },
  sponsorIconWrapper: {
    marginLeft: "auto",
    marginRight: 10,
  },
  sponsorIcon: {
    fontSize: 30,
    marginTop: -2,
  },
}));

/**student details section of the member registration form */
const Attendees = ({ values, touched, errors, onChange, onBlur, count, countUpdate, spaces }) => {
  // hooks
  const classes = useStyles();

  // local state
  const [attendeeError, setAttendeeError] = React.useState("");
  const [sponsorDialogVisable, setSponsorDialogVisable] = React.useState(false);

  // event handlers
  const handleAddAttendee = () => {
    if (values.attendees.length < spaces) {
      onChange({ target: { name: `attendees[${values.attendees.length}]`, value: '' } });
      countUpdate(count + 1);
    } else {
      setAttendeeError("No more spaces are available for this term.");
    };
  };

  const handleSponsorStudent = () => {
    if(!sponsorDialogVisable){
      setSponsorDialogVisable(true);
      countUpdate(count + 1);
    };
  };

  const handleCancelSponsorStudent = () => {
    setSponsorDialogVisable(false);
    countUpdate(count - 1);
  };

  // render component
  return (
    <div className={classes.root}>
      <Typography variant="h3">
        1. Register Student/s
        <img src={waveImage} alt="waving hand" className={classes.imageIcon}/>
      </Typography>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Student Details</Typography>

          <Grid container spacing={2} className={classes.attendees}>
          { values.attendees.map((attendee, i) => (
            <React.Fragment key={"row_" + i}>
          <Grid item sm={6} xs={12} key={i + "_firstg"}>
              <TextInput
                key={i + "_first"}
                label="Attendee First Name"
                name={`attendees[${i}]['firstName']`}
                value={attendee['firstName']}
                onChange={onChange}
                onBlur={onBlur}
                error={touched.attendees && touched.attendees[i]?.firstName && errors.attendees && errors.attendees[i]?.firstName}
                variant="filled"
                fullWidth
                bgcolor="#eaeff5"
                helperbgcolor="#ffffff"
                />
            </Grid>
            <Grid item sm={6} xs={12} key={i + "_lastg"}>
            <TextInput
              key={i + "_last"}
              label="Last Name"
              name={`attendees[${i}]['lastName']`}
              value={attendee['lastName']}
              onChange={onChange}
              onBlur={onBlur}
              error={touched.attendees && touched.attendees[i]?.lastName && errors.attendees && errors.attendees[i]?.lastName}
              variant="filled"
              fullWidth
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              />
          </Grid>
          </React.Fragment>
          )) }
            {sponsorDialogVisable &&
              <Grid item xs={12} >
                <div className={classes.sponsorContainer}>
                  <div className={classes.sponsorTextContainer}> 
                    <Typography variant="subtitle1">
                      You're choosing to sponsor another attendee to join a Game Dev Club 
                    </Typography>
                      <IconButton 
                        onClick={handleCancelSponsorStudent}
                        className={classes.sponsorIconWrapper}
                      >
                        <CancelOutlined color="primary" className={classes.sponsorIcon} />
                      </IconButton>
                  </div>
                </div>
              </Grid>
            }
            <Grid item xs={12} key="button">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddAttendee}
              >
                Add Attendee
              </Button>
{/* commented out until business decision has been made to continue with sponsorship or not */}
{/*              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleSponsorStudent}
                style={{marginLeft: 8,}}
              >
                Sponser a student
              </Button>
*/}
              <FormHelperText>{attendeeError && attendeeError}</FormHelperText>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );

};

export default Attendees;
