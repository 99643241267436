import React from "react"
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Login from "./Login";

import { useUserStore } from "../../state";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    marginBottom: 0,
    fontSize: "2rem",
    WebkitFontSmoothing: "antialiased",
  },
  header: {
    color: "#FFF",
    marginTop: 30,
    marginBottom: 30,
  },
  iconButton: {
    color: "#FFF",
    padding: 5,
    top: 5,
    right: 5,
    position: "absolute",
  },
  waveEmoji: {
    width: 35,
    marginRight: 10,
  },
  closeIcon: { color: "#FFF", width: 30, height: 30 },
}));

export default observer(function WidgetContainer() {
  const userStore = useUserStore();
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{ style: { backgroundColor: "#3899ff", zIndex: 100 } }}
      fullScreen
      open={userStore.showLogin}
      onClose={() => userStore.setShowLogin(false)}
    >
      <Container style={{ maxWidth: 860 }}>
        <div className={classes.header}>
          <Typography className={classes.title}>Log in to Game Dev Club</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              Good to see you! Log in with social media or your Gamefroot
              account.
            </Typography>
          </div>
        </div>
        <Login />
      </Container>
      <IconButton onClick={() => userStore.setShowLogin(false)} className={classes.iconButton}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </Dialog>
  );
});
