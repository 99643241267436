// logic imports
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { motion } from 'framer-motion'
import { useBreakpoint } from '../../hooks/use-breakpoint'

// material-ui component imports
import Typography from '@material-ui/core/Typography'

// image imports
import platformerImg from '../../img/home/platformer 2x.jpg'
import spaceShooterImg from '../../img/home/space shooter 2x.jpg'
import topDownImg from '../../img/home/top-down 2x.jpg'
import codingNPCImg from "../../img/home/Coding NPCs.jpg"

// component scope styles
const useStyles = makeStyles(theme => ({
  contentSection: {
    padding: "30px 0",
    textAlign: "center",
  },
  title: {
    fontSize: 30,
    color: "#505971",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    textAlign: "center",
    margin: "20px 0",
  },
  text: {
    width: "80%",
    color: "#505971",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
      width: "80%",
    },
  },
  contentFlexWrapper: {
    margin: "30px 0",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    }
  },
  contentImage: {
    maxWidth: 440,
    width: "calc(50% - 50px)",
    borderRadius: 10,
    position: "relative",
    [theme.breakpoints.only('xs')]: {
      width: "80vw",
      marginRight: 0,
    }, 
    [theme.breakpoints.only('sm')]: {
      width: "calc(50% - 10px)",
    },
  },
  rolloverWrapper: {
    opacity: 0,
    borderRadius: 15,
    background: "#372285CC",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "calc(100% - 4px)",
  },
  rolloverTitle: {
    paddingTop: 20,
    paddingLeft: 20,
    textAlign: 'left',
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    textShadow: '#372285 2px 3px',
    [theme.breakpoints.only('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
  },
  rolloverBody: {
    textAlign: 'left',
    color: "#FFF",
    textShadow: '#372285 2px 3px',
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.only('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  }
}))

export default function Content() {
  const classes = useStyles()
  const breakpoint = useBreakpoint()

  return (
    <div className={classes.contentSection}>
      <Typography className={classes.title}>
        Game Dev Club Content
      </Typography>
      <Typography className={classes.text}>
      Game Dev Club students learn how problem solving skills by coding their own 2D games,
       animations and Digital stories.      
      </Typography>
      <div style={{ margin: "40px 0" }}>
        <div className={classes.contentFlexWrapper}>
          <div
            className={classes.contentImage}
            style={!breakpoint.extraSmall? { marginRight: 25 } : { marginBottom: 30 }}
          >
            <img
              style={{ width: "100%", height: "100%", borderRadius: 15 }}
              src={platformerImg}
            />
            <motion.div
              initial={{ opacity: 0 }}
              className={classes.rolloverWrapper}
              whileHover={{ opacity: 1, }}
              whileTap={{ opacity: 1, }}
            >
              {!breakpoint.small && 
                <Typography className={classes.rolloverTitle}>
                  Platformer Game
                </Typography>
              }
              <Typography className={classes.rolloverBody}>
                Club members learn to code a classic Platformer game like Super Mario Bros 
                but set in Aotearoa, New Zealand.
              </Typography>
              {!breakpoint.small && 
                <Typography className={classes.rolloverBody}>
                  Learning outcomes include computation thinking, authentic game design and 
                  creative coding skills.
                </Typography>
              }
            </motion.div>
          </div>
          <div className={classes.contentImage}>
            <img
              style={{ width: "100%", height: "100%", borderRadius: 15 }}
              src={topDownImg}
            />
            <motion.div
              initial={{ opacity: 0 }}
              className={classes.rolloverWrapper}
              whileHover={{ opacity: 1, }}
              whileTap={{ opacity: 1, }}
            >
              {!breakpoint.small && 
                <Typography className={classes.rolloverTitle}>
                  Top-Down Game
                </Typography>
              }
              <Typography className={classes.rolloverBody}>
                Club members learn to code a Top-down down video game based on classic 
                video games like Zelda. 
              </Typography>
              {!breakpoint.small && 
                <Typography className={classes.rolloverBody}>
                  Learning outcomes include computation thinking, authentic game design and 
                  creative coding skills.
                </Typography>
              }
            </motion.div>
          </div>
        </div>

        <div className={classes.contentFlexWrapper}>
          <div
            className={classes.contentImage}
            style={!breakpoint.extraSmall? { marginRight: 25 } : { marginBottom: 30 }}
          >
            <img
              style={{ width: "100%", height: "100%", borderRadius: 15 }}
              src={codingNPCImg}
            />
            <motion.div
              initial={{ opacity: 0 }}
              className={classes.rolloverWrapper}
              whileHover={{ opacity: 1, }}
              whileTap={{ opacity: 1, }}
            >
              {!breakpoint.small && 
                <Typography className={classes.rolloverTitle}>
                  Coding NPCs
                </Typography>
              }
              <Typography className={classes.rolloverBody}>
                Club members learn how to create a mixture of fun Non Playable Characters for your next Platformer puzzle game.
              </Typography>
              {!breakpoint.small && 
                <Typography className={classes.rolloverBody}>
                  Learning outcomes include computation thinking, authentic game design and 
                  creative coding skills.
                </Typography>
              }
            </motion.div>
          </div>
          <div className={classes.contentImage}>
            <img
              style={{ width: "100%", height: "100%", borderRadius: 15 }}
              src={spaceShooterImg}
            />
            <motion.div
              initial={{ opacity: 0 }}
              className={classes.rolloverWrapper}
              whileHover={{ opacity: 1, }}
              whileTap={{ opacity: 1, }}
            >
              {!breakpoint.small && 
                <Typography className={classes.rolloverTitle}>
                  Space Shooter
                </Typography>
              }
              <Typography className={classes.rolloverBody}>
                Club members learn to code a classic Space Shooter video game for their 
                portfolio and learn about the special effects used in video games. 
              </Typography>
              {!breakpoint.small && 
                <Typography className={classes.rolloverBody}>
                  Learning outcomes include computation thinking, authentic game design and 
                  creative coding skills.
                </Typography>
              }
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
