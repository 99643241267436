import { client } from "./client";

/**
 * send an email
 */
export const createDoc = (data) => client.service("documents").create(data);

export const getDoc = (id) => client.service("documents").get(id);

export const deleteDoc = (id) => client.service("documents").remove(id);

export const findDocs = (id, tag, latest) => client.service("documents").find({
  query: Object.assign({
    groupId: id,
    tag: tag,
    attributes: ["id", "filename", "ext", "description"],
    
  },
  latest && {
    $limit: 1, 
    $sort: {
      createdAt: -1,
    },
  }),
});




