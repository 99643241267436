import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 6,
    marginBottom: 6,
    borderRadius: 3,
    overflow: "hidden",
    "& .MuiInputLabel-root": {
      paddingTop: 8,
      paddingLeft: 8,
    },
    "& .MuiInputBase-root": {
      paddingTop: 8,
      paddingLeft: 8,
    },   
    "& .MuiFormHelperText-root": {
      marginTop: 0,
   }
  }
}))

const TextAreaInput = ({
  className,
  id,
  type,
  label,
  error,
  rows,
  rowsMax,
  value,
  onChange,
  variant,
  disabled,
  fullWidth,
  bgcolor = false,
  ...props
}) => {
  const classes = useStyles()

  return (
  <FormControl className={classes.container} fullWidth={fullWidth} style={{backgroundColor: bgcolor ? bgcolor : null}}>
    <TextField
      id={id}
      disabled={disabled}
      className={clsx(className, "text-input")}
      label={label}
      type={type}
      variant={variant}
      error={!!error}
      rows={rows || 2}
      rowsMax={rowsMax || 4}
      multiline
      value={value || ''}
      onChange={onChange}
      {...props}
    />
    {
      error &&
      <FormHelperText className='text-danger'>{error}</FormHelperText>
    }
  </FormControl>
  )
}

export default TextAreaInput
