import { makeAutoObservable } from 'mobx'

import { fetchAccessControl } from '../api/auth'

class RbacStore {
  ac = undefined
  clubAc = undefined
  role = 'none'
  clubRole = 'none'

  constructor() {
    makeAutoObservable(this)
  }

  // <--- Computed --->
  get canPerformSysAdminActions () {
    return this.role === 'admin'
  }

  get userUnaffiliatedWithClub () {
    return (this.clubRole === 'none') && this.role !== 'admin'
  }

  get canUserViewClubMembers () {
    return this.clubAc?.can(this.clubRole).readOwn('members').granted || this.canPerformSysAdminActions
  }

  get canUserViewClubGames () {
    return this.clubAc?.can(this.clubRole).readOwn('games').granted || this.canPerformSysAdminActions
  }

  get canUserAssignLessons () {
    return this.clubAc?.can(this.clubRole).updateOwn('lessons').granted || this.canPerformSysAdminActions
  }

  get canUserViewEventDetails () {
    return this.clubRole !== 'none' || this.canPerformSysAdminActions
  }

  get canUserCreateClubs () {
    return this.ac?.can(this.role).createOwn('code_clubs').granted || this.canPerformSysAdminActions
  }

  get canUserViewAdminTab () {
    return this.clubAc?.can(this.clubRole).readOwn('admin_tab').granted || this.canPerformSysAdminActions
  }

  get canUserCreateMembers () {
    return this.clubAc?.can(this.clubRole).createOwn('members').granted || this.canPerformSysAdminActions
  }

 get canUserViewClubDrafts () {
    return this.clubAc?.can(this.clubRole).readOwn('drafts').granted || this.canPerformSysAdminActions
  }

  get canUserViewSeats () {
    return this.clubAc?.can(this.clubRole).readOwn('seats').granted || this.canPerformSysAdminActions
  }

  // <--- Action --->

  setRole (role) {
    this.role = role
  }

  setClubRole (role) {
    this.clubRole = role
  }

  // <--- Flow --->
  async getAccessControl () {
    if (!this.ac && !this.clubAc) {
      try {
        const {ac, clubAc} = await fetchAccessControl()
        this.ac = ac
        this.clubAc = clubAc
      } catch (err) {
        this.accessControlError = err
      }
    }
  }
}

export default RbacStore