import { makeAutoObservable } from "mobx";

import {
  fetchGroupsPublic,
  fetchGroupMembers,
  fetchGroupTermMembers,
  fetchGroupIdsByUser,
  fetchGroupById,
  fetchPublicGroupById,
  fetchUserRole,
  isMember,
  isGuardian,
  fetchGroups,
  fetchAllGroups,
  fetchGroupNames,
  fetchGroupCoupons,
  fetchAllCoupons,
  fetchCodeCoupons,
  createCoupon,
  createGroup,
  editGroup,
  createVenue,
  createMember,
  updateMembersRoles,
} from "../api/groups";

import { createUser, createSeat } from "../api/user";

import { createDoc, findDocs, getDoc, deleteDoc } from "../api/documents";

import { fetchProjectsByUser } from "../api/games";

const INITIAL_FETCH_AMOUNT = 999;
const EXTENSION_FETCH_SIZE = 20;

class GroupStore {
  initialLoad = true;
  size = INITIAL_FETCH_AMOUNT;

  loadingClubs = false;
  clubsError;

  myClubs = [];
  publicClubs = [];

  creatingClub = false;
  createClubError;
  createClubSuccess;

  /* Club */
  loadingClub = false;
  currentClub;
  outOfClubs = false;
  search = "";
  lastSearch = "";

  loadingMembers = false;
  membersError = "";
  currentClubMembers = [];
  myClubMembers = [];

  loadingGames = false;
  gamesError;
  gameFilter = "members";
  currentClubGames = [];
  currentClubDrafts = [];
  filteredClubGames = [];
  showDrafts = false;

  editingClub = false;
  editClubError;
  editClubSuccess;

  changingClubStatus = false;
  changeClubStatusSuccess;
  changeClubStatusError;

  editingClubRoles = false;
  editClubRolesSuccess;
  editClubRolesError;

  removingMembers = false;
  removeMembersError;
  removeMembersSuccess;

  authorizingPayment = false;
  authorizingPaymentSuccess;
  authorizingPaymentError;

  creatingMember = false;
  creatingMemberError;
  creatingMemberSuccess;

  uploadingDoc = false;
  docError;
  docSuccess;


  constructor(RootStore) {
    makeAutoObservable(this);
    this.rootStore = RootStore;
  }

  // <-- Computed --->
  get existingOrganisations() {
    return [
      ...new Set(
        this.myClubs
          .filter((club) => ["admin"].includes(club.role))
          .map((club) => club.organiserName)
      ),
    ];
  }

  get clubAdmins() {
    return this.currentClubMembers.filter((member) => member.role === "admin");
  }

  get clubMembers() {
    return this.currentClubMembers.filter((member) => member.role === "member");
  }

  get freeLimit() {
    return this.currentClubMembers.length === 3;
  }

  get groupIds() {
    return this.myClubs.map((group) => group.id);
  }

  // <--- Actions --->

  resetSelection() {
    this.publicClubs = [];
    this.getClubsPublic();
    this.size = this.amount;
    this.lastSearch = this.search;
  }

  resetStatus(type) {
    return {
      club: () => {
        this.currentClubDrafts = undefined;
        this.initialLoad = true;
        this.showDrafts = false;
        this.currentClubGames = [];
        this.currentClubMembers = [];
        this.expiredClub = undefined;
        this.clubError = undefined;
      },
      clubs: () => {
        this.clubsError = undefined;
        this.isOwner = false;
        this.isTutor = false;
      },
      roles: () => {
        this.editClubRolesSuccess = undefined;
        this.editClubRolesError = undefined;
      },
      edit: () => {
        this.editClubSuccess = undefined;
        this.editClubError = undefined;
      },
      status: () => {
        this.changingClubStatus = false;
        this.changeClubStatusError = undefined;
        this.changeClubStatusSuccess = undefined;
      },
      create: () => {
        this.createClubSuccess = undefined;
        this.createClubError = undefined;
      },
      payment: () => {
        this.authorizingPaymentError = undefined;
        this.authorizingPaymentSuccess = false;
      },
      games: () => {
        this.gamesError = undefined;
      },
      members: () => {
        this.membersError = undefined;
      },
      createMember: () => {
        this.creatingMemberError = undefined;
        this.creatingMemberSuccess = undefined;
      },
      remove: () => {
        this.removeMembersError = undefined;
        this.removeMembersSuccess = undefined;
      },
    }[type]();
  }

  // checkCacheForClub(id) {
  //   id = parseInt(id, 10);
  //   const searchPublic = this.publicClubs.find((club) => club.id === id);
  //   const searchPrivate = this.myClubs.find((club) => club.id === id);
  //   return searchPrivate || searchPublic;
  // }

  filterGames() {
    const games = this.showDrafts
      ? this.currentClubDrafts
      : this.currentClubGames;
    const admins = this.currentClubMembers
      .filter((member) => member.role === "admin")
      .map((admin) => admin.userId);
    if (this.gameFilter === "members") {
      this.filteredClubGames = admins.length
        ? games.filter((game) => !admins.includes(game.owner))
        : games;
    } else if (this.gameFilter === "admin") {
      this.filteredClubGames = admins.length
        ? games.filter((game) => admins.includes(game.owner))
        : [];
    } else {
      this.filteredClubGames = games;
    }
  }

  toggleShowDrafts(value) {
    this.showDrafts = value;
    if (!this.currentClubDrafts && this.showDrafts) {
      this.getClubGames();
    } else {
      this.filterGames();
    }
  }

  setGameFilter(data) {
    this.gameFilter = data;
  }

  setSearch(val) {
    this.search = val;

    if (val === "") this.doSearch();
  }

  doSearch() {
    if (this.search !== this.lastSearch) {
      this.lastSearch = this.search;
      this.resetSelection();
    }
  }

  setCurrentClub(groupId) {
    this.currentClub = this.myClubs.find(club => club.id == groupId);
    if (!this.currentClub) {
      this.currentClub = this.publicClubs.find(club => club.id == groupId);
    }
  }

  // <--- Flow --->

  async createClub(data) {
    this.creatingClub = true;
    this.resetStatus("create");
    try {
      data.type = "gdc";
      this.createClubSuccess = await createGroup(data);
      this.createClubSuccess.groupTerms = [];
      this.createClubSuccess.role = "admin";
      this.myClubs.push(this.createClubSuccess);
      if (this.publicClubs.length > 0) {
        this.publicClubs.push(this.createClubSuccess);
      }
      // this.filterClubs();
    } catch (err) {
      this.createClubError = err;
      console.error(err);
    } finally {
      this.creatingClub = false;
    }
  }

  async getClubsPublic() {
    this.loadingClubs = true;
    this.outOfClubs = false;
    this.resetStatus("clubs");

    try {
      const { data } = await fetchGroupsPublic({
        searchQuery: this.lastSearch,
        limit: INITIAL_FETCH_AMOUNT,
      });
      this.publicClubs = data;

      if (data.length < INITIAL_FETCH_AMOUNT) this.outOfClubs = true;
    } catch (err) {
      console.error(err);
      this.outOfClubs = true;
      this.clubsError =
        "Could not get clubs.  Please refresh the page and try again.";
    } finally {
      this.loadingClubs = false;
    }
  }

  async extendClubsPublic() {
    this.loadingClubs = true;

    try {
      const { data } = await fetchGroupsPublic({
        limit: EXTENSION_FETCH_SIZE,
        offset: this.size,
        searchQuery: this.lastSearch,
      });

      if (!data.length) {
        this.clubsError = "Out of Clubs";
        this.outOfComments = true;
        return;
      }

      this.publicClubs = [...this.publicClubs, ...data];
      this.filterClubs();
      this.size += EXTENSION_FETCH_SIZE;

      if (this.publicClubs.length < this.size) this.outOfClubs = true;
    } catch (err) {
      console.error(err);
      this.outOfClubs = true;
      this.clubsError =
        "Could not get clubs.  Please refresh the page and try again.";
    } finally {
      this.loadingClubs = false;
    }
  }

  async getMyClubs(groupIdsOwner, groupIdsTutor, groupIdsMember, groupIdsGuardian, isSysAdmin) {
    this.loadingClubs = true;
    this.resetStatus("clubs");
    try {
      var result;
      if (isSysAdmin) {
        result = await fetchAllGroups();
      } else {
        let groupIds = new Set([...groupIdsOwner, ...groupIdsTutor, ...groupIdsMember, ...groupIdsGuardian])
        if (groupIds.size > 0) {
          result = await fetchGroups([...groupIds]);
        } else {
          result.data = [];
        }
      }
      this.myClubs = result.data;
      this.myClubs.forEach(club => {
        if (isSysAdmin) {
          club.role = "admin";
          this.isOwner = true;
        } else if (groupIdsOwner.includes(club.id)) {
          club.role = "owner";
          this.isOwner = true;
        } else if (groupIdsTutor.includes(club.id)) {
          club.role = "tutor";
          this.isTutor = true;
        } else if (groupIdsMember.includes(club.id)) {
          club.role = "member";
        } else if (groupIdsGuardian.includes(club.id)) {
          club.role = "guardian";
        } else {
          club.role = "none";
        }
      });
    } catch (err) {
      this.clubsError = err;
    } finally {
      this.loadingClubs = false;
    }
  }

  async getGroupById(groupId) {
    this.loadingClub = true;
    try {
      this.currentClub = this.rootStore.userStore.currentUser?.id ? await fetchGroupById(groupId) : await fetchPublicGroupById(groupId);
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get owner clubs.  Please refresh the page and try again.";
    } finally {
      this.loadingClub = false;
    }
  }

  getNextTerm() {
    if (this.currentClub?.groupTerms?.length) {
      const today = new Date();
      const nextTerm = this.currentClub.groupTerms.find(term => {
        const termStart = new Date(term.termSessions[0]?.sessionDate);
        return (termStart >= today);
      })
      return nextTerm;
    }

  }

  async getCurrentRole() {
    try {
      if (this.rootStore.userStore.currentUser?.id && this.currentClub && (!this.currentClub.role || (this.currentClub.role === "none"))) {
        let result = await fetchUserRole(this.currentClub.id, this.rootStore.userStore.currentUser.id);
        if (result.data?.length) {
          this.currentClub.role = result.data[0].role;
        } else {
          let result = await isMember(this.currentClub.id, this.rootStore.userStore.currentUser.id);
          if (result.data?.length) {
            this.currentClub.role = "member";
          } else {
            let result = await isGuardian(this.currentClub.id, this.rootStore.userStore.currentUser.id);
            if (result.data?.length) {
              this.currentClub.role = "guardian";
            } else {
              this.currentClub.role = "none";
            }
          }
        }
      }
      return this.currentClub?.role;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get role for current club. Please refresh the page and try again.";
    }
  }

// get IDs of groups for which user is owner or tutor
async getGroupIdsUser(userId, roles) {
  try {
    const { data } = await fetchGroupIdsByUser(userId, roles);
    var groupIds = [];
    data.forEach((group, i) => groupIds.push(group.groupId));
    return groupIds;
  } catch (err) {
    console.error(err);
    this.membersError =
      "Could not get club IDs. Please refresh the page and try again.";
  }
}

async getGroupNames(groupIds) {
  try {
    const { data } = await fetchGroupNames(groupIds);
    var groupNames = [];
    data.forEach((group) => groupNames.push(group.name));
    return groupNames;
  } catch (err) {
    console.error(err);
  }
}


  async getAllGroups() {
    this.loadingClubs = true;
    try {
      const { data } = await fetchAllGroups();
      this.myClubs = data;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get clubs.  Please refresh the page and try again.";
    } finally {
      this.loadingClubs = false;
    }
  }


  async getCoupons(groupIds, isAdmin = false, excludeExpired = true) {
    try {
      let result;
      if (groupIds.length > 0) {
        result = await fetchGroupCoupons([...groupIds, 0], excludeExpired);
      } else if (isAdmin) {
        result = await fetchAllCoupons(excludeExpired);
      } else {
        return [];
      }
      const { data } = result;
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  

  async checkCoupon(code, groupId) {
    try {
      const { data } = await fetchCodeCoupons(code, [groupId, 0], true);
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  

  async isCouponUnique({code, groupId}) {
    try {
      let groupIds = [];
      if (groupId > 0) {
        groupIds.push(0);
        groupIds.push(groupId);
      }
      const { data } = await fetchCodeCoupons(code, groupIds, true);
      return data.length == 0;
    } catch (err) {
      console.error(err);
    }
  }
  
  async createCoupon(data) {
    try {
      return await createCoupon(data);
    } catch (err) {
      console.error(err);
    }
  }

  async createVenue(data) {
    try {
      return await createVenue(data);
    } catch (err) {
      console.error(err);
    }
  }


  async editClub(id, data) {
    this.editingClub = true;
    this.resetStatus("edit");
    try {
      const updatedClub = await editGroup(id, data);
      this.editClubSuccess = updatedClub;
      if (this.currentClub) {
        this.currentClub = Object.assign(this.currentClub, updatedClub);
      } else {
        this.currentClub = updatedClub;
      }
      const i =
        this.myClubs.indexOf((club) => club.id === id) ||
        this.publicClubs.indexOf((club) => club.id === id);
      this.myClubs = Object.assign([], this.myClubs, {
        [i]: Object.assign(this.currentClub, updatedClub),
      });
      // this.filterClubs();
    } catch (err) {
      this.editClubError = err;
      console.error(err);
    } finally {
      this.editingClub = false;
    }
  }

  async createMember(payload, subscriptionId) {
    this.resetStatus("createMember");
    this.creatingMember = true;
    try {
      const user = await createUser(
        Object.assign(
          { ...payload, features: "code_club" },
          Number.isInteger(subscriptionId) && { subscriptionType: "pro" }
        )
      );
      const member = await createMember(user.id, this.currentClub.id);
      const memberData = { ...user, ...member };
      this.currentClubMembers.unshift(memberData);
      if (Number.isInteger(subscriptionId)) {
        const seat = await createSeat({
          userId: user.id,
          subscriptionId,
        });
        return seat;
      }
    } catch (err) {
      this.createMemberError = err;
      throw err;
    } finally {
      this.creatingMember = false;
    }
  }

  async getGroupMembers(groupIDs, includeRemoved) {
    this.loadingMembers = true;
    this.outOfMembers = false;

    try {
      const { data } = await fetchGroupMembers(groupIDs, includeRemoved);
      this.myClubMembers = data;

    } catch (err) {
      console.error(err);
      // this.outOfMembers = true;
      this.membersError =
        "Could not get members.  Please refresh the page and try again.";
    } finally {
      this.loadingMembers = false;
    }
  }

  async getCurrentGroupMembers(groupID, includeRemoved) {
    this.loadingMembers = true;
    this.outOfMembers = false;
    try {
      const { data } = await fetchGroupMembers(groupID, includeRemoved);
      this.currentClubMembers = data;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get members.  Please refresh the page and try again.";
    } finally {
      this.loadingMembers = false;
    }
  }

  async getGroupTermMembers(groupIDs) {
    try {
      const { data } = await fetchGroupTermMembers(groupIDs);
      let members = [];
      data.forEach((term) => {
        term.termMembers?.forEach((member) => {
          if (!members.find(existing => (existing.userId == member.userId))) {
            members.push(member.user);
          }
        });
      });
      return members;

    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get members.  Please refresh the page and try again.";
    } finally {
    }
  }


  async getClubGames() {
    this.loadingGames = true;
    this.filteredClubGames = [];
    const ids =
      this.currentClubMembers &&
      this.currentClubMembers.map((member) => member.userId);
    this.resetStatus("games");
    this.resetStatus("status");
    if (this.currentClubMembers.length) {
      try {
        const result = await fetchProjectsByUser(
          ids,
          this.showDrafts,
          !this.showDrafts
        );
        if (this.showDrafts) {
          this.currentClubDrafts = result;
        } else {
          this.currentClubGames = result;
        }

        this.filterGames(this.gameFilter);
      } catch (err) {
        this.gamesError = err;
        console.error(err);
      } finally {
        this.loadingGames = false;
      }
    }
  }

  async updateGrades(gradeLists) {
    const incomingLists = Object.keys(gradeLists);

    await this.editClub(this.currentClub.id, gradeLists);

    incomingLists.forEach((list) => {
      this.currentClub[list] = gradeLists[list];
    });
  }

  async changeClubStatus(status) {
    this.changingClubStatus = true;
    const id = this.currentClub.id;
    this.resetStatus("status");
    try {
      this.changeClubStatusSuccess = await editGroup(id, { status });
      if (["deleted", "archived"].includes(status)) {
        // await changeEventStatusByClub(id, status);
        this.myClubs = this.myClubs.filter((club) => club.id !== id);
        this.publicClubs = this.publicClubs.filter((club) => club.id !== id);
        // this.filterClubs();
      }
    } catch (err) {
      this.changeClubStatusError =
        "Failed to delete club, please refresh and try again.";
      console.error(err);
    } finally {
      this.changingClubStatus = false;
    }
  }

  async editClubRoles({ admins, members }) {
    this.editingClubRoles = true;
    this.resetStatus("roles");
    const adminChanges = admins.filter((admin) => {
      return (
        this.currentClubMembers.find((member) => member.userId === admin.userId)
          .role !== "admin"
      );
    });
    const memberChanges = members.filter((member) => {
      return (
        this.currentClubMembers.find(
          (previousMember) => previousMember.userId === member.userId
        ).role !== "member"
      );
    });
    try {
      if (adminChanges.length) {
        await updateMembersRoles(adminChanges, "admin");
        this.editClubRolesSuccess = "Successfully updated membership roles";
      }
      if (memberChanges.length) {
        await updateMembersRoles(memberChanges, "member");
        this.editClubRolesSuccess = "Successfully updated membership roles";
      }
    } catch (err) {
      this.editClubRolesError =
        "Failed to update club roles. Please try again.";
      console.error(err);
    }
    this.editingClubRoles = false;
  }

  async removeMembers({ removed, members }) {
    this.resetStatus("remove");
    this.removingMembers = true;
    const toRemove = removed.filter((member) => member.role === "member");
    const toUndoRemove = members.filter((member) => member.role === "removed");
    const sumChanges = toRemove.concat(toUndoRemove).length;
    try {
      if (toRemove.length) {
        await updateMembersRoles(toRemove, "removed");
      }
      if (toUndoRemove.length) {
        await updateMembersRoles(toUndoRemove, "member");
      }
      const getId = (member) => member.userId;
      const setRole = (role) => (member) => ({ ...member, role });
      const isUnchanged = (changes) => (member) =>
        !changes.map(getId).includes(member.userId);
      this.removedClubMembers = [
        ...this.removedClubMembers.filter(isUnchanged(toUndoRemove)),
        ...toRemove.map(setRole("removed")),
      ];
      this.currentClubMembers = [
        ...this.currentClubMembers.filter(isUnchanged(toRemove)),
        ...toUndoRemove.map(setRole("member")),
      ];
      this.removeMembersSuccess = `Successfully updated 
        ${sumChanges > 1 ? "members roles" : "member role"}.`;
    } catch (err) {
      this.removeMembersError = `Failed to ${
        sumChanges > 1 ? "members roles" : "member role"
      }. 
       Please refresh and try again.`;
    } finally {
      this.removingMembers = false;
    }
  }


  async addGroupMember(userId, groupId, role = "tutor") {
    try {
      const member = await createMember(userId, groupId, role);
      const memberData = { ...userId, ...member };
      if (this.currentClub?.id == groupId) {
        this.currentClubMembers.unshift(memberData);
      }
      return true;
    } catch (err) {
      console.error(err);
      this.membersError = "Could not add member to group.";
      return false;
    }
  }

  async addDoc(data) {
    this.uploadingDoc = true;
    try {
      const doc = await createDoc(data);
      return doc;
    } catch (err) {
      console.error(err);
      this.docError = "Could not upload document.";
      return false;
    } finally {
      this.uploadingDoc = false;
    }
  }
  
  async getDoc(id) {
    try {
      const data = await getDoc(id);
      return data;
    } catch (err) {
      console.error(err);
      this.docError = "Could not get document.";
      return false;
    }
  }
  
  async deleteDoc(id) {
    try {
      const data = await deleteDoc(id);
      return data;
    } catch (err) {
      console.error(err);
      this.docError = "Could not delete document.";
      return false;
    }
  }
  
  async findDocs(id, tag, latest = false) {
    try {
      const { data } = await findDocs(id, tag, latest);
      return data;
    } catch (err) {
      console.error(err);
      this.docError = "Could not find documents.";
      return false;
    }
  }
  
}


export default GroupStore;
