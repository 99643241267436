import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
import { withFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import CheckboxInput from "../common/CheckboxInput";
import Nav from "../common/Nav";
import TitleBar from "../club/dashboard/TitleBar";
import Attendees from "../registration/Attendees";
import ClubDetails from "../registration/ClubDetails";
import PaymentDetails from "../registration/PaymentDetails";
import TermsDialog from "../registration/TermsDialog";
import RegistrationThanks from "../registration/Thanks";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container, "& .MuiFormHelperText-root": {} },
  manualPayment: {
    marginTop: "0px",
    marginBottom: "1em",
    fontSize: "0.95rem",
    lineHeight: "normal",
    color: "#505971",
  },
  submit: {
    marginTop: "1em",
    flexDirection: "row",
    alignItems: "center",
    "& button": {
      width: "12em",
    },
    "& p": {
      display: "inline-flex",
      margin: "0px 0px 0px 1em !important",
    },
  },
  imageIcon: {
    width: 40,
    height: "auto",
    marginBottom: -4,
    marginLeft: 12,
  },
  paper: {
    padding: 20,
    marginTop: 20,
    width: "80%",
    position: "relative",
  },
}));

function createClubData(where, when, price, contact) {
  if (!where) where = "Not yet known";
  if (!when) when = "Not yet known";
  if (!price) price = "Not yet known";
  if (!contact) contact = "Not yet known";
  return { where, when, price, contact };
}

const RegistrationMain = (props) => {
  const { groupId, groupStore, termStore, userStore, currentUser } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    status,
    couponErr,
    setCouponErr,
  } = props;

  const [openTerms, setOpenTerms] = React.useState(false);

  const [openSubmitting, setOpenSubmitting] = React.useState(false);

  function SubmittingDialog(props) {
    const { open } = props;

    return (
      <Dialog open={open}>
        <DialogContent>
          <div style={{ padding: "2em" }}>
            <PulseLoader loading={true} color="rgb(67, 196, 255)" width={180} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  SubmittingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
  };

  const [canRegister, setCanRegister] = React.useState(false);
  const [disabledMessage, setDisabledMessage] =
    React.useState("Loading data...");
  const [attendeeCount, setAttendeeCount] = React.useState(1);
  const [discount, setDiscount] = React.useState(0);

  const [clubName, setClubName] = React.useState("Unknown Club");
  const [clubDetails, setClubDetails] = React.useState(
    createClubData("", "", "", "")
  );
  const [completed, setCompleted] = React.useState(false);
  const [spaces, setSpaces] = React.useState(999);

  useEffect(() => {
    const getData = async () => {
      await groupStore.getGroupById(groupId);

      setDisabledMessage("Club not found.");
      if (groupStore.currentClub) {
        if (groupStore.currentClub.id > 0 && groupStore.currentClub.name) {
          setClubName(groupStore.currentClub.name);
          let when = "";
          const nextTerm = groupStore.getNextTerm();
          if (nextTerm?.termSessions?.length) {
            // use only one of the options for the when property
            // when property formated as date
            when = Moment(nextTerm.termSessions[0].sessionDate).format(
              "ddd D MMM YYYY"
            );
            // when property formated as time
            // when = `${nextTerm.termSessions[0].startTime} - ${nextTerm.termSessions[0].endTime}`;
            if (nextTerm.memberLimit > 0) {
              let currentMems = await termStore.getTermMemberCount(nextTerm.id);
              setSpaces(nextTerm.memberLimit - currentMems?.memberCount);
            }
            setCanRegister(spaces > 0);
            setDisabledMessage("");
          } else {
            setDisabledMessage(
              "The next term for this club is not yet accepting registrations. Please check back later."
            );
          }
          let currentData = createClubData(
            groupStore.currentClub.location,
            when,
            "$200.00",
            groupStore.currentClub.contactEmail
          );
          setClubDetails(currentData);
        }
      }
    };

    getData();
  }, []);

  const handleOpenTerms = (e) => {
    e.preventDefault();
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  useEffect(() => {
    setOpenSubmitting(isSubmitting);
    if (!isSubmitting && status && status.ok) {
      setCompleted(true);
    }
  }, [isSubmitting]);

  const handleCloseSubmitting = () => {
    setOpenSubmitting(false);
  };

  return (
    <>
      <Nav hideLogo />
      <TitleBar name={`${clubName} Registration`} />
      {completed ? (
        <RegistrationThanks
          userStore={userStore}
          status={status}
          clubName={clubName}
          clubDetails={clubDetails}
          values={values}
          currentUser={currentUser}
        />
      ) : (
        <>
          {spaces > 0 ? (
            <div style={{ backgroundColor: "#eaeff5", minHeight: 1350 }}>
              <div className={classes.container}>
                <Paper elevation={3} className={classes.paper}>
                  <ClubDetails {...clubDetails} />
                  <Attendees
                    values={values}
                    touched={touched}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    count={attendeeCount}
                    countUpdate={setAttendeeCount}
                    userStore={userStore}
                    spaces={spaces}
                  />
                  <PaymentDetails
                    groupStore={groupStore}
                    groupId={groupId}
                    values={values}
                    touched={touched}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    count={attendeeCount}
                    discountUpdate={setDiscount}
                    setCouponErr={setCouponErr}
                    currentUser={currentUser}
                  />
                  <div style={{ marginBottom: 24 }}>
                    <Typography variant="h3">3. Payment Details</Typography>

                    <FormGroup>
                      <CheckboxInput
                        id="manualPayment"
                        name="manualPayment"
                        value={values.manualPayment}
                        label="Manual Payment (without credit card)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                        touched={touched.manualPayment}
                        error={errors.manualPayment}
                      />
                      {props.values.manualPayment && (
                        <FormHelperText className={classes.manualPayment}>
                          <p>
                            Payment can be made by direct transfer to our bank
                            account. A credit card option will be added in
                            future.
                          </p>

                          <p>
                            <strong style={{ color: "#000000" }}>
                              Our bank details are as follows:
                              <br />
                              03 - 0510 - 0017144 - 000
                            </strong>
                          </p>
                          <p>
                            <strong style={{ color: "#000000" }}>
                              Payment reference details for our account should
                              use
                              <br />
                              club name + surname as reference.
                            </strong>
                          </p>
                        </FormHelperText>
                      )}
                    </FormGroup>
                    {/* <CheckboxInput
                      id="termsAndConditions"
                      name="termsAndConditions"
                      value={values.termsAndConditions}
                      label={
                        <div>
                          I agree to the{" "}
                          <Link to="" onClick={handleOpenTerms}>
                            Terms and Conditions
                          </Link>
                        </div>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="primary"
                      touched={touched.termsAndConditions}
                      error={errors.termsAndConditions}
                    />
                    <TermsDialog open={openTerms} onClose={handleCloseTerms} setOpenTerms={setOpenTerms} /> */}
                    <FormGroup className={classes.submit}>
                      <Button
                        variant="contained"
                        disabled={!canRegister}
                        onClick={handleSubmit}
                      >
                        {props.values.manualPayment
                          ? "Make Booking"
                          : "Make Payment"}
                      </Button>
                      {disabledMessage && (
                        <FormHelperText>{disabledMessage}</FormHelperText>
                      )}
                      {errors.submit && (
                        <FormHelperText error={!!errors.submit}>
                          {errors.submit}
                        </FormHelperText>
                      )}
                    </FormGroup>
                    <SubmittingDialog
                      open={openSubmitting}
                      onClose={handleCloseSubmitting}
                    />
                  </div>
                </Paper>
              </div>
            </div>
          ) : (
            <div className={classes.container}>
              <p>This term is already fully booked, sorry!</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    attendees: [{ firstName: "", lastName: "" }],
    firstName:
      props.currentUser?.id && props.currentUser?.firstName
        ? props.currentUser.firstName
        : "",
    lastName:
      props.currentUser?.id && props.currentUser?.lastName
        ? props.currentUser.lastName
        : "",
    phone:
      props.currentUser?.id && props.currentUser?.phone
        ? props.currentUser.phone
        : "",
    email:
      props.currentUser?.id && props.currentUser?.email?.value
        ? props.currentUser.email.value
        : "",
    couponCode: "",
    coupon: 0,
    price: 200.0,
    discount: 0.0,
    donation: 0.0,
    manualPayment: false,
    termsAndConditions: false,
    applying: false,
  }),
  validationSchema: (props) => {
    return yup.lazy((values) => {
      return yup.object().shape({
        attendees: yup
          .array()
          .of(
            yup.object().shape({
              firstName: yup
                .string()
                .required("Please enter the attendee's first name"),
              lastName: yup
                .string()
                .required("Please enter the attendee's last name"),
            })
          )
          .ensure()
          .min(1)
          .required("Please enter an attendee"),
        firstName: yup.string().required("Please enter your first name"),
        lastName: yup.string().required("Please enter your last name"),
        phone: yup
          .string()
          .matches(/^[0-9,.+ ()#-]+$/, "Phone number is invalid"),
        email: yup
          .string()
          .email("Email is invalid")
          .required("Please enter your email")
          .test(
            "uniqueEmail",
            "An account already exists for this email! Please log in or contact Gamefroot support.",
            async (value) =>
              !!props.currentUser?.email?.value ||
              (await props.userStore.validateEmail(value))
          ),
        couponCode: yup.string().test("applyingCoupon", function (value) {
          if (props.couponErr === "") {
            return true;
          } else {
            return this.createError({ message: props.couponErr });
          }
        }),
        donation: yup.number().min(0, "Donation cannot be negative!"),
        manualPayment: yup
          .boolean()
          .isTrue("Manual payment is currently the only option!"),
        // termsAndConditions: yup
        //   .boolean()
        //   .isTrue("Please agree to the terms and conditions"),
      });
    });
  },
  handleSubmit: async (
    payload,
    { props, setSubmitting, setStatus, setErrors }
  ) => {
    setStatus(null);
    setSubmitting(true);

    try {
      let newUsers = [];
      let userErrors = [];
      payload.price =
        payload.price * payload.attendees.length - payload.discount;
      let termId = await props.groupStore.getNextTerm()?.id;

      let regUser = props.currentUser?.id;
      if (regUser > 0) {
        newUsers.push(props.currentUser);
        if (
          props.currentUser.firstName !== payload.firstName ||
          props.currentUser.lastName !== payload.lastName ||
          props.currentUser.phone !== payload.phone ||
          props.currentUser.email?.value !== payload.email
        ) {
          props.userStore.updateUser(
            regUser,
            {
              firstName: payload.firstName,
              lastName: payload.lastName,
              phone: payload.phone,
              email: payload.email,
            },
            props.currentUser.email?.value !== payload.email
          );
        }
      } else {
        let regUserData = await props.userStore.createUser(
          payload.firstName,
          payload.lastName,
          payload.phone,
          payload.email,
          "",
          "guardian"
        );
        if (regUserData && regUserData.id && regUserData.id > 0) {
          newUsers.push(regUserData);
          regUser = regUserData.id;
        } else {
          setErrors({ submit: "error creating user" });
          return false;
        }
      }

      let paymentType = payload.manualPayment ? "manual" : "card";
      let regId = await props.termStore.createRegistration({
        ...payload,
        paymentType,
        termId,
        regUser,
      });
      if (!regId) {
        userErrors.push("error creating registration record!");
      } else {
        await Promise.all(
          payload.attendees.map(async (attendee) => {
            let newUser = await props.userStore.createUser(
              attendee.firstName,
              attendee.lastName
            );
            if (newUser && newUser.id && newUser.id > 0) {
              newUsers.push(newUser);
              let userId = newUser.id;

              paymentType = "n/a"; // this field shouldn't exist, it's stored in the registration table
              let result = await props.termStore.addTermMember(
                userId,
                termId,
                regId,
                paymentType
              );
              if (!result) {
                userErrors.push("error adding user to term!");
              }
            } else {
              userErrors.push(
                "error creating user: " +
                  attendee.firstName +
                  " " +
                  attendee.lastName
              );
            }
          })
        );
      }
      if (newUsers.length > 0) {
        setStatus({ ok: true, newUsers: newUsers, errors: userErrors });
      } else {
        let errorMessage =
          "Submitting the registration form failed. Please contact Gamefroot support.";
        if (userErrors.length > 0) {
          errorMessage += " Errors:";
          userErrors.forEach((error) => {
            errorMessage += " " + error + ";";
          });
        }
        setErrors({ submit: errorMessage });
      }
    } catch (err) {
      console.log(err);
      setErrors({
        submit:
          "Submitting the registration form failed. Please contact Gamefroot support.",
      });
    } finally {
      setSubmitting(false);
    }
  },
});

export default formikEnhancer(RegistrationMain);
