import "./css/main.css";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import "./css/main.css";
import {
  LessonProvider,
  UserProvider,
  GroupProvider,
  RbacProvider,
  ClubProvider,
  CommentProvider,
  TermProvider,
} from "./state";
import { FirebaseProvider } from "./FirebaseProvider";
import Club from "./pages/club";
import Login from "./components/login/WidgetContainer";
import Registration from "./pages/registration";
import Home from "./pages/home";
import Dashboard from "./components/owner_dashboard/OwnerDashboard";
import GetInvolved from "./pages/GetInvolved";
import Groups from "./components/Groups";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const theme = createMuiTheme({
  container: {
    margin: 'auto',
    width: '100vw',
    maxWidth: 860,
    height: "100%",
    boxSizing: "border-box",
    paddingTop: 24,
    paddingBottom: 24,
    [breakpoints.down("sm")]: {
      width: "100%",
      padding: 24,
    },
    [breakpoints.down("sm")]: {
      width: "100%",
      padding: 24,
    },
    [breakpoints.down('sm')]: {
      width: "100%",
      padding: 24,
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,20,0.3)",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "'Fredoka One', cursive;",
        color: "primary",
        size: "medium",
      },
      contained: {
        backgroundColor: "#3096FF",
        color: "white",
      },
      outlined: {
        borderColor: "#3096FF",
        color: "#3096FF",
      },
    },
    MuiTabs: {
      indicator: {
        display: "none",
      },
    },
  },
  palette: {
    primary: {
      light: "#99bee0",
      main: "#0085ff",
      dark: "#0085ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    text: {
      primary: "#555e78",
      secondary: "#868686",
      warning: "#fc4b6c",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif;",
    h1: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: "2rem",
      color: "white",
      webkitFontSmoothing: "antialiased",
      marginTop: 8,
      marginBottom: 8,
    },
    h2: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: "1.6rem",
      color: "#515873",
      webkitFontSmoothing: "antialiased",
      marginTop: 12,
      marginBottom: 12,
    },
    h3: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: "1.3rem",
      color: "#515873",
      webkitFontSmoothing: "antialiased",
      marginTop: 12,
      marginBottom: 12,
    },
    h4: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: "1.1rem",
      color: "#515873",
      webkitFontSmoothing: "antialiased",
      marginTop: 12,
      marginBottom: 12,
    },
    subtitle1: {
      color: "#515873",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
    },
    subtitle2: {
      color: "#515873",
      fontSize: "1rem",
      lineHeight: 1.5,
    },
  },
});

const AppWrapper = () => {
  return (
    <Router>
      <Route path="*">
        <App />
      </Route>
    </Router>
  );
};

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <FirebaseProvider>
        <UserProvider>
          <RbacProvider>
            <GroupProvider>
              <TermProvider>
                <LessonProvider>
                  <CommentProvider>
                    <Switch location={location}>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/involve" component={GetInvolved} />
                      <Route exact path="/group/:id" component={Club} />
                      <Route exact path="/groups" component={Groups} />
                      <PrivateRoute
                        exact
                        path="/dashboard/:id?"
                        component={Dashboard}
                      />
                      <Route
                        exact
                        path="/registration/:id"
                        component={Registration}
                      />
                    </Switch>
                  </CommentProvider>
                </LessonProvider>
              </TermProvider>
            </GroupProvider>
            <Login />
          </RbacProvider>
        </UserProvider>
      </FirebaseProvider>
    </ThemeProvider>
  );
}

export default AppWrapper;
