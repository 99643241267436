import { client } from "./client";

/**
 * Fetch group by id and fill empty fields with default data
 * @param { Number } id Group id
 * @returns {Object} Group Data
 */
export const fetchGroupById = (id) =>
  client.service("groups").get(id, {
    query: {
      include: [
        {
          service: "venues",
          as: "venue",
        },
        {
          service: "members",
          as: "groupMembers",
          attributes: ["role", "userId"],
        },
        {
          service: "terms",
          as: "groupTerms",
          required: false,
          attributes: ["id", "memberLimit"],
          include: {
            service: "termSessions",
            as: "termSessions",
            attributes: ["sessionDate", "startTime", "endTime"],
            required: false,
          },
          order: ["id", "firstSession"],
        },
      ],
    },
  });

export const fetchPublicGroupById = (id) =>
  client.service("groups").get(id, {
    query: {
      $public: "getPublicGroups",
      include: {
        service: "terms",
        as: "groupTerms",
        required: false,
        attributes: ["id", "memberLimit"],
        include: {
          service: "termSessions",
          as: "termSessions",
          attributes: ["sessionDate", "startTime", "endTime"],
          required: false,
        },
        order: ["id", "firstSession"],
      },
    },
  });

export const fetchGroups = (groupId) =>
  client.service("groups").find({
    query: Object.assign({
      id: {
        $in: Array.isArray(groupId) ? groupId : [groupId],
      },
      status: ["active", "archived"],
      attributes: [
        "id",
        "name",
        "contactName",
        "organiserName",
        "status",
        "creatorId"
      ],
       include: [
      {
        service: "terms",
        as: "groupTerms",
        required: false,
        attributes: ["id"],
        include: [
          {
            service: "termSessions",
            as: "termSessions",
            attributes: ["sessionDate"],
            required: false,
          },
          {
            service: "termMembers",
            as: "termMembers",
            attributes: ['role'],
            where: {
              status: "active",
            },
            required: false,
            include: [
            {
              service: "user",
              as: "user",
              attributes: ["firstName", "lastName", "username"],
            }]
          }
        ]
      }],
    }),
  });

export const fetchAllGroups = () =>
  client.service("groups").find({
    query: Object.assign({
      status: ["active", "archived"],
      attributes: [
        "id",
        "name",
        "contactName",
        "organiserName",
        "status",
        "creatorId"
      ],
      include: [
      {
        service: "terms",
        as: "groupTerms",
        required: false,
        attributes: ["id"],
        include: [
          {
            service: "termSessions",
            as: "termSessions",
            attributes: ["sessionDate"],
            required: false,
          },
          {
            service: "termMembers",
            as: "termMembers",
            attributes: ['role'],
            where: {
              status: "active",
            },
            required: false,
            include: [
            {
              service: "user",
              as: "user",
              attributes: ["firstName", "lastName", "username"],
            }]
          }
        ]
      }],
    }),
  });


  export const fetchGroupNames = (groupIds) =>
  client.service("groups").find({
    query: Object.assign({
      id: {
        $in: groupIds,
      },
      attributes: ["name"]
    },
    ),
  });


  export const fetchGroupCoupons = (groupId, excludeExpired) =>
  client.service("coupons").find({
    query: Object.assign({
      groupId: {
        $in: Array.isArray(groupId) ? groupId : [groupId],
      },
      include: [
        {
          service: "groups",
          as: "group",
          required: false,
          attributes: ["name"],
        }],
      },
    excludeExpired && { expires: { $gt: new Date().toDateString() } }    
    ),
  });

export const fetchAllCoupons = (excludeExpired) =>
  client.service("coupons").find({
    query: Object.assign({
      attributes: [
        "id",
        "groupId",
        "couponCode",
        "couponType",
        "value",
        "maxUses",
        "expires",
        ["count", "registrations->termMembers.id", "timesUsed", "coupons.id"],
      ],
      include: [
      {
        service: "groups",
        as: "group",
        required: false,
        attributes: ["name"],
      },
      {
        service: 'registrations',
        as: 'registrations',
        attributes: "none",
        required: false,
        include: {
          service: 'termMembers',
          as: 'termMembers',
          attributes: "none",
          required: false,
          },
      },     
    
    
    ],
    },
    excludeExpired && { expires: { $gt: new Date().toDateString() } }    
    ),
  });

  export const fetchCodeCoupons = (code, groupIds, excludeExpired) =>
  client.service("coupons").find({
    query: Object.assign({
      couponCode: code,
      attributes: [
        "id",
        "groupId",
        "couponCode",
        "couponType",
        "value",
        "maxUses",
        "expires",
        ["count", "registrations->termMembers.id", "timesUsed", "coupons.id"],
      ],
      include: {
        service: 'registrations',
        as: 'registrations',
        attributes: "none",
        required: false,
        include: {
          service: 'termMembers',
          as: 'termMembers',
          attributes: "none",
          required: false,
          },
      },     
    },
    (groupIds.length > 0) && { groupId: {$in: groupIds} },
    excludeExpired && { expires: { $gt: new Date().toDateString() } }    
    ),
  });

  export const createCoupon = (data) => client.service("coupons").create(data);

  export const createVenue = (data) => client.service("venues").create(data);

/**
 * Fetches groups with visibility field set to `public`.
 * Sorts by newest and fills empty fields.
 * @returns {Array} Public group data
 */
export const fetchGroupsPublic = ({ limit, offset = 0, searchQuery }) =>
  client.service("groups").find({
    query: {
      $limit: limit,
      $skip: offset,
      $public: "getPublicGroups",
      ...(searchQuery && {
        name: {
          $like: `%${searchQuery}%`,
        },
      }),
      visibility: "public",
      status: "active",
      $sort: {
        createdAt: -1,
      },
      // include: {
      //   service: "members",
      //   as: "groupMembers",
      //   attributes: ["role", "userId"],
      // },
      include: {
        service: "terms",
        as: "groupTerms",
        required: true,
        attributes: ["id"],
        include: [
          {
          service: "termSessions",
          as: "termSessions",
          attributes: ["sessionDate"],
          required: true,
        },
        {
          service: "termMembers",
          as: "termMembers",
          attributes: ["id"],
          where: {
            status: "active",
            role: "member",
          },
          required: false,
        }
    ],
        order: ["id", "firstSession"],
      },
    },
  });

  export const fetchUserRole = (groupId, userId) =>
  client.service("members").find({
    query: Object.assign({
      $public: "getPublicGroups",
      groupId: groupId,
      userId: userId,
    }),
  });

  export const isMember = (groupId, userId) =>
  client.service("terms").find({
    query: Object.assign({
      groupId: groupId,
      $public: "getPublicGroups",
      attributes: ['id'],
      include: {
        service: "termMembers",
        as: "termMembers",
        required: true,
        where: {
          userId: userId,
          status: "active",
        },
      },
    })
  });

  export const isGuardian = (groupId, userId) =>
  client.service("terms").find({
    query: Object.assign({
      groupId: groupId,
      $public: "getPublicGroups",
      attributes: ['id'],
      include: {
        service: "registrations",
        as: "termRegistrations",
        required: true,
        where: {
          regUser: userId,
        },        
      },
    })
  });

export const fetchGroupsByOwnerId = (ownerID) =>
  client.service("groups").find({
    query: {
      creatorId: ownerID,
      include: [
        {
          service: "terms",
          as: "groupTerms",
          required: false,
          attributes: ["id"],
          include: {
            service: "termSessions",
            as: "termSessions",
            attributes: ["sessionDate"],
            required: false,
          },
        },
      ],
    },
  });

  export const fetchGroupMembers = (groupId, includeRemoved = false) =>
  client.service("members").find({
    query: Object.assign(
      {
        groupId: {
          $in: Array.isArray(groupId) ? groupId : [groupId],
        },
        include: [
          {
            service: "user",
            as: "user",
            include: {
              service: "email",
              as: "email",
              required: false,
            },
          },
          {
            service: "groups",
            as: "group",
            required: true,
            attributes: ["name"],
          }],
        },
      !includeRemoved && { role: { $ne: "removed" } }
    ),
  });

  export const fetchGroupTermMembers = (groupId) =>
  client.service("terms").find({
    query: Object.assign({
      groupId: {
        $in: Array.isArray(groupId) ? groupId : [groupId],
      },
//      attributes: ['id', 'location', ],
      include: {
          service: "termMembers",
          as: "termMembers",
          required: false,
          include: {
            service: "user",
            as: "user",
            include: {
              service: "email",
              as: "email",
              required: false,
            },
          },
          where: {
          status: "active",
          role: ["notIn", ["admin", "removed"]],
        },
      },
    })
  });

export const getMemberById = (id) =>
  client.service("members").get(id, {
    query: {
      include: {
        service: "user",
        as: "user",
      },
    },
  });

/**
 * No longer used
 * Fetches groups that a user is a member of.
 * Cleans table-join data and fills empty fields.
 * @param {Number} userId
 * @returns {Array} Group data
 */
export const fetchMembershipsWithGroups = (userId) =>
  client.service("members").find({
    query: {
      userId: userId || "$userId",
      $select: ["role", "payed", "id"],
      role: {
        $ne: "removed",
      },
      $sort: {
        createdAt: -1,
      },
      include: {
        service: "groups",
        as: "group",
        where: {
          status: "active",
        },
      },
    },
  });


  export const fetchGroupIdsByUser = (userId, roles) =>
  client.service("members").find({
    query: {
      userId: userId,
      role: {
        $in: roles,
      },
      attributes: ["groupId"],
      include: {
        service: "groups",
        as: "group",
        required: true,
        attributes: "none",
        where: {
          status: "active",
        },
      },
    },
  });

/**
 * Creates group - which will assign user club admin server-side.
 * @param {Object} data Create group form data
 * @returns {Promise}
 */
export const createGroup = (data) => client.service("groups").create(data);

/**
 * Edits a group
 * @param {Number} id Group id
 * @param {Object} data Edit group form data
 * @returns {Promise}
 */
export const editGroup = (id, data) => client.service("groups").patch(id, data);

export const createMember = (userId, groupId, role = "member") =>
  client.service("members").create({
    userId,
    groupId,
    role,
  });

/**
 * Updates club member roles
 * @param {Array} members Members to update role
 * @param {String} role Role to change to
 * @returns {Promise}
 */
export const updateMembersRoles = (members, role) =>
  client.service("members").patch(
    null,
    { role },
    {
      query: {
        groupId: members[0].groupId,
        userId: {
          $in: members.map((member) => member.userId),
        },
      },
    }
  );

/**
 * Attempts to register a member to a payment
 * @param {Number} id member id
 * @param {String} code payment code
 * @returns {Promise}
 */
export const registerMemberPayment = (id, code) =>
  client.service("members").patch(id, {
    payed: 1,
    code,
  });
