import {
  rbacService,
  userService,
  emailService,
  client,
} from "./client";
import AccessControl from "accesscontrol";
import firebase from "firebase/app";

export const login = async (credentials) => {
  try {
    if (!credentials) {
      // Try to authenticate using an existing token
      return await client.reAuthenticate()
        .then((response) => response)
        .catch(err => {
            if (err.code !== 401) { // 401 is expected if there is no existing token
              console.log(err);
            }
          });
    } else {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const { email, password } = credentials;

      if (regex.test(email)) {
        let user = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);

        if (user) {
          const token = await firebase.auth().currentUser?.getIdToken(true);
          client.authentication.setAccessToken(token);

          return await client
            .authenticate({
              strategy: "firebase",
              access_token: token,
            })
            .then((res) => {
              return res;
            });
        }
      } else {
        return await client
          .authenticate({
            strategy: "local",
            username: credentials.email,
            password: credentials.password,
          })
          .then((response) => {
            return response;
          });
      }
    }
  } catch (err) {
    throw err;
  }
};

export const federatedLogin = async (provider) => {
  try {
    const user = await firebase.auth().signInWithPopup(provider);

    if (user) {
      const token = await firebase.auth().currentUser?.getIdToken(true);
      client.authentication.setAccessToken(token);

      await client
        .authenticate({
          strategy: "firebase",
          access_token: token,
        })
        .then((res) => {
          return res;
        });
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const register = async (credentials) => {
  try {
    const { email, password } = credentials;

    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (res.user) {
      credentials.firebaseId = res.user.uid;
    }

    await userService.create(credentials);

    return await login({ email, password });
  } catch (err) {
    throw err;
  }
};

export const registerExisting = async (credentials, userId) => {
  try {
    const { email, password } = credentials;

    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (res.user) {
      await userService.patch(userId, { firebaseId: res.user.uid });
    }
  } catch (err) {
    throw err;
  }
};

export const logout = () => client.logout();

export const fetchAccessControl = async () => {
  const { rules, clubRules } = await rbacService.find();
  return {
    ac: new AccessControl(rules),
    clubAc: new AccessControl(clubRules),
  };
};

export const checkEmail = async (userId) => {
  try {
    const { data } = await emailService.find({
      query: {
        owner: userId,
      },
    });

    return data;
  } catch (err) {
    throw err;
  }
};
