import React, { useEffect, useState } from "react";
// logic imports
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
// material-ui component imports
import Grid from "@material-ui/core/Grid";
// custom component imports
import MainSection from "./ParentDashboardMainSection";
import SideBar from "./ParentDashboardSideBar";

import { 
  useTermStore, 
} from "../../state";

// component scope styles
const useStlyes = makeStyles(theme => ({
  container: {
    maxWidth: 860, 
    width: '90vw', 
    margin: "auto", 
  },
}));

/**component controling layout for parent dashboard page*/
const ParentDashboard = (props) => {
  const {groupStore} = props;
  const termStore = useTermStore();

  const classes = useStlyes();

  const [nextDate, setNextDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [nextTerm, setNextTerm] = useState();
  const [seats, setSeats] = useState();

  useEffect(() => {
    const getData = async () => {
      let tempNextTerm = groupStore.getNextTerm();
      setNextTerm(tempNextTerm);
      if (tempNextTerm?.termSessions?.length) {
        setNextDate(moment(tempNextTerm.termSessions[0].sessionDate).format("Do MMMM"));
        setEndDate(moment(tempNextTerm.termSessions[tempNextTerm.termSessions.length - 1].sessionDate).format("Do MMMM"));
        if (tempNextTerm.termSessions[0].startTime) {
          setStartTime(moment(tempNextTerm.termSessions[0].startTime, "HH:mm:ss").format("h:mma"));
        }
        if (tempNextTerm.termSessions[0].endTime) {
          setEndTime(moment(tempNextTerm.termSessions[0].endTime, "HH:mm:ss").format("h:mma"));
        }
        if (tempNextTerm.memberLimit > 0) {
          let currentMems = await termStore.getTermMemberCount(tempNextTerm.id);            
          setSeats(tempNextTerm.memberLimit - currentMems?.memberCount);
        }
      } else {
        setNextDate(undefined);
        setEndDate(undefined);
        setStartTime(undefined);
        setEndTime(undefined)
        setSeats(undefined);
      }

    };

    getData();
  }, [groupStore]);


  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <MainSection
            groupStore={groupStore}
            termStore={termStore}
            nextTerm={nextTerm}
            nextDate={nextDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
           />
        </Grid>
        <Grid item xs={4}>
          <SideBar
            groupStore={groupStore}
            nextDate={nextDate}
            seats={seats}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ParentDashboard;
