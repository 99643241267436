import React from 'react'
import { observer } from 'mobx-react-lite'

import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'


import { makeStyles } from '@material-ui/styles'

import {
  useLessonStore,
  useGroupStore
} from '../../../state'
import editorUrl from '../../../utils/editor-url'

const tutorialFolders = {
  "Top-down Video Tutorials": "gamedevclub-top-down-video-tutorials",
  "Platformer 102: Expansion Tutorials": "gamedevclub-platformer-expansion",
  "Platformer 103: Expansion Tutorials": "gamedevclub-platformer-expansion-2",
  "Platformer 101: Expansion Tutorials":
    "gamedevclub-platformer-video-tutorials",
  "Interactive Fiction": "Interactive-Fiction",
  "Mihi Maker": "mihi-maker-tutorial"
};

const useStyles = makeStyles(theme => ({
  textButton: {
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
  }
}))

const LessonDisplay = () => {
  const classes = useStyles()
  const {
    selectedLessons
  } = useLessonStore()

  const { initialLoad } = useGroupStore()

  const handleClick = (name) => {
    const folderName = tutorialFolders[name]
    if (folderName) {
      window.location = `${editorUrl()}/tutorials/${folderName}`;
    }
  }

  return (
    <div className={classes.container}>
      <Fade in={true} timeout={initialLoad ? 200 : 0}>
        <>
          <Typography variant={'h2'}>
            {`Current Lesson${selectedLessons.length > 1 ? 's' : ''}`}
          </Typography>
          <Typography variant="subtitle1">
            Tutorials to follow for this term
          </Typography>
          {selectedLessons.map(lesson => (
            <Button
              key={lesson.id}
              onClick={() => handleClick(lesson.name)}>
              <img src={lesson.image} />
            </Button>
          ))}
        </>
      </Fade>
    </div>
  )
}

export default observer(LessonDisplay)