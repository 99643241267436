import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/SearchRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px 8px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#a3d9ff",
    borderRadius: 20,
    width: 280,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "#505971",
    fontWeight: 500,
    height: 8,
  },
  icon: {
    color: "#505971",
  },
  loader: {
    width: 25,
  },
}));


const CustomizedInputBase = ({
  loading,
  style={},
  className,
  iconClassName,
  inputClassName,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div style={style} className={clsx(classes.root, className && className)}>
      <SearchIcon
        className={clsx(classes.icon, iconClassName && iconClassName)}
      />
      <InputBase
        className={clsx(classes.input, inputClassName && inputClassName)}
        {...props}
      />
      {loading && <CircularProgress className={classes.loader} />}
    </div>
  );
};

export default CustomizedInputBase;
