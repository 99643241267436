import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { pickColour } from "../../utils/color-picker";

import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TeacherIcon from "@material-ui/icons/School";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import LocateIcon from "@material-ui/icons/Room";
import GroupIcon from "@material-ui/icons/GroupRounded";

const ClubEntry = (props) => {
  const {
    i,
    id,
    icon,
    name,
    isAdmin,
    organizer,
    groupMembers,
    nextTerm,
    destination,
    createdAt,
    distance,
    iconClick,
    mapLat,
    mapLong
  } = props;

  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      width: 71,
      height: 71,
      padding: 0,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    iconText: {
      fontFamily: "Fredoka One, cursive",
      fontSize: 40,
      textTransform: "capitalize",
      width: 64,
      textAlign: "center",
      color: "white",
      fontWeight: 100,
    },
    textLocate: {
      display: 'block',
    },
    iconSmall: {
      width: 20,
      height: 20,
      marginRight: 5,
      position: "relative",
//      top: 2,
      backfaceVisibility: "hidden",
      color: "#616579",
    },
    paper: {
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      userSelect: "none",
      transition: "background-color 0.3s",
      width: "100%",
    },
    cardContainer: {
      margin: 8,
      width: "auto",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    p: {
      fontFamily: "Roboto, sans-serif",
      backfaceVisibility: "hidden",
      fontSize: "1.1rem",
      margin: 0,
      marginRight: 10,
      color: "#787c8f",
      fontWeight: "400",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));

  const classes = useStyles();

  return (
    <motion.div
      whileHover={{
        scale: 1.025,
        transition: { duration: 0.1, ease: "easeIn" },
      }}
      whileTap={{ scale: 0.99 }}
      className="club-entry"
    >
      <Link to={`/group/${id}`} style={{ textDecoration: "none" }}>
        <Paper className={classes.paper}>
          <Grid className={classes.cardContainer} container>
            <Grid
              item
              className={classes.iconContainer}
              style={{ backgroundColor: pickColour(i, true) }}
            >
              <Typography className={classes.iconText}>{icon}</Typography>
            </Grid>
            <Grid style={{ paddingLeft: 15 }} item xs>
              <Typography
                style={{ textAlign: "left", fontSize: 18, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: 317, marginBottom: 5 }}
                variant={"h2"}
              >
                {name} {isAdmin && <TeacherIcon />}
              </Typography>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "5" }}>
                {nextTerm && (
                  <>
                    <CalendarIcon className={classes.iconSmall} />
                    <p className={classes.p}>When: {nextTerm}</p>
                  </>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {groupMembers && (
                  <>
                    <GroupIcon className={classes.iconSmall} />
                    <p className={classes.p}>Members: {groupMembers}</p>
                  </>
                )}
              </div>
              {(distance < 9999) && (
                <div
                  style={{ display: "flex", alignItems: "center", cursor: "help" }}
                  onClick={(e) => {e.preventDefault(); iconClick(mapLat, mapLong)}}                  
                >
                  <LocateIcon className={classes.iconSmall} />
                  <p className={classes.p}>Distance: {distance}km</p>
                  </div>
                )}
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </motion.div>
  );
};

export default ClubEntry;