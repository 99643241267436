/**
 * Picks across a gradient of colours.
 * @param {number} i - Index of a component map.
 */

const colours = [
  '#ffc400',
  '#ff9800',
  '#ff5722',
  '#d81b60',
  '#aa00ff',
  '#3f51b5',
  '#2196F3',
  '#26c6da',
  '#00C853'
]

const coloursNew = [
  '#fb5143',
  '#24d3ff',
  '#ff7b1a',
  '#bf37ff',
  '#24e652',
  '#3c9bff',
  '#ffbc1a'
]

export const pickColour = (i, saturatedPallette) => (saturatedPallette ? coloursNew : colours)[i % (saturatedPallette ? coloursNew : colours).length]