import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackRounded";

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: "#3A3E4B",
    width: "100%",
    height: 90,
    marginTop: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#FFFFFF",
    paddingLeft: 16,
  }, 
  container: {
    padding: "30px 0",
    width: "100%",
    maxWidth: 860,
    position: "relative",
  },
  icon: {
    fontSize: "2rem",
    color: "#FFFFFF",
  },
  iconButton: {
    position: "absolute",
    left: -50,
  },
}));

const TitleBar = (props) => {

  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <div className={classes.container}>
        <IconButton onClick={history.goBack} className={classes.iconButton}>
          <BackIcon className={classes.icon}/>
        </IconButton>
        <Typography variant={"h1"} className={classes.text}>{props.name}</Typography>
      </div>
    </div>
  )
}

export default TitleBar;
