import { client } from "./client";

/**
 * Fetch term by id
 * @param { Number } id Group id
 * @returns {Object} Group Data
 */
export const fetchTerm = (id) =>
  client.service("terms").get(id, {
    query: {
      include: [
        {
          service: "termSessions",
          as: "termSessions",
          //          attributes: ['sessionDate'],
          required: false,
        },
        {
          service: "termMembers",
          as: "termMembers",
          //          attributes: ['sessionDate'],
          required: false,
          include: [
          {
            service: "user",
            as: "user",
            attributes: ["firstName", "lastName", "username"],
            include: [{
              service: 'email',
              as: 'email',
              attributes: ["value"],
              required: false,
            }],     
          },
          {
            service: 'registrations',
            as: 'registration',
            attributes: ["firstName", "lastName", "price"],
            required: false,
            include: {
                service: 'user',
                as: 'user2',
                attributes: ["firstName", "lastName"],
                required: false,
              },
          }]
        }
      ]
     }
   });

   export const fetchTerms = (groupId) =>
   client.service("terms").find({
     query: Object.assign({
       groupId: {
         $in: Array.isArray(groupId) ? groupId : [groupId],
       },
 //      attributes: ['id', 'location', ],
       include: [
         {
           service: "termMembers",
           as: "termMembers",
           required: false,
           where: {
             status: "active",
             role: ["notIn", ["admin", "removed"]],
           },
         },
         {
           service: "termSessions",
           as: "termSessions",
           required: false,
         },
       ],
     })
   });

export const addTermMember = (userId, termId, regId, paymentType, role) =>
  client.service("termMembers").create({
    termId: termId,
    userId: userId,
    regId: regId,
    active: "active",
    role: role,
    paymentType: paymentType,
    paid: false,
  });

// export const fetchTutors = (termId) =>
//   client.service("termMembers").find({
//     query: Object.assign({
//       termId: {
//         $in: Array.isArray(termId) ? termId : [termId],
//       },
//       attributes: ["termId"],
//       status: "active",
//       role: "tutor",
//       include: {
//         service: "user",
//         as: "user",
//         attributes: ["firstName", "lastName", "username"],
//       },
//     }),
//   });

export const getMemId = (termId, userId) =>
client.service("termMembers").find({
  query: Object.assign({
    termId: termId,
    userId: userId,
    attributes: ["id"],
  }),
});

export const getMemberCount = (termId) =>
client.service("terms").get(termId, {
  query: {
    attributes: [["count", "termMembers.id", "memberCount", "terms.id"]],
    $public: "getMemberCount",
    include: {
      service: "termMembers",
      as: "termMembers",
      attributes: "none",
      where: {
        role: "member"
      },
      required: false,
    }
  }
});
   

export const fetchGroupIdsByMember = (userId) =>
client.service("terms").find({
  query: Object.assign({
    attributes: ["groupId"],
    include: [
      {
        service: "termMembers",
        as: "termMembers",
        required: true,
        where: {
          userId: userId,
          status: "active",
          role: ["notIn", ["removed"]],
        },
      },
    ],
  })
});


export const fetchGroupIdsByGuardian = (userId) =>
client.service("terms").find({
  query: Object.assign({
    attributes: ["groupId"],
    include: [
      {
        service: "registrations",
        as: "termRegistrations",
        required: true,
        where: {
          regUser: userId,
        },
      },
    ],
  })
});


export const fetchTermGuardians = (termId) =>
  client.service("registrations").find({
    query: Object.assign({
      termId: {
        $in: Array.isArray(termId) ? termId : [termId],
      },
      include: [
        {
          service: "user",
          as: "user2",
          attributes: ["firstName", "lastName", "username"],
          include: [{
            service: 'email',
            as: 'email',
            attributes: ["value"],
            required: false,
          }]
        }
      ]
     })
   });

export const fetchTermStudentsGuardian = (termId, userId) =>
  client.service("registrations").find({
    query: Object.assign({
      termId: {
        $in: Array.isArray(termId) ? termId : [termId],
      },
      regUser: {
        $in: Array.isArray(userId) ? userId : [userId],
      },
      include: [
        {
          service: "termMembers",
          as: "termMembers",
          required: true,
          include: [
            {
              service: "user",
              as: "user",
              attributes: ["firstName", "lastName", "username"],
            }
          ]
        }
      ]
     })
   });


export const createTerm = (data) => client.service("terms").create(data);

export const editTerm = (id, data) => client.service("terms").patch(id, data);

export const createTermSession = (data) =>
  client.service("termSessions").create(data);

export const editTermSession = (id, data) => client.service("termSessions").patch(id, data);

export const createRegistration = (data) =>
  client.service("registrations").create(data);

export const setPaid = (id) => {
  client.service("termMembers").patch(id, {paid: true})
}
  