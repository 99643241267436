const feathers = require("@feathersjs/feathers");
const rest = require("@feathersjs/rest-client");
const auth = require("@feathersjs/authentication-client");
const axios = require("axios")

export const client = feathers()

const urls = {
  development: "http://dev.gamefroot.com:3030",
  production: "https://gdc-api.gamefroot.com",
  staging: "https://gdc-api-staging.gamefroot.com",
};

export const feathersUrl = urls[process.env.REACT_APP_GAMEFROOT_ENV];

const restClient = rest(feathersUrl)

client.configure(auth({ storageKey: "feathers-jwt" }));

client.configure(restClient.axios(axios));


console.log(`#STATUS 🌏 Environment: ${process.env.REACT_APP_GAMEFROOT_ENV}`)

export const userService = client.service('user')
export const lessonRecordService = client.service('member-lesson-records')
export const groupService = client.service("groups");
export const groupCommentService = client.service("group-comments")
export const lessonService = client.service("lessons");
export const emailService = client.service("email");
export const seatService = client.service("seats")
export const rbacService = client.service('rbac');
