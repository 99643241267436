import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import RegistrationMain from '../components/registration/RegistrationMain'

import {
  useGroupStore,
  useUserStore,
  useTermStore
} from '../state'

const Registration = (props) => {
  const groupId = useParams().id
  const groupStore = useGroupStore()
  const termStore = useTermStore()
  const userStore = useUserStore()
  const [couponErr, setCouponErr] = React.useState("");

  return (
    <RegistrationMain
      groupId={groupId}
      groupStore={groupStore}
      userStore={userStore}
      termStore={termStore}
      couponErr={couponErr}
      setCouponErr={setCouponErr}
      currentUser={userStore.currentUser}
    />
  );
}


export default Registration
