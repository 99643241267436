import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import {
  useRbacStore,
  useLessonStore,
  useTermStore,
} from "../../state";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import ClubLessons from "./dashboard/ClubLessons";
import LessonDisplay from "./dashboard/LessonDisplay";
import ClubDiscussion from "./ClubDiscussion";
import PdfList from "./PdfList";

const useStyles = makeStyles((theme) => ({
  dashboardBlock: {
    marginBottom: 48,
  },
  buttonJoin: {
    backgroundColor: "#ffea00",
    color: "#996b59",
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
    width: "100%",
    "&:hover": {
      backgroundColor: "#ffea00 !important",
      color: "#996b59",
    },
    marginBottom: 20,
  },
  text: {
    color: "#515873",
    marginBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  poster: {
    maxWidth: "100%"
  },
}));

const ClubDashboard = (props) => {
  const {groupStore} = props;
  const classes = useStyles();
  const lessonStore = useLessonStore();
  const history = useHistory();
  const termStore = useTermStore();

  const {
    currentClub,
  } = groupStore;

  const {
    canUserAssignLessons,
    userUnaffiliatedWithClub,
  } = useRbacStore();

  const { selectedLessons } = lessonStore;

  const [nextDate, setNextDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [seats, setSeats] = useState();
  const [posterUrl, setPosterUrl] = useState("");
  const [posterAlt, setPosterAlt] = useState("");

  const joinClick = (event) => {
    history.push("/registration/" + currentClub.id);
  };

  useEffect(() => {
    const getData = async () => {
      const nextTerm = groupStore.getNextTerm();
      if (nextTerm?.termSessions?.length) {
        setNextDate(moment(nextTerm.termSessions[0].sessionDate).format("Do MMMM"));
        setEndDate(moment(nextTerm.termSessions[nextTerm.termSessions.length - 1].sessionDate).format("Do MMMM"));
        if (nextTerm.termSessions[0].startTime) {
          setStartTime(moment(nextTerm.termSessions[0].startTime, "HH:mm:ss").format("h:mma"));
        }
        if (nextTerm.termSessions[0].endTime) {
          setEndTime(moment(nextTerm.termSessions[0].endTime, "HH:mm:ss").format("h:mma"));
        }
        if (nextTerm.memberLimit > 0) {
          let currentMems = await termStore.getTermMemberCount(nextTerm.id);            
          setSeats(nextTerm.memberLimit - currentMems?.memberCount);
        }
      } else {
        setNextDate(undefined);
        setEndDate(undefined);
        setStartTime(undefined);
        setEndTime(undefined);
        setSeats(undefined);
      }

      let posters = await groupStore.findDocs(groupStore.currentClub?.id, "poster", true);
      if (posters.length) {
        let poster = await groupStore.getDoc(posters[0].id);
        if (poster?.url) {
          const res = await axios.get(poster.url);
          setPosterUrl(res.data);
        }
        setPosterAlt(poster?.description);
      }
    };

    getData();
  }, [groupStore]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        <div className={classes.dashboardBlock}>
          <div className={classes.section}>
            <Typography className={classes.text} style={{paddingTop: 12,}}>
              Game Dev Clubs are like Code Clubs but specifically designed around coding 
              games and preparing kids for a career in the games industry.
            </Typography>
            <Typography className={classes.text}>{currentClub.description}</Typography>
          </div>

          <div className={classes.section}>
            <Typography variant={"h4"} className={classes.text}>Club Details</Typography>
            <Typography className={classes.text}><b>Where:</b> {currentClub.location}</Typography>
            { startTime && endTime &&
              <Typography className={classes.text}><b>When:</b> {startTime} - {endTime} </Typography>
            }
            { nextDate && endDate && 
              <Typography className={classes.text}><b>From:</b> {nextDate} to {endDate}</Typography>
            }
          </div>
        </div>

        {!userUnaffiliatedWithClub && <ClubDiscussion />}

        {posterUrl && 
          <img src={posterUrl} alt={posterAlt} className={classes.poster} />
        }

      </Grid>

      {userUnaffiliatedWithClub && (
        <Grid item xs={4}>
          <div className={classes.section}>
            <Typography variant={"h4"} className={classes.text}>Club Registration</Typography>
            <Button
              variant="contained"
              className={classes.buttonJoin}
              onClick={joinClick}
              disabled={!nextDate}
            >
              Make Booking
            </Button>
            {!!seats &&
              <Typography className={classes.text}>{`${seats} seats left available`}</Typography>
            }
          </div>
          <div className={classes.section}>
            <Typography variant={"h4"} className={classes.text}>Club Contact</Typography>
            <Typography className={classes.text} style={{marginBottom: "unset"}}>{currentClub.contactName}</Typography>
            <Link href={`mailto:${currentClub.contactEmail}`}>{currentClub.contactEmail}</Link>
            <br /><Link href={`tel:${currentClub.contactPhone}`}>{currentClub.contactPhone}</Link>
          </div>
          <PdfList groupStore={groupStore}></PdfList>

        </Grid>
      )}
      {!userUnaffiliatedWithClub && (
        <Grid item xs={4}>
          {(canUserAssignLessons || selectedLessons) && (
            <div className={classes.dashboardBlock}>
              {canUserAssignLessons ? <ClubLessons /> : <LessonDisplay />}
            </div>
          )}

          <PdfList groupStore={groupStore}></PdfList>

        </Grid>
      )}
    </Grid>
  );
};

export default observer(ClubDashboard);
