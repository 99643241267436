// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useBreakpoint } from "../../hooks/use-breakpoint";
import { observer } from "mobx-react-lite";

// global state
import { useUserStore } from "../../state";

// material-ui component imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";

// custom component imports
import RoundedButton from "../common/RoundedButton";

// image imports
import splashImg from "../../img/home/gdc banner devices.png";

// component scope styles
const useStyles = makeStyles(theme => ({
  container: { ...theme.container },
  splashGrid: {
    height: 380,
    maxWidth: 860,
    margin: 'auto',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 60,
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingBottom: 20,
      width: '90%',
    }
  },
  splashBackground: {
    width: "100%",
    backgroundColor: "#4B1CC4",
  },
  splashTitle: {
    fontSize: 30,
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    [theme.breakpoints.up('md')]: {
      width: "90%",
      paddingTop: 70,
    },
  },
  splashButtonWrapper: {
    maxWidth: 195,
    paddingTop: 30,
  },
  image:{
    paddingTop: 50,
    width: "100%",
    height: "auto",
  },
}))

// functional component
const Splash = () => {
  // define hooks
  const classes = useStyles()
  const breakpoint = useBreakpoint()
  const userStore = useUserStore();
  const history = useHistory()

  // gather data
  const login = async () => {
    await userStore.login()
  }

  React.useEffect(login, [])

  // event handlers
  const handleStart = () => history.push('/dashboard/new')

  const handleFind = () => history.push('/groups')

  // render component
  return (
    <div className={classes.splashBackground}>
      <Grid 
        container
        className={classes.splashGrid}
      >

        <Grid item xs={6}>
          <Typography className={classes.splashTitle}>
            An after-school learning programme for kids to learn creative
            coding skills
          </Typography>
          <Grid container >
            <Grid item xs={6}>
              <div className={classes.splashButtonWrapper}>
                <RoundedButton text="Find a Club" onClick={handleFind}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.splashButtonWrapper}>
                {userStore.currentUser?.id &&
                  // hides start club button if not loged in
                  <RoundedButton
                    icon={!breakpoint.small?<AddIcon />: null}
                    color="#399aff"
                    text="Start Club"
                    onClick={handleStart}
                  />
                } 
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <img className={classes.image} src={splashImg} alt="devices using gamefroot" />
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(Splash);
