// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// SVG/image imports
import girlImg from "../../img/home/girl outline 2x.png";

// component scope styles
const useStyles = makeStyles(theme => ({
  container: { ...theme.container },
  padded: {
    paddingBottom: 30,
  },
  title: {
    fontSize: 30,
    color: "#505971",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    textAlign: "center",
    margin: "20px 0",
  },
  topText: {
    width: "80%",
    margin: "auto",
    color: "#505971",
  },
  list: {
    fontSize: '1.2em',
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    marginLeft: 80,
    paddingTop: 30,
    paddingBottom: 30,
  },
  panel: {
    backgroundColor: "#aa56fe",
    borderRadius: 20,
    position: "relative",
  },
  img: {
    position: "absolute",
    top: -45,
    left: -60,
    width: 150,
    height: 150,
    transform: "scaleX(-1)",
  },
}))

export default function InfoSection() {
  const classes = useStyles()

  return (
    <div >
      <div className={classes.container}>
        <Typography className={classes.title}>
          Developing Creative Coding Skills
        </Typography>
        <Typography className={classes.topText} style={{paddingBottom: 16}}>
          Game Dev Club is a learning programme for kids that love games and game 
          technology, and it is tailored towards kids that want to learn how to code with 
          creative purpose.
        </Typography>
        <Typography className={classes.topText}>
          Our resources are developed by industry experts and delivered 
          by paid professionals to ensure that members get continuity of tutoring.
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={clsx(classes.title, classes.padded)}>
          Benefits for students
        </Typography>
        <Paper className={classes.panel}>
          <ul className={classes.list}>
            <li>Students develop a sense of responsibility, time management and communication skills</li>
            <li>Students gain condifdence and are enthusiatic about learning</li>
            <li>Students learn authentic skills based on real industry knowledge</li>
            <li>Students learn realistic coding skills</li>
            <li>Encourages pathways towards future study, and direction</li>
          </ul>
          <img className={classes.img} src={girlImg} alt="cartoon girl" />
        </Paper>
      </div>
    </div>
  )
}
