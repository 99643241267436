import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { makeStyles } from '@material-ui/styles'

import {
  useLessonStore,
  useGroupStore
} from '../../../state'

const useStyles = makeStyles(theme => ({
  assignButton: {
    width: '100%',
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
  },
  lessonContainer: {
    position: 'relative',
    marginBottom: 12
  },
  cancelButton: {
    position: 'absolute',
    top: -15,
    right: -15,
    backgroundColor: 'white',
    padding: 2,
    transition: "all 0.2s ease",
    "&:hover": {
      transform: 'scale(1.1)',
      backgroundColor: 'white',
      color: '#fc4b6c'
    },
  }
}))

const ClubLessons = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [hover, setHover] = useState(null)
  const lessonStore = useLessonStore()
  const groupStore = useGroupStore()

  const {
    lessons,
    selectedLessons,
    loadingLessons,
  } = lessonStore

  const {
    initialLoad,
    currentClub
  } = groupStore

  const handleSelect = id => {
    if (!currentClub.lessonIds?.includes(id)) {
      lessonStore.addLesson(Number(id))
      groupStore.editClub(currentClub.id, { 
        lessonIds: Boolean(currentClub.lessonIds.length) ? [...currentClub.lessonIds, String(id)] : [String(id)]
      })
    }

    setAnchorEl(null)
  }

  const handleUnassign = id => {
    lessonStore.removeLesson(id)
    groupStore.editClub(currentClub.id, { lessonIds: currentClub.lessonIds.filter(lesson => lesson !== String(id)) })
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {(!loadingLessons && Boolean(selectedLessons.length)) &&
        <Fade in={true} timeout={initialLoad ? 200 : 0}>
          <>
            <Typography variant={'h2'}>
              {`Active Lesson${selectedLessons.length > 1 ? 's' : ''}`}
            </Typography>

            {selectedLessons.map(lesson => {
              return (
              <div
                key={lesson.id}
                className={classes.lessonContainer}
                onMouseEnter={() => setHover(lesson.id)}
                onMouseLeave={() => setHover(null)}
              >
                <img key={lesson.id} src={lesson.image} />
                <Fade in={true} timeout={200}>
                  <IconButton
                    className={classes.cancelButton}
                    style={{display: hover === lesson.id ? 'block' : 'none'}}
                    onClick={() => handleUnassign(lesson.id)}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                </Fade>
              </div>  
            )})}
          </>
        </Fade>
      }

      <Button
        variant="contained"
        color="primary"
        onClick={e => setAnchorEl(e.currentTarget)}
        className={classes.assignButton}
      >
        ASSIGN A LESSON
      </Button>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {lessons &&
          lessons.filter(lesson => !selectedLessons.includes(lesson)).map(lesson => (
            <MenuItem
              key={lesson.id}
              onClick={() => handleSelect(lesson.id)}
            >
              <img src={lesson.image} />
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}

export default observer(ClubLessons)
