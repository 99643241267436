import React, { useEffect } from "react";

import { withFormik } from "formik";
import * as yup from "yup";

import FormGroup from "@material-ui/core/FormGroup";
import Dropdown from "../common/Dropdown";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PulseLoader from "react-spinners/PulseLoader";

import { useGroupStore } from "../../state";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container, "& .MuiFormHelperText-root": {} },
  background: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#399aff",
    padding: "90px 0px 0px 0px",
  },
  submit: {
    marginTop: "1em",
    flexDirection: "row",
    alignItems: "center",
    "& button": {
      width: "12em",
    },
    "& p": {
      display: "inline-flex",
      margin: "0px 0px 0px 1em !important",
    },
  },
}));

const AssignTutor = (props) => {
  const { groupId, termId, tutors, termStore, setRefresh } = props;
  const groupStore = useGroupStore();
  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    status,
  } = props;

  const [openSubmitting, setOpenSubmitting] = React.useState(false);
  const [available, setAvailable] = React.useState([]);

  function SubmittingDialog(props) {
    const { open } = props;

    return (
      <Dialog open={open}>
        <DialogContent>
          <div style={{ padding: "2em" }}>
            <PulseLoader loading={true} color="rgb(67, 196, 255)" width={180} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  SubmittingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
  };

  useEffect(() => {
    setOpenSubmitting(isSubmitting);
  }, [isSubmitting]);

  const handleCloseSubmitting = () => {
    setOpenSubmitting(false);
  };

  useEffect(() => {
    const getData = async () => {
      let tempTutors = [];
      await groupStore.getCurrentGroupMembers(groupId);
      groupStore.currentClubMembers?.map((member, i) => {
        if (member.role == 'tutor' || member.role == 'admin') {
          if (!tutors.includes(member.userId)) {
            tempTutors.push({id: member.userId, username: member.user.username});
          }
        }
      });
      setAvailable(tempTutors);
    };

    getData();
  }, [tutors]);


  return (
<>
<FormGroup className={classes.submit}>
    <Dropdown name="tutorId" value={values.tutorId} handleChange={handleChange}>
    <MenuItem value="0" key="tutor_0">&nbsp;</MenuItem>
    {available.map((tutor, i) => (
      <MenuItem value={tutor.id} key={"tutor_" + tutor.id}>{(tutor.username)}</MenuItem>
    ))}
  </Dropdown>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleSubmit}
          disabled={!((values.tutorId > 0) && (termId > 0))}
        >
          Assign Tutor
        </Button>
        {errors.submit && (
          <FormHelperText error={!!errors.submit}>
            {errors.submit}
          </FormHelperText>
        )}
      </FormGroup>

      <SubmittingDialog
        open={openSubmitting}
        onClose={handleCloseSubmitting}
      />
</>
  );
};

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    tutorId: 0,
  }),
  validationSchema: yup.object().shape({
    tutorId: yup.number().required("Please select a tutor to assign to this term"),
  }),
  handleSubmit: async (
    payload,
    { props, setSubmitting, setStatus, setErrors }
  ) => {
    setStatus(null);
    setSubmitting(true);
    try {
      let errorMessage = "";
      let result = await props.termStore.addTermMember(
        payload.tutorId,
        props.termId,
        null,
        "N/A",
        "tutor"
      );
      if (result) {
        setStatus({ ok: true });
        props.setRefresh(true);
      } else {
        errorMessage = "Error adding tutor to term!";
      }
      setErrors({ submit: errorMessage });
    } catch (err) {
      console.log(err);
      setErrors({
        submit:
          "Submitting the assign tutor form failed. Please contact Gamefroot support.",
      });
    } finally {
      setSubmitting(false);
    }
  },
});

export default formikEnhancer(AssignTutor);
