// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// image imports
import devToolImg from "../../img/home/gdc banner devices.png";
import bespokeImg from "../../img/home/how-to-code-tute-stack.png";

const useStyles = makeStyles( theme => ({
  pageWrapper: {
    width: "100vw",
    paddingBottom: 70,
  },
  panle: {
    height: "100%",
    borderRadius: 20,
  },
  title: {
    textAlign: "center",
    fontSize: 30,
    color: "#505971",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    textAlign: "center",
    margin: "20px 0",
    [theme.breakpoints.only("xs")]: {
      margin: "20px auto",
    },
  },
  text: {
    width: "60%",
    color: "#505971",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("xs")]: {
      textAlign: "left",
      width: "80%",
    },
  },
  colouredSectionTitle: {
    color: "#505971",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    textAlign: "left",
    margin: "20px 0 0 30px",
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      marginTop: 12,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 14,
      marginTop: 12,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    }
  },
  colouredSectionText: {
    color: "#FFF",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    textAlign: "left",
    marginLeft: 30,
    fontSize: 13,
    marginTop: 5,
    [theme.breakpoints.only("sm")]: {
      fontSize: 11,
      marginTop: 3,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 15,
      marginTop: 5,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
      marginTop: 15,
    },
  },
  contentContainer: {
    width: "80%",
    maxWidth: 920,
    margin: "auto",
  },
  image: {
    width: "110%",
    height: "auto",
  },
  topPadding30: {
    width: "115%",
    paddingTop: 50,
    paddingLeft: 10,
  },
}))

export default function Projects() {
  const classes = useStyles()

  return (
    <div className={classes.pageWrapper}>
      <Typography className={classes.title}>
        Game Dev Club Learning Goals
      </Typography>
      <div className={classes.contentContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.panle} style={{backgroundColor: "#aa56fe",  }} >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  className={classes.colouredSectionTitle}
                >
                  Real Game Development Tools
                </Typography>
                <Typography
                  className={classes.colouredSectionText}
                >
                  An easy to use, online cloud-based platform to create 2D games
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img className={clsx(classes.image, classes.topPadding30)} src={devToolImg} alt="laptops showing dev tools"/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.panle} style={{ backgroundColor: "#399BFF"}}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  className={classes.colouredSectionTitle}
                >
                  Bespoke Game Dev Club resources
                </Typography>
                <Typography
                  className={classes.colouredSectionText}
                >
                  Learning goals are curriculum aligned & based on making real
                  games
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img className={classes.image} src={bespokeImg} alt="how to code booklets" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
      </div>
    </div>
  );
};
