// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

// material-ui component imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// SVG/images imports
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import backgroundSVG from "../../img/registration/angledPanelSVG.svg";
import bullseyeImg from "../../img/registration/direct-hit.png";

// component scope styles
const useStyles = makeStyles(theme => ({
    rootPositionedContainer: {
      position: "absolute",
      left: "105%",
      top: 0,
    },
    positionedContainer: {
      position: "relative",
      height: 360,
      marginBottom: 30,
    },
    background: {
      height: 360,
    },
    textContainer: {
      position: "absolute",
      top: 30,
      left: 30,
      width: 180,
    },
    icon: {
      marginBottom: -6,
      paddingRight: 15,
    },
    text: {
      marginBottom: 20,
      fontSize: "0.9rem",
    },
    // sponsor section styles
    sponorContainer: {
      width: 195,
      marginLeft: 30,
    },
    // term goals section styles
    tgBackground: {
      position: "relative",
      backgroundColor: "#FFFFFF",
      borderRadius: 3,
      width: 260,
      height: 230,
      marginBottom: 30,
    },
    // duty of care section styles
    dofContainer: {
      backgroundColor: "#479FEA",
      overflow: "hidden",
      borderRadius: 10,
      width: 195,
      marginTop: 5,
    },
    dofTextContainer: {
      backgroundColor: "#FEF187",
      marginLeft: 6,
      padding: "20px 12px",
    },
    dofBackground: {
      position: "relative",
      backgroundColor: "#FEE400",
      borderRadius: 3,
      width: 260,
      height: 320,
    },
    // bottom link styles
    linkContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "calc(100% - 30px)"
    },
  })
);
  
/**side bar on member registration page*/
const ClubDetails = ({where, when, price}) => {
  // hooks
  const classes = useStyles()
  
  // render component
  return (
    <div className={classes.rootPositionedContainer}>

      <div className={classes.positionedContainer}>
        <img className={classes.background} src={backgroundSVG} alt="stylized background container"/>
        <div className={classes.textContainer}>
          <Typography variant="h3" style={{marginBottom: 20}} >Club Details</Typography>  
          <Grid container style={{width: 180}}>

            <Grid item xs={2}>
              <RoomRoundedIcon className={classes.icon}/>   
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.text}>Where: {where}</Typography> 
            </Grid>

            <Grid item xs={2}>
              <TodayRoundedIcon className={classes.icon}/> 
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.text}>When: {when}</Typography> 
            </Grid>

            <Grid item xs={2}>
              <MonetizationOnRoundedIcon className={classes.icon}/> 
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.text}>Price: {price} per seat</Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" className={classes.text} style={{fontSize: "0.85rem"}}>
            Game Dev Clubs run over an 8 week period at the above location.
          </Typography>

        </div>
      </div>
{/* commented out until business decision has been made to continue with sponsorship or not */}
{/*      <div>
        <div className={classes.sponorContainer}>
          <Typography variant="h3" style={{marginBottom: 20}} >sponsor a member?</Typography> 
          <Typography variant="body1" className={classes.text} style={{fontSize: "0.85rem"}}>
            Not all kids can afford to pay for a club.
          </Typography> 
          <Typography variant="h4" style={{marginBottom: 40, color: "#479FEA"}} >SPONSOR A STUDENT</Typography>
        </div>
      </div>
*/}
      <div className={classes.tgBackground}>
        <div className={classes.textContainer}>
          <Typography variant="h3" style={{marginBottom: 20}} >
            <img src={bullseyeImg} alt="bullseye"  style={{width: 30, height: 30, marginRight: 10, marginBottom: -8}}/>
            Term Goals
          </Typography> 
          <Typography variant="body1" className={classes.text} style={{fontSize: "0.85rem"}}>
            Each term members learn cool coding tips and tricks and have the opportunity to 
            add more of their creations to their digital portfolio.
          </Typography>  
        </div>
      </div>

      <div className={classes.dofBackground}>
        <div className={classes.textContainer}>
          <div className={classes.dofContainer}>
            <div className={classes.dofTextContainer}>
              <Typography variant="subtitle1"  style={{marginBottom: 10}} >Duty of Care: </Typography>
              <Typography variant="body1" style={{fontSize: "0.95rem", marginBottom: 10}}>
                Game Dev Club takes student safety seriously. Our tutors are either 
                professionals that have been police checked or are practicing teachers 
                trained in Game Development. 
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
  
  export default ClubDetails;
