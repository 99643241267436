import CommentStore from "./CommentStore"
import GroupStore from "./GroupStore"
import LessonStore from "./LessonStore"
import RbacStore from "./RbacStore"
import TermStore from "./TermStore"
import UserStore from "./UserStore"

export default class RootStore {
  constructor() {
    this.commentStore = new CommentStore(this)
    this.groupStore = new GroupStore(this)
    this.lessonStore = new LessonStore(this)
    this.rbacStore = new RbacStore(this)
    this.termStore = new TermStore(this)
    this.userStore = new UserStore(this)
  }
}
