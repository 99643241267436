/**UTILITY FUNCTION: takes passed user subscription type convert it and 
 * returns the equivalent fetched RBAC rules access role. */
export default function subToRoleConverter (subscriptionType) {
  // Assumes making use of our current three subscriptionType's as of
  // creation of this code block (22-DEC-2021).
  // 
  // current subscriptionType values:
  // - "admin"
  // - "edu"
  // - "none"
  // 
  // deprecated subscriptionType values:
  // - "pro"
  // 
  if (subscriptionType === "edu") {
    // convert subscriptionType value to equivalent RBAC role value:
    // - "sub_edu"
    return "sub_edu"; 
  } else if (subscriptionType === "pro") {
    // continue to allow backwards compatibility with deprecated subscriptionType value.
    // convert subscriptionType value to equivalent RBAC role value:
    // - "sub_pro"
    return "sub_pro";
  } else {
    // assumes these other subscriptionType values match RBAC role values:
    // - "admin"
    // - "none"
    return subscriptionType;
  };
};
