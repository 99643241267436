import React from "react";
import { makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    borderRadius: "40px",
    height: 32,
    padding: '4px 30px',
    border: "2px solid transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: 'pointer',
    marginRight: 10,
    boxShadow: '0px 2px 4px -1px rgb(35 50 126 / 20%), 0px 4px 5px 0px rgb(35 50 126 / 14%), 0px 1px 10px 0px rgb(35 50 126 / 12%)'
  },
  text: {
    fontFamily: '"Fredoka One", cursive',
    textTransform: "uppercase",
    color: "#FFF",
    fontSize: 18,
    lineHeight: 1.3,
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('xs')]: {
      fontSize: 15,
    },
  },
  icon: {
    color: '#FFF',
    marginRight: 5,
    marginLeft: -10,
  }
}));

const RoundedButton = ({ icon, text, color, onClick }) => {
  const classes = useStyles();
  return (
    <motion.div
      style={{
        borderColor: color ? color : "#FFF",
        backgroundColor: color ? color : "transparent",
      }}
      className={classes.root}
      whileTap={{
        scale: 0.95,
      }}
      onClick={onClick}
    >
      {icon && React.cloneElement(icon, {className: classes.icon})}
      <Typography className={classes.text}>{text}</Typography>
    </motion.div>
  );
};

export default RoundedButton;
