
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import Fade from '@material-ui/core/Fade'
import SyncLoader from 'react-spinners/SyncLoader'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import NewComment from './discussion/NewComment'
import Comment from './discussion/Comment'
import LoadMore from '../common/LoadMore'
import ConfirmDialog from '../common/ConfirmDialog'

import {
  useGroupStore,
  useCommentStore
} from '../../state'

const ClubDiscussion = () => {
  const groupStore = useGroupStore()
  const commentStore = useCommentStore()
  const [replyTo, setReplyTo] = useState(null)
  const [focusComment, setFocusComment] = useState(null)
  const [confirm, setConfirm] = useState(false)

  const {
    comments,
    totalResults,
    submittingComment,
    loadingComments,
    loadingCommentsError,
    outOfComments,
  } = commentStore

  const handleDelete = id => {
    setFocusComment(id)
    setConfirm(true)
  }

  const handleAgree = () => {
    setConfirm(false)
    commentStore.deleteComment(focusComment)
    setFocusComment(null)
  }

  return (
    <>
      <Grid container justify='space-between'>
        <Typography variant={'h2'}>
          Discussion
      </Typography>
        <Typography variant={'subtitle2'}>
          {totalResults} comments
      </Typography>
      </Grid>

      <NewComment
        context='main'
        groupId={groupStore.currentClub.id}
        disabled={replyTo !== null || focusComment !== null || submittingComment}
      />

      {comments.map((comment, i) => (
        <div key={i}>
          <Comment
            {...comment}
            key={comment.id}
            i={comment.id}
            replyTo={replyTo}
            setReplyTo={id => setReplyTo(id)}
            focusComment={focusComment}
            setFocusComment={id => setFocusComment(id)}
            onDelete={handleDelete}
            isParent={true}
          />
          {comment.children?.map(child => (
              <Comment
                {...child}
                key={child.id}
                i={child.id}
                focusComment={focusComment}
                setFocusComment={id => setFocusComment(id)}
                onDelete={handleDelete}
                isParent={false}
              />
            ))
          }
        </div>
      ))}
      {!outOfComments &&
        <LoadMore
          loading={loadingComments}
          hasMore={!outOfComments}
          displayEOR={comments.length > 20}
          endMarker='No More Comments'
          getNext={() => commentStore.extendComments(comments.length)}
          auto={true}
        />
      }

      <ConfirmDialog
        open={confirm}
        title='Delete Comment'
        handleDisagree={() => setConfirm(false)}
        handleAgree={handleAgree}
      >
        <Typography>Delete comment permanently?</Typography>
      </ConfirmDialog>
    </>
  )
}

export default observer(ClubDiscussion)
