import { makeAutoObservable } from "mobx";
import Moment from "moment";

import {
  fetchTerm,
  fetchTerms,
  fetchGroupIdsByMember,
  fetchGroupIdsByGuardian,
  fetchTermStudentsGuardian,
  addTermMember,
  createTerm,
  createTermSession,
  editTerm,
  editTermSession,
  createRegistration,
  getMemId,
  setPaid,
  getMemberCount,
  fetchTermGuardians
} from "../api/terms";

class TermStore {
  loadingTerm = false;
  currentTerm;
  currentGroupId = 0;
//  groupTerms = [];
  terms = []

  editingTerm = false;
  editTermError;
  editTermSuccess;

  changingTermStatus = false;
  changeTermStatusSuccess;
  changeTermStatusError;

  removingMembers = false;
  removeMembersError;
  removeMembersSuccess;

  authorizingPayment = false;
  authorizingPaymentSuccess;
  authorizingPaymentError;

  creatingTerm = false;
  createTermError;
  createTermSuccess;

  creatingRegistration = false;
  createRegistrationError;
  createRegistrationSuccess;

  creatingMember = false;
  createMemberError;
  createMemberSuccess;

  constructor() {
    makeAutoObservable(this);
  }

  // <--- Computed --->

  // <--- Actions --->

//  resetSelection() {
//    this.groupTerms = [];
//    this.getGroupTerms();
//  }

  resetStatus(type) {
    return {
      term: () => {
        this.currentTerm = undefined;
        this.termError = undefined;
      },
      terms: () => {
        this.terms = [];
        this.currentGroupId = 0;
        this.termsError = undefined;
      },
      edit: () => {
        this.editTermSuccess = undefined;
        this.editTermError = undefined;
      },
      status: () => {
        this.changingTermStatus = false;
        this.changeTermStatusError = undefined;
        this.changeTermStatusSuccess = undefined;
      },
      create: () => {
        this.createTermSuccess = undefined;
        this.createTermError = undefined;
      },
      payment: () => {
        this.authorizingPaymentError = undefined;
        this.authorizingPaymentSuccess = false;
      },
      createMember: () => {
        this.creatingMemberError = undefined;
        this.creatingMemberSuccess = undefined;
      },
      remove: () => {
        this.removeMembersError = undefined;
        this.removeMembersSuccess = undefined;
      },
    }[type]();
  }

//  checkCacheForTerm(id) {
//    id = parseInt(id, 10);
//    const searchTerms = this.groupTerms.find((term) => term.id === id);
//    return searchTerms;
//  }

  // <--- Flow --->

  async getTerms(groupId) {
    this.loadingTerms = true;
    this.resetStatus("terms");

    const sortTerms = (a, b) => {
      let d1 = new Date(a.termSessions[0].sessionDate);
      let d2 = new Date(b.termSessions[0].sessionDate);
      if (d1 < d2) {
        return -1;
      } else if (d1 > d2) {
        return 1;
      } else {
        return 0;
      }
    }

    try {
      const { data } = await fetchTerms(groupId);
//      this.terms = data.sort((a, b) => new Date(a.termSessions[0].sessionDate) - new Date(b.termSessions.sessionDate));
      this.terms = data.sort(sortTerms);
      this.currentGroupId = groupId;
    } catch (err) {
      console.error(err);
      this.termsError =
        "Could not get terms.  Please refresh the page and try again.";
    } finally {
      this.loadingTerms = false;
    }
  }

  async getTermById(id) {
    this.loadingTerm = true;
    this.resetStatus("term");
//    const term = this.checkCacheForTerm(id);
//    if (term) {
//      this.currentTerm = term;
//      this.loadingTerm = false;
//    } else {
      try {
        const term = await fetchTerm(id);
        this.currentTerm = term;
      } catch (err) {
        console.log(err);
        this.termError = err;
      } finally {
        this.loadingTerm = false;
      }
//    }
  }

  async getTermMemberCount(id) {
    return getMemberCount(id);
  }

  async getTermGuardians(id) {
    const { data } = await fetchTermGuardians(id);
    return data;
  }

  // get IDs of groups for which user is a term member
  async getGroupIdsMember(userId) {
    try {
      const { data } = await fetchGroupIdsByMember(userId);
      var groupIds = [];
      data.forEach((group, i) => groupIds.push(group.groupId));
      return groupIds;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get club IDs. Please refresh the page and try again.";
    }
  }

  // get IDs of groups for which user is a guardian of term members
  async getGroupIdsGuardian(userId) {
    try {
      const { data } = await fetchGroupIdsByGuardian(userId);
      var groupIds = [];
      data.forEach((group, i) => groupIds.push(group.groupId));
      return groupIds;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get club IDs. Please refresh the page and try again.";
    }
  }

  async getTermStudentsGuardian(termId, userId) {
    try {
      const { data } = await fetchTermStudentsGuardian(termId, userId);
      return data;
    } catch (err) {
      console.error(err);
      this.membersError =
        "Could not get students for this term. Please refresh the page and try again.";
    }
  }



  async addTermMember(userId, termId, regId = null, paymentType = "manual", role = "member") {
    try {
      const { data } = await addTermMember(userId, termId, regId, paymentType, role);
      return true;
    } catch (err) {
      console.error(err);
      this.membersError = "Could not add member to term.";
      return false;
    }
  }

  async createTerm(data) {
    this.creatingTerm = true;
    try {
      this.createTermSuccess = await createTerm({
        groupId: data.groupId,
        location: data.location,
        memberLimit: data.memberLimit
      });
      if (this.createTermSuccess.id) {
        let termId = this.createTermSuccess.id;
        let sessionDate = Moment(data.date);

          this.createTermSuccess.termSessions = [];
          for (let i = 0; i < 8; i++) {
          this.createTermSuccess.termSessions.push(await createTermSession({
            termId: termId,
            location: data.location,
            sessionDate: sessionDate.format("YYYY-MM-DD"),
            startTime: data.startTime,
            endTime: data.endTime,
          }));
          sessionDate.add(7, "days");
        }
      }
    } catch (err) {
      this.createTermError = err;
      console.error(err);
    } finally {
      this.creatingTerm = false;
    };
  };

  async updateTerm(termId, data) {
    try {
      const updatedTerm = await editTerm(termId, {location: data.location});
      let termSessions = await Promise.all(data.termSessions.map(async (termSession, i) => {
        return await this.updateTermSession(termSession.id, termSession);
      }));
      updatedTerm.termSessions = termSessions;
      return updatedTerm;
    } catch (err) {
      console.error(err);
      this.termError = "Could not save changes to term.";
      return false;
    }
  }


  async updateTermSession(termSessionId, data) {
    try {
      const updatedTermSession = await editTermSession(termSessionId, data);
      return updatedTermSession;
    } catch (err) {
      console.error(err);
      this.termError = "Could not save changes to term session.";
      return false;
    }
  }


  async createRegistration(data) {
    this.creatingRegistration = true;
    try {
      this.createRegistrationSuccess = await createRegistration({
        termId: data.termId, 
        regUser: data.regUser, 
        firstName: data.firstName, 
        lastName: data.lastName, 
        phone: data.phone, 
        email: data.email, 
        coupon: data.coupon, 
        discount: data.discount, 
        price: data.price, 
        donation: data.donation, 
        paymentType: data.paymentType,
        paid: false});
      if (this.createRegistrationSuccess?.id) {
        return this.createRegistrationSuccess.id;
      } else {
        return false;
      }
    } catch (err) {
      this.createRegistrationError = err;
      console.error(err);
      return false;
    } finally {
      this.creatingRegistration = false;
    };
  };


  async setPaid(termId, userId) {
    const { data } = await getMemId(termId, userId);
    let id = data[0]?.id;
    if (data?.length && data[0]?.id) {
      return await setPaid(id);
    }
  }
  
};

export default TermStore;
