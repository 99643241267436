import { withFormik } from 'formik';
import * as yup from 'yup';
import Moment from 'moment';

import ClipLoader from 'react-spinners/ClipLoader';

import { makeStyles } from '@material-ui/styles';

import OutlinedButton from '../common/OutlinedButton';
import TextInput from '../common/TextInput';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    fontSize: '1.3rem',
    color: '#515873',
    webkitFontSmoothing: "antialiased"
  },
  subtitle: {
    ...theme.typography.h2,
    marginTop: 24
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    marginTop: 6,
    marginBottom: 6
  }
}));

const CreateTerm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Typography variant={'h2'}>
        Create A Term
      </Typography>
      <p>Terms run for eight sessions, on the same day each week.</p>

          <form onSubmit={handleSubmit} className={classes.form}>
          <Grid
        container
        direction='row'
        spacing={2}
        alignItems='center'
      >
        <Grid item xs={12} sm={4}>
            <TextInput
              id="date"
              type="date"
              label="Date of First Session"
              error={touched.date && values.date && errors.date}
              value={values.date}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
        </Grid>

<Grid item xs={12} sm={4}>
            <TextInput
              id="startTime"
              type="time"
              label="Start Time"
              error={touched.startTime && values.startTime && errors.startTime}
              value={values.startTime}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
        </Grid>

<Grid item xs={12} sm={4}>
            <TextInput
              id="endTime"
              type="time"
              label="End Time"
              error={touched.endTime && values.endTime && errors.endTime}
              value={values.endTime}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
        </Grid>

<Grid item xs={12} sm={8}>
            <TextInput
              id="location"
              type="text"
              label="Location"
              error={
                touched.location &&
                values.location &&
                errors.location
              }
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
        </Grid>

<Grid item xs={12} sm={4}>
            <TextInput
              id='memberLimit'
              type='number'
              label="Member Registration Limit"
              error={
                touched.memberLimit &&
                values.memberLimit &&
                errors.memberLimit
              }
              value={values.memberLimit}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
        </Grid>
        </Grid>

            <Grid container justify='flex-end'>
              <div className="login-spinner">
                <ClipLoader
                  loading={isSubmitting}
                  size={25}
                  color={"#0085ff"}
                />
                {errors.submit && <p color="danger">{errors.submit}</p>}
              </div>

              <OutlinedButton
                className="float-right"
                style={{ marginBottom: 30, marginTop: 30 }}
                color="#2196f3"
                size="medium"
                type="submit"
                disabled={
                  !values.date ||
                  !!errors.startTime ||
                  !!errors.endTime ||
                  !!errors.location
                }
              >
                CREATE TERM
              </OutlinedButton>
            </Grid>
          </form>
    </>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({
    location: props.location,
    memberLimit: props.memberLimit,
    date: new Date(),
    startTime: "17:00",
    endTime: "19:00",
  }),
  validationSchema: () => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          date: yup.date().required("Date is required."),
          startTime: yup.string().test(
              'start-valid',
              "Start time is required.",
              (value) => {
                return Moment(value, 'HH:mm', true).isValid();
              }),
          endTime: yup.string().test(
            'start-valid',
            "End time must be a valid time.",
            (value) => {              
              return !value || Moment(value, 'HH:mm', true).isValid();
            }),
          location: yup.string().required("Location is required."),
          memberLimit: yup.number(),
        })
      );
    });
  },
  handleSubmit: async (payload,
    { props, setSubmitting, setErrors }) => {
    try {
      await props.termStore.createTerm({
        ...payload,
        groupId: props.groupId
    })
    } catch (err) {
      setErrors({
        submit: err.message
      });
    } finally {
      setSubmitting(false);
      props.onFinished();
    }
  },
  displayName: "CreateTerm"
});

export default formikEnhancer(CreateTerm);