import { client, userService, emailService, seatService } from './client'

export const validateUnique = async (name, value) => {
  const [service, fieldName, publicTag] = {
    username: [userService, "username", "validateUniqueUsername"],
    email: [emailService, "value", "validateUniqueEmail"],
  }[name];
  try {
    const { total } = await service.find({
      query: {
        [fieldName]: value,
        $limit: 0,
        $public: publicTag,
      },
    });
    return total === 0;
  } catch (err) {
    throw err;
  }
};

export const updateUser = (id, data) => {
  userService.patch(id, data)
}

export const updateUserEmail = (id, data) => {
  emailService.patch(id, data)
}

export const createUser = (data) => userService.create(data);

export const createEmail = (data) => emailService.create(data);

/**
 * Change a members password who may have forgotten or been locked out
 * of there account. This action initates extra security checks server-side
 * ensuring that the user is an admin and shares a group with the user.
 * @param {Number} id target user of change password's id
 * @param {String} password new password
 * @param {Number} groupId groupId of member
 * @returns {Promise}
 */
export const updatePassword = (id, password, groupId) =>
  userService.patch(id, password, { groupId });

export const fetchSeats = (subId) =>
  seatService.find({
    query: {
      subscriptionId: subId,
    },
  });

export const createSeat = (data) => seatService.create(data);

export const findUser = (username) =>
  client.service("user").find({
    query: Object.assign(
      {
        username: username,
        attributes: ["id"]
      },
    ),
  });

