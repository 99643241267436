import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 6,
    marginBottom: 6,
  },
  errorHelper: {
    marginTop: "-12px"
  },
  label: {
    color: "#000000"
  }
}))

const CheckboxInput = ({
  type,
  className,
  id,
  label,
  touched,
  error,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormGroup>
    <FormControlLabel className={classes.container} control={
      <Checkbox
        id={id}
        name='manualPayment'
        checked={value}
        onChange={onChange}
        color='primary'
        className="checkbox-input"
        style={{color: (touched && !!error) ? '#f44336' : ''}}
        {...props}
      />
    }
    label={<InputLabel htmlFor={id} error={touched && !!error} className={classes.label}>{label}</InputLabel>}
    />
    {touched && !!error &&
      <FormHelperText className={classes.errorHelper}>{error}</FormHelperText>
  }
    </FormGroup>
  )
}

export default CheckboxInput
