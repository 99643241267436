const urls = {
  development: "http://dev.gamefroot.com:3000",
  staging: "https://staging.gamefroot.com",
  production: "https://make.gamefroot.com",
};

/** Return the appropriate editor URL for the current environment. */
const editorUrl = () => urls[process.env.REACT_APP_GAMEFROOT_ENV];

export default editorUrl;
