import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button"
import { Formik, Form } from "formik";
import * as yup from "yup";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import ClipLoader from "react-spinners/ClipLoader";
import { useUserStore } from "../../state"

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    marginBottom: 5,
    fontSize: "1.2rem",
    WebkitFontSmoothing: "antialiased",
    color: "#465477",
  },
  underline: {
    "&:before": {
      borderBottom: "2px solid rgba(0, 0, 0, 0.42)",
    },
  },
  loginButton: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    fontSize: "0.9rem",
    paddingTop: 6,
    paddingLeft: 14,
    paddingRight: 14,
    backgroundColor: "#389aff",
    "&:hover": {
      backgroundColor: "#389aff",
    },
  },
  textButton: {
    fontSize: "0.95rem",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    backfaceVisibility: "hidden",
    MozBackfaceVisibility: "hidden",
    WebkitBackfaceVisibility: "hidden",
  },
  section: {
    marginBottom: 25,
  },
  endAdornment: { position: "absolute", right: 5 },
  iconButton: {
    padding: 2,
  },
  spinnerWrapper: {
    marginRight: 8,
    display: "flex",
  },
  errorText: {
    fontSize: "0.9rem",
  },
}));

const loginSchema = yup.lazy((values) => {
  return yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });
});

const LoginForm = () => {
  const classes = useStyles();
  const userStore = useUserStore();
  const history = useHistory();


  const handleLogin = async (
    { email, password },
    { setSubmitting, setStatus }
  ) => {
    try {
      await userStore.login({ email, password });
      
      userStore.setShowLogin(false);
      history.push('/dashboard')
    } catch (err) {
      setStatus({ submitErr: err });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          password: "",
          email: "",
          showRecoverPassword: false,
          showPassword: false,
          remember: false,
        }}
        validationSchema={loginSchema}
        onSubmit={handleLogin}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          submitCount,
          isSubmitting,
          status,
        }) => {
          const error = (name) =>
            (values[name] || submitCount > 0) && errors[name];

          const handleClickShowPassword = () => {
            setFieldValue("showPassword", !values.showPassword);
          };

          return (
                <Form>
                  <div className={classes.section}>
                    <Typography className={classes.title}>Username</Typography>
                    <TextField
                      label="Your Username"
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(error("email"))}
                      helperText={error("email")}
                      onBlur={handleBlur}
                      type=""
                      name="email"
                      placeholder="Your Username"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                        },
                      }}
                      autoComplete="email"
                      fullWidth
                    />
                  </div>
                  <div className={classes.section}>
                    <Typography className={classes.title}>Password</Typography>
                    <TextField
                      label="Your Game Dev Club Password"
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(error("password"))}
                      helperText={error("password")}
                      onBlur={handleBlur}
                      type={values.showPassword ? "" : "password"}
                      name="password"
                      placeholder="Your Game Dev Club Password"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                        },
                        endAdornment: (
                          <div className={classes.endAdornment}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              className={classes.iconButton}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </div>
                        ),
                      }}
                      autoComplete="password"
                      fullWidth
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flexGrow: 1 }} />
                    <span
                      className={classes.spinnerWrapper}
                      style={{ marginRight: 8, display: "flex" }}
                    >
                      <ClipLoader
                        loading={isSubmitting}
                        size={25}
                        color="#389aff"
                      />
                    </span>
                    <Button
                      size="small"
                      href=""
                      type="submit"
                      className={classes.loginButton}
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                    >
                      Log in
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      left: -8,
                      width: "calc(100% + 16px)",
                      marginTop: 25,
                    }}
                  >
                    {status && status.submitErr && (
                      <Typography
                        className={classes.errorText}
                        color="secondary"
                      >
                        Incorrect Login
                      </Typography>
                    )}
                  </div>
                </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
