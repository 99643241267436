// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// custom component imports
import TextInput from "../common/TextInput";

// image imports
import creditCardImage from "../../img/registration/credit-card.png";
import { ContactSupportOutlined } from "@material-ui/icons";

// component scope styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  discount: {
    color: "#00d81e",
    fontWeight: "900",
  },
  price: {
    fontWeight: "900",
  },
  grid: {
    marginTop: 4,
    marginBottom: 24,
    alignItems: "center",
    "& .MuiGrid-item": {
      paddingTop: 0,
    },
  },
  imageIcon: {
    width: 40, 
    height: "auto", 
    marginBottom: -10, 
    marginLeft: 12,
  },
}));

/**guardian details/payment section */
const PaymentDetails = ({
  groupStore,
  groupId,
  values,
  touched,
  errors,
  onChange,
  onBlur,
  count,
  discountUpdate,
  setCouponErr,
  currentUser
}) => {
  // hooks
  const classes = useStyles();

  // gather data
  const couponText = React.useRef(null);
  const couponApply = React.useRef(null);

  React.useEffect(() => {
    if (values.discount > 0) {
      applyCoupon();
    }
  }, [count]);

  // event handlers
  const applyCoupon = async () => {
    couponText.current?.focus();
    values.discount = 0;
    values.coupon = 0;
    let couponErr = "";
    if (values.couponCode !== "") {
      let data = await groupStore.checkCoupon(values.couponCode, groupId);
      if (data.length > 0) {
        let coupon = data[0];
        let available = coupon.maxUses - coupon.timesUsed;
        if (!(coupon.maxUses > 0)) {
          available = 99;
        }
        if (available < 1) {
          couponErr = "That coupon has already reached its maximum number of uses, sorry!";
        } else if (count > available) {
          couponErr = "That coupon cannot be used for that many Attendees, sorry!";
        } else {
          values.coupon = coupon.id;
          if (coupon.couponType === "dollars") {
            values.discount = count * coupon.value;
          } else {
            values.discount = count * coupon.value / 100 * values.price;
          }
        }
      } else {
        couponErr = "That is not a currently valid coupon for this Club.";
      }
    }
    await setCouponErr(couponErr);
    discountUpdate(values.discount);
    couponApply.current?.focus();
  };

  const removeDiscount = () => {
    values.discount = 0;
    values.coupon = 0;
    values.couponCode = "";
    discountUpdate(values.discount);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        2: Guardian Details
      </Typography>
      <Typography variant="subtitle1">Guardian Details</Typography>

      <Grid container spacing={2} className={classes.grid}>
        <Grid item sm={6} xs={12}>
          <TextInput
            name="firstName"
            label="First Name"
            value={values.firstName}
            onChange={onChange}
            onBlur={onBlur}
            error={touched.firstName && errors.firstName}
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            variant="filled"
            readOnly={(currentUser?.id && currentUser?.firstName)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextInput
            name="lastName"
            label="Last Name"
            value={values.lastName}
            onChange={onChange}
            onBlur={onBlur}
            error={touched.lastName && errors.lastName}
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            readOnly={(currentUser?.id && currentUser?.lastName)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextInput
            name="phone"
            label="Phone Number"
            value={values.phone}
            onChange={onChange}
            onBlur={onBlur}
            error={touched.phone && errors.phone}
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            readOnly={(currentUser?.id && currentUser?.phone)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextInput
            name="email"
            label="Email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            error={touched.email && errors.email}
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            readOnly={(currentUser?.id && currentUser?.email?.value)}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Coupon Codes</Typography>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={8} sm={3}>
          <TextInput
            inputRef={couponText}
            name="couponCode"
            label="Enter Coupon Code"
            value={values.couponCode}
            onChange={onChange}
            onBlur={onBlur}
            error={touched.couponCode && errors.couponCode}
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
          />
        </Grid>

        <Grid item xs={4} sm={3}>
          <Button
            ref={couponApply}
            variant="outlined"
            onClick={applyCoupon}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      
      <Typography variant="subtitle1">Amount to pay</Typography>
  {(values.coupon > 0) &&
    <>
      <Grid container spacing={2} className={classes.grid} direction="row">
        <Grid item xs={8} sm={3}>
          <TextInput
            name="discount"
            label="Discount"
            value={
              values.discount ? values.discount.toFixed(2) : "0.00"
            }
            className={classes.discount}
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            fullWidth
            readOnly
            adornment="-$"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button variant="outlined" onClick={removeDiscount} >
            Remove Discount
          </Button>
        </Grid>
      </Grid>
    </>
  }

      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={8} sm={3}>
          <TextInput
            name="price"
            label="Price"
            value={(values.price * count - values.discount).toFixed(2)}
            className={classes.price}
            readOnly
            fullWidth
            bgcolor="#eaeff5"
            helperbgcolor="#ffffff"
            adornment="$"
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography>{`${count}x Attendee${
            count > 1 ? "s" : ""
          }`}</Typography>
        </Grid>   
      </Grid>
    </div>
  );
};

export default PaymentDetails;
