import { login, logout, checkEmail, federatedLogin } from "../api/auth";
import { makeAutoObservable, observable } from "mobx";
import firebase from "firebase/app";
import { makePersistable } from "mobx-persist-store" 
import {
  updateUser,
  updateUserEmail,
  validateUnique,
  updatePassword,
  fetchSeats,
  createUser,
  createEmail,
  findUser,
} from "../api/user";
import {
  sendEmail,
} from "../api/sendEmail";
import subToRoleConverter from "../utils/sub-role-converter";

class UserStore {
  constructor(RootStore) {
    this.rootStore = RootStore
    makeAutoObservable(this);

    makePersistable(this, { 
      name: 'UserStore', 
      properties: ['currentUser'], 
      storage: window.localStorage  ,
      expireIn: 86400000,
      removeOnExpiration: true
    })
  }

  // <--- Observables --->

  currentUser = {
    id: null,
    username: "",
    subscriptionType: "",
    firstName: "",
    lastName: "",
    createdAt: "",
    updatedAt: "",
    type: "",
    role: "none",
    lessonRecords: {},
    members: [],
    email: {}
  };

  loadingUser = false;
  loadingUserErrors = null;

  changingPassword = false;
  changePasswordSuccess = undefined;
  changePasswordError = undefined;
  errorMessage = undefined;

  profile = {};

  subscription = {};
  activeSeats = [];

  showLogin = false;

  // <--- Actions --->
  populateProfile() {
    this.profile = new Profile(this.currentUser);
  }

  pushSeat(seat) {
    this.activeSeats.push(seat);
  }

  setShowLogin(val) {
    this.showLogin = val;
  }

  // <--- Computed --->
  get remainingSeats() {
    return this.subscription.seats - this.activeSeats.length;
  }

  // <--- Flow --->
  async login(credentials) {
    try {
      // If credentials is undefined - login will get user from session
      const data = await login(credentials);
      const user = data?.user;

      if (user) {
        this.currentUser = user;
        this.rootStore.rbacStore.setRole(subToRoleConverter(user.subscriptionType));

        this.getSubscription();
//        this.currentUser = user;

        if (!user.email) {
          const emails = await checkEmail(user.id);
          console.log(emails);
          if (emails.length > 0) {
            user.email = emails[0];
          } else {
            this.showAddEmail = true;
          }
        }
      } else {
        logout();
      }
    } catch (err) {
      this.currentUser = undefined;
      console.log(err)
      // throw err;
    }
  }

  async federatedLogin(provider, callback) {
    const google = new firebase.auth.GoogleAuthProvider();
    const facebook = new firebase.auth.FacebookAuthProvider();

    try {
      const user = await federatedLogin(
        provider === "google" ? google : facebook
      );

      if (user) {
        this.currentUser = user;

        callback && callback();
        this.setShowLogin(false);
        this.getSubscription();
      }
    } catch (err) {
      this.currentUser = undefined;
      this.federatedError =
        "This email is already in use.  Try signing in a different way";
      throw err;
    }
  }

  async logout() {
    await logout();
    this.currentUser = {
      id: null,
      role: "none",
    };
    this.rootStore.groupStore.myClubs = []
    this.rootStore.groupStore.myClubMembers = []
    this.rootStore.rbacStore.setRole("none");
    window.location = "/";
  }    

  async getSubscription() {
    if (this.currentUser.subscriptions?.length) {
      this.subscription = this.currentUser.subscriptions[0];

      if (this.subscription.type === "edu") {
        try {
          const { data } = await fetchSeats(this.subscription.id);
          this.activeSeats = data;
        } catch (err) {}
      }
    }
  }

  async changePassword(id, password, groupId) {
    this.changingPassword = true;
    this.changePasswordSuccess = undefined;
    this.changePasswordError = undefined;
    try {
      await updatePassword(id, password, groupId);
      this.changePasswordSuccess = "Password updated successfully.";
    } catch (err) {
      console.error(err);
      this.changePasswordError =
        "Password could not be updated. Perhaps you lack the permissions to do so? If you think this is a mistake, try again.";
    } finally {
      this.changingPassword = false;
    }
  }

  async createUser(firstName, lastName, phone = "", email = "", password = "", type = "student", username = "") {
    this.errorMessage = undefined;
    if (email) {
      let emailUnique = await validateUnique("email", email);
      if (!emailUnique) {
        this.errorMessage = "That email address is already in use.";
        return false;
      }
    }
    if (!username) {
      let defaultuser = "" + firstName + lastName;
      username = defaultuser.replaceAll(/[^a-z0-9_-]/gim, "");
    }
    let valid = await validateUnique("username", username);
    let tries = 0;
    while (!valid && tries < 100) {
      let nameNumber = Math.floor(Math.random() * 8999 + 1000);
      valid = await validateUnique("username", username + nameNumber);
      if (valid) username += nameNumber;
      tries++;
    }
    if (valid) {
      if (password == "") {
        let chars = "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
        for (let i = 0; i < 8; i++)
          password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      const user = await createUser({
        username: username,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        password: password,
        type: type,
      });
      if (user?.id) {
        user.password = password;
        if (email) {
          await createEmail({owner: user.id, value: email });
        }
      }
      return user;
    } else {
      return false;
    }
  }

  async updateUser(userId, data, emailChanged = false) {
    this.errorMessage = undefined;
    if (data.email && emailChanged) {
      let emailUnique = await validateUnique("email", data.email);
      if (!emailUnique) {
        this.errorMessage = "That email address is already in use.";
        return false;
      }
      let result = await updateUserEmail(userId, data);
      console.log(result);
      if (!result) {
        this.errorMessage = "Error updating email.";
        return false;
      }
    }
    if (data.username) {
      let valid = await validateUnique("username", data.username);
      if (!valid) {
        this.errorMessage = "That username is not valid.";
        return false;
      }
    }
    return await updateUser(userId, data);
  }

  async validateUsername(username, allowChange = false) {
    if (!username || username.length > 60) {
      return false;
    }
    let newUsername = username.replaceAll(/[^a-z0-9_-]/gim, "");
    if (allowChange && newUsername.length > 0) {
      username = newUsername;
    } else {
      if (username !== newUsername) {
        return false;
      }
    }

    let valid = await validateUnique("username", username);
    if (allowChange) {
      let tries = 0;
      while (!valid && tries < 100) {
        let nameNumber = Math.floor(Math.random() * 8999 + 1000);
        valid = await validateUnique("username", username + nameNumber);
        if (valid) username += nameNumber;
        tries++;
      }
    }
    if (valid) {
      return username;
    } else {
      return false;
    }
  }

  async validateEmail(email) {
    if (!email) {
      return false;
    }
    let valid = await validateUnique("email", email);
    if (valid) {
      return true;
    } else {
      return false;
    }
  }

  async findUser(username) {
    try {
      const {data} = await findUser(username);
      if (data?.length > 0) return data[0].id;
    } catch (err) {
      throw err;
    }
  }

  async sendEmail(to, subject, body, bodyHtml = "") {
    if (to.toLowerCase().includes("@example.com")) {
      console.log("example address, email not actually sent");
      let result = {response: "OK"}
      return result;
    }
    try {
      const result = await sendEmail({sender: this.currentUser?.id, recipient: to, subject: subject, body: body, bodyHtml: bodyHtml});
      if (result) {
        console.log("email sent to " + to);
      }
      return result;
    } catch (err) {
      throw err;
    }
  }

}


class Profile {
  constructor(user) {
    makeAutoObservable(this);
    

    if (user) {
      const { firstName, lastName, username, email, id } = user;
      this.originalValues = observable({
        name: `${firstName} ${lastName}`,
        username,
        email: email && email.length && email[0].email,
        id,
        emailId: email && email.length && email[0].emailId,
      });
    } else {
      this.originalValues = observable({
        username: "",
        email: "",
      });
    }
  }

  // <--- Observables --->
  isValid = {};
  isValidating = {};
  needsValidating = {};

  pastValid = {};
  pastInvalid = {};

  // <--- Actions --->\
  checkNeedsValidation(values) {
    Object.keys(values).forEach((key) => {
      if (!this.pastValid[key]) {
        this.pastValid[key] = [];
      }
      if (!this.pastInvalid[key]) {
        this.pastInvalid[key] = [];
      }
      if (this.pastValid[key].includes(values[key])) {
        this.needsValidating = { ...this.needsValidating, [key]: false };
        this.isValid = { ...this.isValid, [key]: true };
      } else if (this.pastInvalid[key].includes(values[key])) {
        this.needsValidating = { ...this.needsValidating, [key]: false };
        this.isValid = { ...this.isValid, [key]: false };
      } else if (values[key] !== this.originalValues[key]) {
        this.isValid = { ...this.isValid, [key]: undefined };
        this.needsValidating = { ...this.needsValidating, [key]: true };
      } else {
        this.isValid = { ...this.isValid, [key]: undefined };
        this.needsValidating = { ...this.needsValidating, [key]: false };
      }
    });
  }

  // <--- Flow --->

  async validateUnique({ email, username }) {
    if (this.needsValidating.email) {
      this.isValidating = { ...this.isValidating, email: true };
      try {
        const valid = await validateUnique("email", email);
        if (valid) {
          this.pastValid.email = [...this.pastValid.email, email];
          this.isValid = { ...this.isValid, email: true };
        } else {
          this.pastInvalid.email = [...this.pastInvalid.email, email];
          this.isValid = { ...this.isValid, email: false };
        }
        this.needsValidating = { ...this.needsValidating, email: false };
      } catch (err) {
        console.error(err);
      } finally {
        this.isValidating = { ...this.isValidating, email: false };
      }
    }
    if (this.needsValidating.username) {
      try {
        this.isValidating = { ...this.isValidating, username: true };
        const valid = await validateUnique("username", username);
        if (valid) {
          this.pastValid.username = [...this.pastValid.username, username];
          this.isValid = { ...this.isValid, username: true };
        } else {
          this.pastInvalid.username = [...this.pastInvalid.username, username];
          this.isValid = { ...this.isValid, username: false };
        }
        this.needsValidating = { ...this.needsValidating, username: false };
      } catch (err) {
        console.error(err);
      } finally {
        this.isValidating = { ...this.isValidating, username: false };
      }
    }
  }

  async submit({ email, name, username }) {
    const {
      id,
      emailId,
      email: prevEmail,
      name: prevName,
      username: prevUsername,
    } = this.originalValues;
    try {
      if (email !== prevEmail) {
        await updateUserEmail(emailId, { value: email });
        this.originalValues.email = email;
      }
      if (username !== prevUsername || name !== prevName) {
        const [firstName = "", lastName = ""] = name.split(" ");
        await updateUser(id, { username, firstName, lastName });
        this.originalValues.username = username;
        this.originalValues.name = name;
      }
    } catch (err) {
      throw err;
    }
  }

}

export default UserStore;
