import InputLabel from '@material-ui/core/InputLabel'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 6,
    marginBottom: 6,
    borderRadius: 3,
    overflow: "hidden",
    "& .MuiInputLabel-root": {
      paddingTop: 8,
      paddingLeft: 8,
    },
    "& .MuiInputBase-root": {
      paddingTop: 8,
      paddingLeft: 8,
    },   
    "& .MuiFormHelperText-root": {
      marginTop: 0,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    }    
  }
}))

const SelectInput = ({
  children,
  type,
  className,
  id,
  label,
  error,
  value,
  onChange,
  icon,
  adornment,
  fullWidth,
  variant,
  bgcolor = false,
  helperbgcolor = false,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.container} fullWidth={fullWidth} style={{backgroundColor: bgcolor ? bgcolor : null}}>
      {((type === 'date' || type === 'time') ? 
        <InputLabel htmlFor={id} error={!!error} shrink="true">{label}</InputLabel>
      :
        <InputLabel htmlFor={id} error={!!error} >{label}</InputLabel>
      )}
      {icon}
      <Select
        id={id}
        variant={variant}
        className={clsx(className, "text-input")}
        value={value || ''}
        onChange={onChange}
        error={!!error}
        MenuProps={{
          transformOrigin: {
            vertical: -41,
            horizontal: -15
          },
        }}
        {...props}
        startAdornment={adornment && <InputAdornment position="start">
          {adornment}
        </InputAdornment>}
        >
        {children}
      </Select>
      <FormHelperText style={{backgroundColor: helperbgcolor ? helperbgcolor : null}}>{error && error}</FormHelperText>
    </FormControl>
  )
}

export default SelectInput
