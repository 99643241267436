import { withFormik } from 'formik';
import * as yup from 'yup';
import Moment from "moment";

import ClipLoader from 'react-spinners/ClipLoader';

import { makeStyles } from '@material-ui/styles';

import OutlinedButton from '../common/OutlinedButton';
import TextInput from '../common/TextInput';
import SelectInput from '../common/SelectInput';

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    fontSize: '1.3rem',
    color: '#515873',
    webkitFontSmoothing: "antialiased"
  },
  subtitle: {
    ...theme.typography.h2,
    marginTop: 24
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    marginTop: -12,
    marginBottom: 6,
  },
}));

const CreateCoupon = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    groupStore,
    rbacStore,
    groupIds,
    groupNames,
    onFinished
  } = props;

  const classes = useStyles();

  return (
    <>
      <Typography variant={'h2'}>
        Create A Coupon
      </Typography>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={6}>
            <SelectInput
              label='Group'
              name='couponGroup'
              id='couponGroup'
              value={values.couponGroup}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              >
              {((groupIds.length > 1) && !rbacStore.canPerformSysAdminActions) &&
                <MenuItem value="0" key={0}>All (duplicate for each)</MenuItem>
              }
              {(rbacStore.canPerformSysAdminActions) &&
                <MenuItem value="0" key={0}>All (universal coupon)</MenuItem>
              }
              {groupIds
                .map((group, i) => (
                  <MenuItem value={group} key={i + 1}>{groupNames[i]}</MenuItem>
                ))
              }
            </SelectInput>
            </Grid>

              <Grid item xs={12} sm={6}>

            <TextInput
              id="couponCode"
              type="text"
              label="Coupon Code"
              error={touched.couponCode && values.couponCode && errors.couponCode}
              value={values.couponCode}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
            </Grid>

            <Grid item xs={12} sm={6}>
            <SelectInput
              label='Coupon Type'
              name='couponType'
              id='couponType'
              value={values.couponType}
              error={
                touched.couponType &&
                values.couponType &&
                errors.couponType
              }
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="dollars">Dollars</MenuItem>
            </SelectInput>
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextInput
              id="couponValue"
              type="number"
              label="Value"
              error={
                touched.couponValue &&
                values.couponValue &&
                errors.couponValue
              }
              value={values.couponValue}              
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 100 } }}
            />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                  id='maxUses'
                  type='number'
                  label="Maximum Uses (0 for unlimited)"
                  error={
                    touched.maxUses &&
                    values.maxUses &&
                    errors.maxUses
                  }
                  value={values.maxUses}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  bgcolor="#eaeff5"
                  helperbgcolor="#ffffff"
                  fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextInput
              id="expires"
              type="date"
              label="Expiry Date"
              error={touched.expires && values.expires && errors.expires}
              value={values.expires}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
            </Grid>
            </Grid>

            <Grid container justify='flex-end'>
              <div className="login-spinner">
                <ClipLoader
                  loading={isSubmitting}
                  size={25}
                  color={"#0085ff"}
                />
                {errors.submit && <p color="danger">{errors.submit}</p>}
              </div>

              <OutlinedButton
                className="float-right"
                style={{ marginBottom: 30, marginTop: 30 }}
                color="#2196f3"
                size="medium"
                type="submit"
                disabled={
                  !values.couponCode ||
                  !!errors.couponCode ||
                  !!errors.couponType ||
                  !!errors.couponValue ||
                  !!errors.maxUses ||
                  !!errors.expires
                }
              >
                CREATE COUPON
              </OutlinedButton>
            </Grid>
          </form>
    </>
  )
}

const formikEnhancer = withFormik({
  validateOnChange: false,
  mapPropsToValues: props => ({
    couponGroup: (props.groupIds.length == 1 ? props.groupIds[0] : "0"),
    couponCode: "",
    couponType: "percentage",
    couponValue: 10,
    expires: Moment().add(1, 'years').format("YYYY-MM-DD"),
  }),
  validationSchema: (props) => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          couponCode: yup.string().required("Coupon Code is required.").test(
            'uniqueCode',
            'This Code is not available; please choose a different one.',
            async (value) => (await props.groupStore.isCouponUnique({code: value, groupId: values.couponGroup}))),
          couponType: yup.string().required("Coupon Type is required."),
          couponValue: yup.number().required("Coupon Value is required."), //.min("Coupon Value must be at least 1.").max(100, "Coupon Value cannot be more than 100."),
          expires: yup.date().min(new Date(), "Expiry Date cannot be in the past."),
          maxUses: yup.number().integer("Max Uses must be an integer.").min(0, "Max Uses cannot be less than zero."),
        })
      );
    });
  },
  handleSubmit: async ({...payload },
    { props, setSubmitting, setErrors }) => {
    try {
      let groupIds = [payload.couponGroup];
      let couponCode = payload.couponCode;
      let couponType = payload.couponType;
      let value = Math.min(100, Math.max(1, payload.couponValue));
      let maxUses = Math.max(0, payload.maxUses);
      let expires = payload.expires;
      if ((payload.couponGroup == 0) && !props.rbacStore.canPerformSysAdminActions) {
        groupIds = props.groupIds;
      }

      await Promise.all(groupIds.map(async (groupId) => {
        await props.groupStore.createCoupon({
          groupId,
          couponCode,
          couponType,
          value,
          maxUses,
          expires
        });
      }));
    } catch (err) {
      setErrors({
        submit: err.message
      });
    } finally {
      setSubmitting(false);
      props.onFinished();
    }
  },
  displayName: "CreateCoupon"
});

export default formikEnhancer(CreateCoupon);