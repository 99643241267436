// logic imports
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// custom component imports
import Nav from "../components/common/Nav";
import Splash from "../components/home/Splash";
import InfoSection from "../components/home/InfoSection";
import Content from "../components/home/Content";
import Projects from "../components/home/Projects";
import GetInvolved from "../components/home/GetInvolved";

// component scope styles
const useStyles = makeStyles((theme) => ({
  pageWrapper:{
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
}));

// functional component
const Home = () => {
  // declare hooks
  const classes = useStyles();

  // render component
  return (
    <div className={classes.pageWrapper}>
      <Nav />
      <Splash />
      <InfoSection />
      <Content />
      <Projects />
      <GetInvolved />
    </div>
  );
};

export default Home;