// logic imports
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// component scope styles
const useStyles = makeStyles(theme =>({
  container: {
    padding: "5px 30px 0px 30px",
  },
  text: {
    color: "#FFFFFF",
  },
  padded: {
    paddingBottom: 20,
  },
}));

/**
 * component to act as a filler for the federated login side of the login page until 
 * functionality is restored.*/
const LoginFiller = () => {
  // hooks
  const classes = useStyles();
  // render statement
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>This is the login area for:</Typography>
      <Typography className={classes.text}>- Parents and guardians</Typography>
      <Typography className={classes.text}>- GDC tutors</Typography>
      <Typography className={clsx(classes.text, classes.padded)}>- GDC administrators</Typography>
      <Typography className={classes.text}><Link>Contact us</Link> for account enquiries</Typography>
    </div>
  );
};

export default LoginFiller;
