import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// logic imports
import makeStyles from "@material-ui/core/styles/makeStyles";
import { pickColour } from "../../utils/color-picker";
// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
// SVG/images imports
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";

// component scope styles
const useStyles = makeStyles(theme => ({
  cardContainer: {
    padding: 12,
  },
  avatar: {
    position: "absolute",
    width: 75,
    height: 75,
    borderRadius: 5,
  },
  right: {
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: 90,
    "& h3": {
      margin: 0,
    },
    "& p": {
      maxWidth: "100%",
      boxSizing: "border-box",
      marginTop: 6,
      marginBottom: 0,
    },
    "& h3, & p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  icon: {
    marginBottom: -5,
  },
}));

/**reusable component for displaying each club a parent has children attending on parents 
 * dashboard page.*/
const ParentViewClubCard = (club) => {
  // hooks
  const classes = useStyles();
  const children = [];
  // render statement
  return (
    <motion.div
      whileHover={{
        scale: 1.025,
        transition: { duration: 0.1, ease: "easeIn" },
      }}
      whileTap={{ scale: 0.99 }}
      className="club-entry"
    >
      <Link to={"/group/" + club.id} style={{ textDecoration: 'none' }}>
        <Paper className={classes.cardContainer}>
          <Avatar
            style={{ backgroundColor: pickColour(club.id) }}
            className={classes.avatar}
          >
            <Typography variant="h1">{club.name.substring(0, 1)}</Typography>
          </Avatar>
          <div className={classes.right}>
            <Typography variant="h3">{club.name}</Typography>

        <Typography className={classes.text}>
          <RoomRoundedIcon className={classes.icon}/> Location: {club.location}
        </Typography>

        <Typography className={classes.text}>
          <TodayRoundedIcon className={classes.icon}/> When: {club.when}
        </Typography>

        <Typography className={classes.text}>
          <SchoolRoundedIcon className={classes.icon}/> Contact: {club.contact}
        </Typography>

        <Typography className={classes.text}>
          <PhoneIcon className={classes.icon}/> Phone: {club.contactNumber}
        </Typography>    

        <Typography className={classes.text}>
          <EmailIcon className={classes.icon}/> Email: {club.contactEmail}
        </Typography>

        <Typography className={classes.text}>
          <PeopleAltRoundedIcon className={classes.icon}/> Children enrolled in this club:
        </Typography>

        {children.map((child, i) => <Typography key={i} className={classes.listText} >{child}</Typography>)}
          </div>
        </Paper>
      </Link>
    </motion.div>
  );
};

export default ParentViewClubCard;
