import { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment'

import ClipLoader from 'react-spinners/ClipLoader';

import { makeStyles } from '@material-ui/styles';

import OutlinedButton from '../common/OutlinedButton';
import TextInput from '../common/TextInput';
import TextAreaInput from '../common/TextAreaInput';
import SelectInput from '../common/SelectInput';
import { getLatLong } from '../common/Geocode'

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'

import {
  useRbacStore
} from '../../state';

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    fontSize: '1.3rem',
    color: '#515873',
    webkitFontSmoothing: "antialiased"
  },
  subtitle: {
    ...theme.typography.h2,
    marginTop: 24
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    marginTop: -12,
    marginBottom: 6,
  },
  cancelIcon: {
    color: '#515873',
    float: 'right'
  }
}));

const CreateClub = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    orgs,
    groupStore,
    termStore,
    userStore,
    onFinished
  } = props;

  const rbacStore = useRbacStore();
  const classes = useStyles();
  const [existingOrganisation, setExistingOrg] = useState(false);
  const [termCount, setTermCount] = useState(1);

  const userId = userStore.currentUser.id;

  const clubLimitReached = !isSubmitting && (groupStore.myClubs.filter(club => club.creatorId === userId).length > 3) && (rbacStore.role === "none");

  useEffect(() => {
    setExistingOrg(orgs.length > 0);
  }, [orgs]);

  const handleExistingOrg = (e, { props }) => {
    if (props.children === "+ Create New Organisation") {
      setExistingOrg(false);
    } else {
      handleChange(e);
    };
  };

  const handleAddTerm = numTerms => {
    setTermCount(termCount + 1);
    return {
      target: {
        name: `terms[${numTerms}]`,
        value: { startTime: "15:15", endTime: "17:00", startDate: moment().format('YYYY-MM-DD') }
      }
    }
  }

  const removeTerm = (i) => {
    props.values.terms.splice(i, 1);
    setTermCount(termCount - 1);
  }

  return (
    <>
      <Typography variant={'h2'}>
        Club Setup
      </Typography>
      {clubLimitReached ? (
        <p>You are not authorised to create additional clubs.</p>
      ) : (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12} sm={6}>
            <Typography variant={'h3'} style={{ marginTop: 24 }}>
              Club Details
              </Typography>

            <TextInput
              id="name"
              type="text"
              label="Club Name"
              error={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />
            {existingOrganisation ? (
            <SelectInput
              label='Select your organisation'
              name='organiserName'
              id='organiserName'
              value={values.organiserName || ""}
              onChange={handleExistingOrg}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              >
                  <MenuItem>
                    + Create New Organisation
                  </MenuItem>

                  {orgs.map((organisation, i) => (
                    <MenuItem key={i} value={organisation}>
                      {organisation}
                    </MenuItem>
                    ))}
            </SelectInput>
            ) : (
                <TextInput
                  id='organiserName'
                  type='text'
                  label="Organisation"
                  error={
                    touched.organiserName &&
                    errors.organiserName
                  }
                  value={values.organiserName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  bgcolor="#eaeff5"
                  helperbgcolor="#ffffff"
                  fullWidth
                />
              )}

            <TextAreaInput
              rowsMax={4}
              id="description"
              label="Club Description"
              error={
                touched.description && errors.description
              }
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              rows="4"
            />

            <TextInput
                id='memberLimit'
                type='number'
                label="Member Registration Limit"
                error={
                  touched.memberLimit &&
                  errors.memberLimit
                }
                value={values.memberLimit}
                onChange={handleChange}
                onBlur={handleBlur}
                bgcolor="#eaeff5"
                helperbgcolor="#ffffff"
                fullWidth
              />

            <TextInput
              id="contactName"
              type="text"
              label="Contact Name"
              error={
                touched.contactName &&
                errors.contactName
              }
              value={values.contactName}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="contactEmail"
              type="email"
              label="Contact Email"
              error={
                touched.contactEmail &&
                errors.contactEmail
              }
              value={values.contactEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="contactPhone"
              type="phone"
              label="Contact Phone"
              error={
                touched.contactPhone &&
                errors.contactPhone
              }
              value={values.contactPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="location"
              type="text"
              label="Location/Room"
              error={touched.location && errors.location}
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <SelectInput
              label='Visibility'
              name='visibility'
              id='visibility'
              value={values.visibility}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
              >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </SelectInput>

            <Typography variant={'h3'} style={{ marginTop: 24 }}>
              Club Address
            </Typography>

            <TextInput
              id="address_1"
              type="text"
              label="Address line 1"
              error={touched.address_1 && errors.address_1}
              value={values.address_1}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="address_2"
              type="text"
              label="Address line 2"
              error={touched.address_2 && errors.address_2}
              value={values.address_2}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="city"
              type="text"
              label="City"
              error={touched.city && errors.city}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="region"
              type="text"
              label="Region"
              error={touched.region && errors.region}
              value={values.region}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="postal_code"
              type="text"
              label="ZIP code / Post code"
              error={touched.postal_code && errors.postal_code}
              value={values.postal_code}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <TextInput
              id="country"
              type="text"
              label="Country"
              error={touched.country && errors.country}
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              bgcolor="#eaeff5"
              helperbgcolor="#ffffff"
              fullWidth
            />

            <Typography variant={'h3'} style={{ marginTop: 24 }}>
              Club Pricing
            </Typography>

                <p>Club Price: <strong>$200.00</strong> (Clubs pricing is preset)</p>

          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'h3'} style={{ marginTop: 24 }}>
              Club Timetable
            </Typography>

            {(termCount > 0) &&
              values.terms.map((term, i) => (
              <div key={i}>
                <CancelIcon
                  className={classes.cancelIcon} 
                  onClick={() => removeTerm(i)}
                  />
                <Typography variant='h4'>{`Term ${i + 1}`}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextInput
                      name={`terms[${i}].startTime`}
                      label="Start Time"
                      type="time"
                      value={term.startTime}
                      error={touched.terms && touched.terms[i]?.startTime && errors.terms && errors.terms[i]?.startTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 300,
                      }}
                      fullWidth
                      bgcolor="#eaeff5"
                      helperbgcolor="#ffffff"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={`terms[${i}].endTime`}
                      label="End Time"
                      type="time"
                      value={term.endTime}
                      error={touched.terms && touched.terms[i]?.endTime && errors.terms && errors.terms[i]?.endTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 300,
                      }}
                      fullWidth
                      bgcolor="#eaeff5"
                      helperbgcolor="#ffffff"
                    />
                  </Grid>
                </Grid>
                <TextInput
                  name={`terms[${i}].startDate`}
                  label="Start Date"
                  type="date"
                  value={term.startDate}
                  error={touched.terms && touched.terms[i]?.startDate && errors.terms && errors.terms[i]?.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  bgcolor="#eaeff5"
                  helperbgcolor="#ffffff"
                />
              </div>
              ))}

              <Typography variant='body2' style={{ marginTop: 12, marginBottom: 12 }}>
                *Clubs run for 8 sessions, and your club will be active for that duration. You can modify the days later.
              </Typography>

              <Button
                variant='outlined'
                onClick={() => handleChange(handleAddTerm(values.terms.length))}
                startIcon={<AddIcon />}
                disabled={values.terms.length === 4}
              >
                Add Term
            </Button>
          </Grid>

            <Grid container>
              <div className="login-spinner">
                {errors.submit && <p color="danger">{errors.submit}</p>}
              </div>
              
              {isSubmitting
                ?
                  <OutlinedButton
                    color="#2196f3"
                    size="medium"
                    type="submit"
                    style={{width: 140}}
                  >
                    <ClipLoader
                      loading={isSubmitting}
                      size={25}
                      color={"#0085ff"}
                    />
                  </OutlinedButton>
                :
                  <OutlinedButton
                    color="#2196f3"
                    size="medium"
                    type="submit"
                    style={{width: 140}}
                    disabled={
                      !values.name ||
                      !!errors.name ||
                      !!errors.organiserName ||
                      !!errors.description ||
                      !!errors.contactName ||
                      !!errors.contactEmail ||
                      !!errors.location ||
                      !!errors.memberLimit ||
                      !!errors.address_1 ||
                      !!errors.address_2 ||
                      !!errors.city ||
                      !!errors.region ||
                      !!errors.postal_code ||
                      !!errors.country ||                  
                      !!errors.terms
                    }
                  >
                    CREATE CLUB
                  </OutlinedButton>
              }

            </Grid>
          </Grid>
          </form>
        )
      }
    </>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({
    visibility: "public",
    num_sessions: 8,
    memberLimit: 25,
    country: "New Zealand",
    mapAddress: "",
    terms: [{ startTime: "15:15", endTime: "17:00", startDate: moment().format('YYYY-MM-DD') }],
  }),
  validationSchema: () => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          name: yup.string().required("Club name is required."),
          organiserName: yup.string().required("Organiser is required."),
          description: yup.string().required("Description is required."),
          memberLimit: yup.number("Member limit must be a number."),
          contactName: yup.string().required("Contact name is required."),
          contactEmail: yup.string().trim().email("Please provide a valid email.").required("Email is required."),
          contactPhone: yup.string().trim().required("Phone number is required."),
          location: yup.string().required("Location is required."),
          visibility: yup.string().required(),
          address_1: yup.string().required("Address line 1 is required."),
          city: yup.string().required("City is required."),
          country: yup.string().required("Country is required."),
          terms: yup.array().of(
            yup.object().shape({
              startDate: yup.date().required("Date is required."),
              startTime: yup.string().test(
                'start-valid',
                "Start time is required.",
                (value) => {
                  return moment(value, 'HH:mm', true).isValid();
                }),
              endTime: yup.string().test(
                'start-valid',
                "End time must be a valid time.",
                (value) => {              
                  return !value || moment(value, 'HH:mm', true).isValid();
                }),
            })
          )
        })
      );
    });
  },
  handleSubmit: async ({...payload },
    { props, setSubmitting, setErrors }) => {
    try {
      payload.mapAddress = [
        payload.address_1, 
        payload.address_2, 
        payload.city, 
        payload.region, 
        payload.postal_code, 
        payload.country 
      ].filter(Boolean).join(", ");
      let latlong = await getLatLong(payload.mapAddress)
      if (latlong) {
        payload.mapLat = latlong.lat
        payload.mapLong = latlong.lng
      }

      await props.groupStore.createClub({
        ...payload,
        creatorId: props.userStore.currentUser.id
      })
      const groupId = props.groupStore.createClubSuccess.id;
      const club = props.groupStore.myClubs.find(club => club.id == groupId);

      if (!groupId) {
        setErrors({ submit: "error creating club" });
      } else {

        const venue = await props.groupStore.createVenue({
          groupId: groupId,
          address_1: payload.address_1,
          address_2: payload.address_2,
          city: payload.city,
          region: payload.region,
          postal_code: payload.postal_code,
          country: payload.country,
        });
        if (venue?.id) {
          await props.groupStore.editClub(groupId, {venueId: venue.id});
        } else {
          setErrors({ submit: "error creating club address" });
        }

        await Promise.all(        
          payload.terms.map(async (term) => {
            await props.termStore.createTerm({
              date: term.startDate,
              startTime: term.startTime,
              endTime: term.endTime,
              location: payload.location,
              memberLimit: payload.memberLimit,
              groupId: groupId
            });
            if (club && props.termStore.createTermSuccess?.id) {
              club.groupTerms?.push(props.termStore.createTermSuccess);
            }
          })
        );

        props.onFinished(groupId);
      }
    } catch (err) {
      setErrors({
        submit: err.message
      });
    } finally {
      setSubmitting(false);
    }
  },
  displayName: "CreateClub"
});

export default formikEnhancer(CreateClub);