import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment";

import { useGroupStore } from "../../state";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";

import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";

import { pickColour } from "../../utils/color-picker";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: 12,
  },
  avatar: {
    position: "absolute",
    width: 75,
    height: 75,
    borderRadius: 5,
  },
  right: {
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: 90,
    "& div": {
      width: "100%",
      marginTop: 6,
      marginBottom: 0,
    },
    "& svg": {
      position: "absolute",
      color: "#6F7386",
    },
    "& h3": {
      margin: 0,
    },
    "& p": {
      maxWidth: "100%",
      boxSizing: "border-box",
      paddingLeft: "2em",
    },
    "& h3, & p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const ClubCard = (club) => {
  const classes = useStyles();
  const [tutor, setTutor] = useState("Not yet assigned");
  const [members, setMembers] = useState("N/A");
  const [nextDate, setNextDate] = useState(undefined)
  const groupStore = useGroupStore();
  const clubLink = ((club.role == "admin" || club.role == "owner" || club.role == "tutor") ? `/dashboard/${club.id}` : `/group/${club.id}`);

  useEffect(() => {
    const getData = async () => {
      groupStore.setCurrentClub(club.id);
      const nextTerm = groupStore.getNextTerm();
      if (nextTerm) {
        if (nextTerm.termSessions?.length) {
          setNextDate(moment(nextTerm.termSessions[0].sessionDate).format("Do MMMM"));
        }
        let tutorArray = [];
        let memberCount = 0
        if (nextTerm.termMembers?.length) {
          memberCount = nextTerm.termMembers.length;
          nextTerm.termMembers.forEach((member) => {
            if (member.role == "tutor") {
              memberCount = memberCount - 1;
              if (member.user.firstName || member.user.lastName) {
                let name = (member.user.firstName + " " + member.user.lastName).trim();
                tutorArray.push(name);
              } else {
                tutorArray.push(member.user.username);
              }
            }
          });
        }
        if (tutorArray.length > 0) {
          setTutor(tutorArray.join(", "));
        }
        setMembers(memberCount.toString());
      }
    };

    getData();
  }, []);

  return (
    <motion.div
      whileHover={{
        scale: 1.025,
        transition: { duration: 0.1, ease: "easeIn" },
      }}
      whileTap={{ scale: 0.99 }}
      className="club-entry"
    >
      <Link to={clubLink} style={{ textDecoration: 'none' }}>
        <Paper className={classes.cardContainer}>
          <Avatar
            style={{ backgroundColor: pickColour(club.id) }}
            className={classes.avatar}
          >
            <Typography variant="h1">{club.name.substring(0, 1)}</Typography>
          </Avatar>
          <div className={classes.right}>
            <Typography variant="h3">{club.name}</Typography>

            <div>
              <PeopleAltRoundedIcon />
              <Typography variant="body1">{`Members: ${
                  members || "N/A"
                }`}</Typography>
            </div>

            <div>
              <SchoolRoundedIcon />
              <Typography variant="body1">{`Tutor: ${tutor}`}</Typography>
            </div>

            <div>
              <CalendarTodayRoundedIcon />
              <Typography variant="body1">{`When: ${
                nextDate || "Not scheduled"
              }`}</Typography>
            </div>
          </div>
        </Paper>
      </Link>
    </motion.div>
  );
};

export default ClubCard;
