import React from 'react'
import { observer } from 'mobx-react-lite'

import { withStyles, makeStyles } from '@material-ui/styles'

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography'
import { lighten } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import CheckIcon from '@material-ui/icons/Check'
import CrossIcon from '@material-ui/icons/Clear'

import Button from '@material-ui/core/Button'

import PulseLoader from 'react-spinners/PulseLoader'



const useStyles = makeStyles(theme => ({
  paperTable: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  cellBorder: {
    borderBottom: "2px solid #c8d4e4"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkIcon: {
    color: "#09ca49"
  },
  crossIcon: {
    color: "#ff4444"
  },
  tac: {
    textAlign: "center"
  },
  narrowCol: {
    width: "3em",
  },
  root: {
    '&$selected': {
      backgroundColor: "#effff3",
      '&:hover': {
        backgroundColor: "#e6f5ea"
      },
    }
  },
  selected: {},
}))

const RowCheckbox = withStyles({
  root: {
    '&$checked': {
      color: "#00a338",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const headCells = [
  { id: 'groupName', disablePadding: true, label: 'Group' },
  { id: 'couponCode', disablePadding: false, label: 'Code' },
  { id: 'couponType', disablePadding: false, label: 'Type' },
  { id: 'value', disablePadding: false, label: 'Value' },
  { id: 'maxUses', disablePadding: false, label: 'Limit', narrow: true },
  { id: 'timesUsed', disablePadding: false, label: 'Used', narrow: true },
  { id: 'expires', disablePadding: false, label: 'Expires'},
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold"
  }
}))(TableCell);


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.cellBorder}>
          <RowCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all coupons' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            className={headCell.narrow ? clsx(classes.narrowCol, classes.cellBorder) : classes.cellBorder}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
    searchroot: {
      padding: "2px 4px",
      height: 28,
      display: 'flex',
      alignItems: 'center',
      maxWidth: 350,
      border: "2px solid #c8d4e4",
      borderRadius: 20,
      alignSelf: 'center',
      flex: 1,
      boxShadow: "none"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontWeight: 500,
      height: 8,
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4
    },
  
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}




const OwnerCouponsTable = props => {
    const {
      setFilterString,
      rowsPerPage,
      rowsFiltered,
      page,
      selected,
      order,
      orderBy,
      handleSelectAllClick,
      handleRequestSort,
      isSelected,
      handleClick,
      handleChangePage,
      handleChangeRowsPerPage,
      stableSort,
      getComparator,
      loadingRows
    } = props


  const classes = useStyles()


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsFiltered.length - page * rowsPerPage);

  function checkOrCross (paid) {
    if (paid) {
      return <CheckIcon className={classes.checkIcon} />
    } else {
      return <CrossIcon className={classes.crossIcon} />
    }    
  }

  return (
    <>
<Paper className={classes.paperTable}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsFiltered.length}
            />
            <TableBody>
              {stableSort(rowsFiltered, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      classes={{ root: classes.root, selected: classes.selected }}
                    >
                      <TableCell padding="checkbox" className={classes.cellBorder}>
                        <RowCheckbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          classes={{ checked: classes.checked }}
                          />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" className={classes.cellBorder}>
                        {row.groupName}
                      </TableCell>
                      <TableCell className={classes.cellBorder}>{row.couponCode}</TableCell>
                      <TableCell className={classes.cellBorder}>{row.couponType}</TableCell>
                      <TableCell className={classes.cellBorder}>{row.value}</TableCell>
                      <TableCell className={classes.cellBorder}>{row.maxUses}</TableCell>
                      <TableCell className={classes.cellBorder}>{row.timesUsed}</TableCell>
                      <TableCell className={classes.cellBorder}>{row.expires}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 51 * emptyRows }}>
                  <TableCell colSpan={6} style={{textAlign: "center"}}>
                    {(loadingRows ? 
                      <PulseLoader
                      loading={true}
                      color="rgb(67, 196, 255)"
                      width={180}
                    />
                  : "")}
                  </TableCell>
                </TableRow>
              )}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  {selected.length > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                      {selected.length} selected
                    </Typography>
                  ) : (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                      
                    </Typography>
                  )}
                </TableCell>
              <TableCell colSpan={4}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rowsFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default observer(OwnerCouponsTable)
