import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import Nav from "../components/common/Nav";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import bespokeImg from "../img/home/bespoke 2x.png";
import happyGirlImg from "../img/home/happy girl 2x.png";
import speechBubbleImg from "../img/home/speech 2x.png";
import quoteMarkImg from "../img/home/quote icon 2x.png";
import kiriImg from "../img/home/kiriliana 2x.png";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container,
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
    '& img': {
      display: 'block',
      width: '100%'
    }
  },
  bgBlue: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#399aff",
    paddingTop: 100,
    paddingBottom: "1em",
    margin: "-1px 0px"
  },
  bgWhite: {
    width: "100%",
    color: "#505971",
    backgroundColor: "#ffffff",
    paddingBottom: "1em",
    textAlign: "left"
  },
  bgYellow: {
    width: "100%",
    color: "#505971",
    backgroundColor: "#ffe400",
    textAlign: "left",
    paddingBottom: "1em",
    margin: "-1px 0px"
  },
  bgViolet: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#aa56fe",
    textAlign: "left",
    paddingBottom: "1em",
    margin: "-1px 0px"
  },  
  bgIndigo: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#6533ff",
    textAlign: "left",
    paddingBottom: "8em",
    margin: "-1px 0px"
  },  
  overlayWrapper: {
    marginTop: "auto",
    width: "100%",
  },
  title: {
    fontSize: 34,
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    textAlign: "left"
  },
  narrowCol: {
    flex: "1 2 400px",
    [theme.breakpoints.down('xs')]: {
      flex: "1 1 auto"
    },
  },
  fixedCol: {
    flex: "0 0 400px",
    [theme.breakpoints.only('sm')]: {
      flex: "1 0 280px"
    },
    [theme.breakpoints.down('xs')]: {
      flex: "1 1 auto"
    },
  },
  wideCol: {
    flex: "1 1 500px",
    [theme.breakpoints.down('xs')]: {
      flex: "1 1 auto"
    },
  },
  EvenCol: {
    flex: "1 1 450px",
    [theme.breakpoints.down('xs')]: {
      flex: "1 1 auto"
    },
  },
  aspect607: {
    boxSizing: "border-box",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    "&::before": {
      content: '""',
      paddingTop: "60.7%",
      float: "left"
    },
    "&::after": {
      content: '""',
      display: "table",
      clear: "left"
    }
  },
  infoTitle: {
    fontSize: 26,
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    width: "60%",
    WebkitFontSmoothing: "antialiased",
    margin: "30px 0px 0px 30px",
    [theme.breakpoints.only('sm')]: {
      fontSize: 20,
    },
},
  infoBody: {
    fontSize: 19,
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    width: "58%",
    margin: "15px 0px 0px 30px",
    [theme.breakpoints.only('sm')]: {
      fontSize: 15,
    },
  },
  infoButton: {
    color: "#FFF",
    background: "#f78839",
    "&:hover": {
      background: "#f78839",
    },
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    fontSize: 18,
    padding: "8px 30px",
    margin: "15px 0px 0px 30px",
    [theme.breakpoints.only('sm')]: {
      fontSize: 14,
    },
  },
  contentBody: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.1rem",
    "& ul": {
      paddingLeft: "20px",
      "& li": {
        padding: "5px 0px 5px 20px"
      }
    }
  },
  tac: {
    textAlign: "center"
  }
}));

const GetInvolved = () => {
  const classes = useStyles();

  return (
    <>
      <Nav />
      <div className={classes.bgBlue}>
        <div className={classes.container} style={{ display: "flex" }}>
          <div className={classes.overlayWrapper}>
            <Typography className={clsx(classes.title, classes.tac)}>
              Starting a Game Dev Club
            </Typography>
            <Typography className={classes.contentBody}>
              <p>Starting a Game Dev Club is easy!</p>
            </Typography>
            <Typography className={classes.contentBody}>
              <p>
                Anybody can setup a club, typically our partners are schools, and facilitators 
                that run clubs in partnership with us.
              </p>
            </Typography>            
            <Typography className={classes.contentBody}>
              <p>
                After School Programmes can be run at any school or community of learning 
                space that has the following facilities; WIFI, student laptops/chrombooks (or 
                they bring their own), and a TV screen and/or projector. 
              </p>
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.bgWhite}>
        <div className={classes.container} style={{ display: "flex" }}>
          <div className={classes.overlayWrapper}>
            <Typography className={clsx(classes.title, classes.tac)}>
              So you want to be a tutor? 
            </Typography>
            <Typography className={classes.contentBody}>
              <p>
                Our tutors are either game developers, experienced Digital Technologies 
                teachers, or have experience facilitating programmes that deliver a high 
                quality digital education. 
              </p>
              <p>
                Game Dev Club can provide you with the training, ongoing support, and 
                resources you need to become a certified Game Dev Club tutor. Game Dev Club 
                certification means you can run one or more Game Dev Clubs.
              </p>
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.bgYellow}>
        <div className={classes.container} style={{ display: "flex" }}>
          <div className={classes.overlayWrapper}>
            <Typography className={clsx(classes.title, classes.tac)}>
              How it works
            </Typography>
            <Typography className={classes.contentBody}>
              <p>
                Game Dev Club offers a term by term programme. Learners have access to an 
                online game creation platform, a suite of tools, and Aotearoa-themed art 
                assets
              </p>
            </Typography>
            <Link href="mailto:dan@gamefroot.com" >Contact us to get started</Link>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default GetInvolved;
