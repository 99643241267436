import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  pdfList: {
    width: "auto",
    "& span": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  }
}));

const PdfList = (props) => {
  const {groupStore} = props;
  const classes = useStyles();

  const [pdfs, setPdfs] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const getPdf = async (id) => {
    if (!loadingPdf) {
      try {
        setLoadingPdf(true);
        let pdf = await groupStore.getDoc(id);
        if (pdf?.url) {      
          await axios
          .get(pdf.url)
          .then(async (response) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = response.data;
            downloadLink.download = pdf.filename + ".pdf";
            downloadLink.click();
          })
          .catch((error) => {
            console.log(error);
          });

        }
      } finally {
        setLoadingPdf(false);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      let tempPdfs = await groupStore.findDocs(groupStore.currentClub?.id, "pdf", false);
      setPdfs(tempPdfs);
    };

    getData();
  }, [groupStore]);

  return (
    (pdfs.length > 0) && 
      <>
        <Typography variant={"h4"} className={classes.text}>Group PDF Files</Typography>
        <ul className={classes.pdfList}>
          {pdfs.map((pdf, i) => (
            <li key={"pdf_" + i}><span onClick={async () => getPdf(pdf.id)}>{pdf.description}</span></li>
          ))}
        </ul>
      </>
  );
};

export default PdfList;
