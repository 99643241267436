import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  container: { ...theme.container, "& .MuiFormHelperText-root": {} },
}));

const RegistrationThanks = (props) => {
  const { userStore, status, clubName, clubDetails, values, currentUser } = props;
  
  const [emailStatus, setEmailStatus] = React.useState("Sending this information to " + values.email + "....");
  const [names, setNames] = React.useState("");

  const classes = useStyles();

  useEffect(() => {
    var userCount = status.newUsers.length;

    var namesTemp = 
      status.newUsers.map((user, i) => {
        if (i > 0) {
          let output = " " + user.firstName;
            if (i < userCount - 2) {
              output += ","
            } else if (i == userCount - 2) {
              if (userCount == 3) {
                output += " and"
              } else {
                output += ", and"
              }
            }
            return output;
          } else {
            return "";
          }
      }).join("");
    setNames(namesTemp);

    const doSend = async () => {
      let message = clubName.toUpperCase() + " REGISTRATION\r\n"
      + "======================================================\r\n"
      + "\r\n"
      + "Thank you for registering" + namesTemp + "\r\n"
      + "\r\n";

      if (status.errors && status.errors.length > 0) {
        message += "Errors were encountered during the registration process:\r\n"
        status.errors.map((error, i) => (
          " * " + error + "\r\n"
        ));
      }

      message += "CLUB DETAILS\r\n"
      + "Where: " + clubDetails.where + "\r\n"
      + "When: " + clubDetails.when + "\r\n"
      + "Contact: " + clubDetails.contact + "\r\n"
      + "\r\n"
      + "PAYMENT SUMMARY\r\n"
      + "Amount charged $" + values.price.toFixed(2) + "\r\n"
      + "\r\n"
      + "Our bank details are as follows:\r\n"
      + "03 - 0510 - 0017144 - 000\r\n"
      + "\r\n"
      + "Payment reference details for our account should use\r\n"
      + "club name + surname as reference.\r\n"
      + "\r\n"
      + "USERS CREATED\r\n";
      if (currentUser?.id) {
        message += "User accounts have been created for each student. They can use their\r\n"
        + "account to log in to the Game Dev Club site.\r\n"
        + "\r\n";
      } else {
        message += "A Game Dev Club user account has been created for you. You can use this\r\n"
        + "account to see Game Dev Club updates and register students for additional\r\n"
        + "terms (* Not yet implemented).\r\n"
        + "\r\n"
        + "Username: " + status.newUsers[0].username + "\r\n" 
        + "Password: " + status.newUsers[0].password + "\r\n"
        + "\r\n"
        + "User accounts have also been created for each student. They can use\r\n"
        + "their account to log in to the Game Dev Club site.\r\n"
        + "\r\n";
      }
      message += status.newUsers.map((user, i) => {
        return ((i > 0) ?
          "Username: " + user.username + "\r\n" 
          + "Password: " + user.password + "\r\n\r\n"
        : ""
        )
      }).join("");
      
      let result = await userStore.sendEmail(values.email, "[Game Dev Club] Registration Details", message);
      if (result?.response === "OK") {
        setEmailStatus("This information has been emailed to " + values.email);
      } else {
        setEmailStatus("Error sending email to " + values.email + "! (" + result?.response + ")");
      }
    };

    doSend();
}, []);



  return (
    <>
        <div className={classes.container}>
          <h1>Thank you for registering{names}</h1>

          {status.errors && status.errors.length > 0 && (
            <>
              <h3>Errors were encountered during the registration process:</h3>
              <ul>
                {status.errors.map((error, i) => (
                  <li key={"error_" + i}>{error}</li>
                ))}
              </ul>
            </>
          )}

          <p>{emailStatus}</p>

          <h3>Club Details</h3>
          <p>Where: {clubDetails.where}
          <br />When: {clubDetails.when}
          <br />Contact: {clubDetails.contact}</p>

          <h3>Payment Summary</h3>
          <p>Amount charged ${values.price.toFixed(2)}</p>
{(values.manualPayment ? (
  <>
<p><strong style={{color: "#000000"}}>Our bank details are as follows:
  <br />03 - 0510 - 0017144 - 000</strong></p>
  <p><strong style={{color: "#000000"}}>Payment reference details for our account should use 
  <br />club name + surname as reference.
  </strong></p>
  </>
  ) : (
<p>[Credit card confirmation goes here?]</p>
))}

          <h3>Users Created</h3>
          {(currentUser?.id) ? <>
            <p>User accounts have been created for each student. 
              They can use their account to log in to the Game Dev Club site.</p>
          </> : <>
            <p>A Game Dev Club user account has been created for you. You can use this
              account to see Game Dev Club updates and register students for additional terms
              (* Not yet implemented).</p>

              <p>Username: {status.newUsers[0].username} 
                  <br />Password: {status.newUsers[0].password}</p>

            <p>User accounts have also been created for each student. 
              They can use their account to log in to the Game Dev Club site.</p>
          </>
          }
          <ul>
            {status.newUsers.map((user, i) => (
              (i > 0) &&
                <li key={user.id}>Username: {user.username} 
                <br />Password: {user.password}</li>              
            ))}
          </ul>
        </div>
    </>
  );
};


export default RegistrationThanks;
