import React from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from '../../state'

import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'


const useStyles = makeStyles(theme => ({
  tab: {
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
//    width: "auto",
    minWidth: "auto",
    color: "#515873",
    padding: "0.5em 2em",
    fontWeight: "bold",
    cursor: "pointer",
    border: "none",
    outline: "none",
    marginRight: "20px",
    position: "relative",
    backgroundColor: "transparent",
    "& div": {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "4px",
      borderRadius: "2px",
      zIndex: 1,
      transition: "height 1s"
    },
    '& span': {
      zIndex: 2
    },
    '&.Mui-selected': {
      color: "#ffffff",
    },
    '&.Mui-selected div': {
      height: "48px",
      borderRadius: "8px",
    }
  },
  tabIndicator: {
    display: "none",
    backgroundColor: "#fdf801",
    height: 4
  },
  textColorInherit: {
    opacity: 1
  },
  indicatorCustom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "4px",
    borderRadius: "2px",
    backgroundColor: "#1ae15c",
    zIndex: 1,
    transition: "height 1s"
}
}));

const DashTabs = (props) => {
  const classes = useStyles();
  const userStore = useUserStore();
  const StyledTab = props => (
    <Tab
      classes={{
        textColorInherit: classes.textColorInherit,
        root: classes.tab
      }}
      {...props}
    />
  )
  return (
    <Tabs
      className={classes.tabContainer}
      classes={{ indicator: classes.tabIndicator }}
      style={{ overflow: "visible" }}
      value={props.value}
    >
      {props.tabs.map((tab, i) => (
        <StyledTab
          color={tab.color}
          label={<React.Fragment>
            <span>{tab.label}</span><div style={{backgroundColor: tab.color}}></div>
          </React.Fragment>}             
          onClick={() => props.onChange(tab.label)}
          value={tab.label}
          key={i}
        />

      ))}
    </Tabs>
  )
}

export default observer(DashTabs)