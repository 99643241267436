import React, { createContext, useContext, useState } from 'react';

import RootStore from './RootStore';

export const rootStore = new RootStore()

const {
  userStore,
  lessonStore,
  groupStore,
  rbacStore,
  commentStore,
  termStore,
} = rootStore

const UserContext = createContext(null);
const LessonContext = createContext(null);
const GroupContext = createContext(null)
const RbacContext = createContext(null)
const CommentContext = createContext(null)
const TermContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [store] = useState(userStore)
  return (
    <UserContext.Provider value={store}>
      {children}
    </UserContext.Provider>
  )
}

export const LessonProvider = ({ children }) => {
  const [store] = useState(lessonStore)
  return (
    <LessonContext.Provider value={store}>
      {children}
    </LessonContext.Provider>
  )
}

export const GroupProvider = ({ children }) => {
  const [store] = useState(groupStore)
  return (
    <GroupContext.Provider value={store}>
      {children}
    </GroupContext.Provider>
  )
}

export const RbacProvider = ({ children }) => {
  const [store] = useState(rbacStore)
  return (
    <RbacContext.Provider value={store}>
      {children}
    </RbacContext.Provider>
  )
}

export const CommentProvider = ({ children }) => (
  <CommentContext.Provider value={commentStore}>
    {children}
  </CommentContext.Provider>
)

export const TermProvider = ({ children }) => {
  const [store] = useState(termStore)
  return (
    <TermContext.Provider value={store}>
      {children}
    </TermContext.Provider>
  )
}

const providerError = (storeName) => {
  throw new Error(`You have forgotten to use ${storeName} provider`)
}

export const useUserStore = () => {
  const store = useContext(UserContext);
  if (!store) throw providerError("User Store")
  return store;
}


export const useLessonStore = () => {
  const store = useContext(LessonContext);
  if (!store) throw providerError("Lesson Store")
  return store
}


export const useGroupStore = () => {
  const store = useContext(GroupContext);
  if (!store) throw providerError("Group Store")
  return store;
}


export const useRbacStore = () => {
  const store = useContext(RbacContext);
  if (!store) throw providerError("RBAC Store")
  return store
}

export const useCommentStore = () => {
  const store = useContext(CommentContext)
  if (!store) throw providerError("Comment Store")
  return store
}

export const useTermStore = () =>  {
  const store = useContext(TermContext)
  if (!store) throw providerError("Term Store")
  return store
}