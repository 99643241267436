import { useHistory } from "react-router";
// logic imports
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
// material-ui component imports
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// SVG/images imports
import bullseyeImg from "../../img/registration/direct-hit.png";

import PdfList from "../club/PdfList";

// component scope styles
const useStyles = makeStyles(theme => ({
  buttonJoin: {
    backgroundColor: "#ffea00",
    color: "#996b59",
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
    width: "100%",
    "&:hover": {
      backgroundColor: "#ffea00 !important",
      color: "#996b59",
    },
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
  },

  text: {
    fontSize: "0.95rem",
  },
  textContainer: {
    position: "absolute",
    top: 30,
    left: 30,
    width: 180,
  },
  bottomMargin10: {
    marginBottom: 10,
  },
  bottomMargin20: {
    marginBottom: 20,
  },
  // duty of care section styles
  dofContainer: {
    backgroundColor: "#479FEA",
    overflow: "hidden",
    borderRadius: 10,
    width: 195,
    marginTop: 5,
  },
  dofTextContainer: {
    backgroundColor: "#FEF187",
    marginLeft: 6,
    padding: "20px 12px",
  },
  dofBackground: {
    position: "relative",
    backgroundColor: "#FEE400",
    borderRadius: 3,
    width: 260,
    height: 320,
  },
  // term goals section styles
  tgBackground: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    borderRadius: 3,
    width: 260,
    height: 230,
    marginBottom: 30,
  },
  tgImg: {
    width: 30, 
    height: 30, 
    marginRight: 10, 
    marginBottom: -8,
  },
  // pdf section styles
  pdflist: {
    marginLeft: 30,
  }
}));

/**side bar info section on parent dashboard page*/
const ParentDashboardSideBar = (props) => {
  const {groupStore,
    nextDate,
    seats,
  } = props;
  // hooks
  const history = useHistory();
  const classes = useStyles();

  const {
    currentClub,
  } = groupStore;

  const joinClick = (event) => {
    history.push("/registration/" + currentClub.id);
  };

  // render statement
  return (
    <div>
        <div className={classes.section}>
          <Typography variant={"h4"} className={classes.text}>Club Registration</Typography>
          <Button
            variant="contained"
            className={classes.buttonJoin}
            onClick={joinClick}
            disabled={!nextDate}
          >
            Make Booking
          </Button>
          {!!seats &&
            <Typography className={classes.text}>{`${seats} seats left available`}</Typography>
          }
        </div>
      
      <div className={classes.dofBackground}>
        <div className={classes.textContainer}>
          <div className={classes.dofContainer}>
            <div className={classes.dofTextContainer}>
              <Typography variant="subtitle1"  className={classes.bottomMargin10} >Duty of Care: </Typography>
              <Typography variant="body1" className={clsx(classes.text, classes.bottomMargin10)}>
                Game Dev Club takes student safety seriously. Our tutors are either 
                professionals that have been police checked or are practicing teachers 
                trained in Game Development. 
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.tgBackground}>
        <div className={classes.textContainer}>
          <Typography variant="h3" className={classes.bottomMargin20} >
            <img src={bullseyeImg} alt="bullseye" className={classes.tgImg} />
            Term Goals
          </Typography> 
          <Typography variant="body1" className={classes.text} >
            Each term members learn cool coding tips and tricks and have the opporunity to 
            add more of their creations to their digital portfolio.
          </Typography>  
        </div>
      </div>

      <div  className={classes.pdflist}>
        <PdfList groupStore={groupStore}></PdfList>
      </div>
    </div>
  );
};

export default ParentDashboardSideBar;
