import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { observer } from "mobx-react-lite";

import Nav from "../common/Nav";
import DashTabs from "../common/DashTabs";
import OwnerClubs from "./OwnerClubs";
import OwnerClub from "./OwnerClub";
import OwnerMembers from "./OwnerMembers";
import OwnerAdmin from "./OwnerAdmin";
import Header from '../common/Header'

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/styles";

import {
  useGroupStore,
  useTermStore,
  useRbacStore,
  useUserStore,
} from "../../state";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container },
  xheading: {
    color: "#ffffff",
  },
  xcontainer: {
    marginTop: 48,
    paddingTop: 26,
    maxWidth: 860,
    width: "100%",
    height: "100%",
    textAlign: "left",
  },
  tabContent: {
    padding: "2em 0px",
    width: "100%",
  },
  wideboxOne: {
    width: "100vw",
    position: "relative",
    left: "50%",
    right: "50%",
    marginTop: "-74px",
    marginLeft: "-50vw",
    marginRight: "-50vw",
    marginBottom: "20px",
    padding: "20px 0px",
    color: "#ffffff",
    backgroundColor: "#399aff",
    textAlign: "center",
  },
}));

const OwnerDashboard = () => {
  const groupId = useParams().id;

  const [tabs, setTabs] = React.useState([{ label: "Clubs", color: "#3096ff" }]);
  const [activeTab, setActiveTab] = useState("Clubs");
  const [title, setTitle] = useState("Dashboard")
  const [loading, setLoading] = React.useState(true);

  const classes = useStyles();
  const groupStore = useGroupStore();
  const termStore = useTermStore();
  const rbacStore = useRbacStore();
  const userStore = useUserStore();

  useEffect(() => {
    const getData = async () => {
      if (!groupStore.myClubs.length) {
        let groupIdsOwner = await groupStore.getGroupIdsUser(userStore.currentUser.id, ["admin"]);
        let groupIdsTutor = await groupStore.getGroupIdsUser(userStore.currentUser.id, ["tutor"]);
        let groupIdsMember = await termStore.getGroupIdsMember(userStore.currentUser.id);
        let groupIdsGuardian = await termStore.getGroupIdsGuardian(userStore.currentUser.id);
        await groupStore.getMyClubs(groupIdsOwner, groupIdsTutor, groupIdsMember, groupIdsGuardian, rbacStore.canPerformSysAdminActions);
      }

      let newTabs = [{ label: "Clubs", color: "#3096ff" }]
      if (groupStore.isOwner || rbacStore.canPerformSysAdminActions) {
        newTabs.push({ label: "Members", color: "#1ae15c" });
        // comented out awaiting content
        // newTabs.push({ label: "Training", color: "#cb38fd" });
        // newTabs.push({ label: "Payments", color: "#ff6d15" });
        newTabs.push({ label: "Admin", color: "#ffc000" });
      }
      setTabs(newTabs);
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    switch(rbacStore.role){
      case "admin":
        setTitle("Partner Dashboard")
        break;
      case "tutor":
        setTitle("Tutor Dashboard")
        break;
      default:
        break;
    }
  }, [rbacStore])

  return (
    <>
      <Nav hideLogo />
      {(groupId > 0) ?
        <OwnerClub groupId={groupId} />
      :
        <>
          <div style={{ paddingTop: 70 }}>
            <Header title={title}/>
          </div>
          <div className={classes.container}>
            <DashTabs
              onChange={(val) => setActiveTab(val)}
              value={activeTab}
              tabs={tabs}
            />
            <Container className={classes.tabContent}>
              {activeTab === "Clubs" && 
                <OwnerClubs
                  groupId={groupId} 
                  loading={loading}
                  role={rbacStore.role}
                />
              }
              {activeTab === "Members" && <OwnerMembers />}
              {/* comented out awaiting content */}
              {/* {activeTab === "Training" && <div>[Training goes here]</div>} */}
              {/* {activeTab === "Payments" && <div>[Payments goes here]</div>} */}
              {activeTab === "Admin" && <OwnerAdmin />}
            </Container>
          </div>
        </>
      }
    </>
  );
};

export default observer(OwnerDashboard);
