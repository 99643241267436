// logic imports
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTermStore, useUserStore, useGroupStore } from "../../state";
import Moment from "moment";

// material-ui component imports
import Typography from '@material-ui/core/Typography'
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";

// custom component imports
import Dropdown from "../common/Dropdown";
import OwnerClubMemTable from "./OwnerClubMemTable.js";
import AddMembers from "./OwnerClubAddMems.js";
import AssignTutor from "./OwnerClubAssignTutor.js";
import CreateTerm from "./CreateTerm.js";

// component scope styles
const useStyles = makeStyles(theme => ({
  schedule: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    "& li": {
      position: "relative",
      display: "inline-block",
      width: "60px",
      margin: "0px 1em 0px 0px",
      padding: "0 0 5px 0",
      border: "2px solid #d3ddee",
      borderRadius: "0.5em",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "'Fredoka One', cursive",
      WebkitFontSmoothing: "antialiased",
      color: "#505971",
      "& div": {
          width: "64px",
          borderRadius: "0.5em 0.5em 0px 0px",
          boxSizing: "border-box",
          margin: "-2px -2px 5px -2px",
          padding: "0.2em",
          color: "#ffffff",
          backgroundColor: "#3096ff",
          textTransform: "uppercase",
      },
      "&.pastSess": {
        color: "#aab7cd",
        "& div": {
          backgroundColor: "#aab7cd",
        }    
      }
    },
  },
  checkIcon: {
    color: "#09ca49",
    position: "absolute",
    bottom: "5px",
    right: "-10px"
  },
  buttonNew: {
    borderRadius: "18px",
    float: "right",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3096FF",
    },
  },
  buttonLink: {
    color: "#3096ff", 
    fontWeight: 500,     
    fontSize: 16,
    fontFamily: "'Fredoka One', cursive",
    float: "right",
  },
}))

function createData(id, userId, name, roleRaw, purchaser, amount, paid) {
  let role = roleRaw;
  switch (role) {
    case "member": role = "Club Member"; break;
    case "tutor": role = "Tutor"; break;
  }
  return { id, userId, name, role, purchaser, amount, paid };
}


const OwnerClubDash = (props) => {
  const {groupId, role} = props
  const termStore = useTermStore();
  const userStore = useUserStore();
  const groupStore = useGroupStore();
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([])
  const [loadingRows, setLoadingRows] = React.useState(true);
  const [terms, setTerms] = React.useState([]);
  const [termId, setTermId] = React.useState(0);
  const [tutors, setTutors] = React.useState([]);
  const [newMembers, setNewMembers] = React.useState(false);
  const [doneAddText, setDoneAddText] = React.useState("Cancel");
  const [refresh, setRefresh] = React.useState(false);
  const [newTerm, setNewTerm] = React.useState(false);


  const location = groupStore.currentClub?.location;
  const memberLimit = groupStore.currentClub?.memberLimit ? groupStore.currentClub.memberLimit : 25;

  function descendingComparator(a, b, orderBy) {
    if (typeof(a[orderBy]) === 'string') {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [];
      rows.map((n) => {
        if (newSelecteds.indexOf(n.userId) < 0) {
          newSelecteds.push(n.userId);
        }        
      })
      setSelected(newSelecteds);
      return
    } else {
      setSelected([]);
    }
  }

  const handleClick = (event, userId) => {
    const selectedIndex = selected.indexOf(userId)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (userId) => selected.indexOf(userId) !== -1;

  useEffect(() => { 
    const getMemberData = async () => {
      setSelected([]);
      setRows([]);
      setLoadingRows(true);
      await termStore.getTermById(termId);

      const tempRows = [];
      const tempTutors = [];
      const tempRegUserCount = [];
      termStore.currentTerm?.termMembers?.map((member, i) => {
        if (member.regId) {
          let regId = "reg_" + member.regId;
          if (tempRegUserCount[regId] > 0) {
            tempRegUserCount[regId] = tempRegUserCount[regId] + 1;
          } else {
            tempRegUserCount[regId] = 1;
          }
        }
      });

      termStore.currentTerm?.termMembers?.map((member, i) => {
        let purchaser = "[no purchaser]";
        if (member.role == "tutor") {
          tempTutors.push(member.userId);
          purchaser = "N/A";
        }
        if (member.registration?.user2) {
          purchaser = (member.registration.user2.firstName
            + " " + member.registration.user2.lastName).trim();
        } else if (member.registration) {
          purchaser = "[" + (member.registration.firstName
            + " " + member.registration.lastName).trim() + "]";
        }
        let name = "[no name]";
        if (member.user) {
          name = (member.user.firstName
            + " " + member.user.lastName).trim();
          if (name === "" && member.user.username) {
            name = member.user.username;
          }
        }
        let price = "N/A";
        if (member.registration?.price > 0) {
          price = "$" + (member.registration?.price / tempRegUserCount["reg_" + member.regId]);
        }

        tempRows.push(createData(
          member.id, 
          member.userId, 
          name, 
          member.role, 
          purchaser, 
          price,
          (member.paid == 1 ? true : false )
        ));
      });
      setRows(tempRows);
      setTutors(tempTutors);
      setLoadingRows(false);
    }

    if (termId) {
      getMemberData();
    } else {
      setLoadingRows(false);
      termStore.resetStatus("term");
    }
    setRefresh(false);
  }, [termId, refresh])

  useEffect(() => {
    const getData = async () => {
      if (!(termStore.terms[0]?.groupId == groupId)) {
        await termStore.getTerms(groupId);
      }
      if (termStore.terms.length) {
        let currentTerms = termStore.terms.slice().reverse();
        setTerms(currentTerms);
        setTermId(currentTerms[0]?.id);
      } else {
        setTerms([]);
      }
    };

    getData();
  }, []);

  const handleChangeTerm = (event) => {
    if (event.target.value === "add_term") {
      toggleNewTerm()
    } else {
      setTermId(event.target.value);
    }
  };

  const getSessionBox = (session) => {
    let sessMoment = Moment(session.sessionDate);
    let today = Moment().startOf('day');
    let isPast = sessMoment < today;
    let css = "";
    return (
      <li key={"session_" + session.id} className={isPast ? "pastSess" : ""}>
        <div>{sessMoment.format("MMM")}</div>
      {sessMoment.format("D")}
      {isPast &&
        <CheckIcon className={classes.checkIcon} />
      }
      </li>
    )
  }

  const toggleNewMembers = () => {
    setNewMembers(!newMembers);
    if (doneAddText === "Close") {
      setRefresh(true);
    }
    setDoneAddText("Cancel");    
  }

  const toggleNewTerm = () => (
    setNewTerm(!newTerm)
  );

  const refreshTerms = async () => {
    // close new term form
    toggleNewTerm();
    // update terms state and refresh list
    await termStore.getTerms(groupId);
    let currentTerms = termStore.terms.filter(term => term.groupId == groupId);
    currentTerms.reverse();
    setTerms(currentTerms);
    setTermId(currentTerms[0]?.id);
  };

  return (
    <>
      {newTerm?
        <>
          <Button
            className={classes.buttonNew}
            variant='contained'
            startIcon={<CancelIcon />}
            onClick={toggleNewTerm}
          >
            Cancel
          </Button>
          <CreateTerm
            termStore={termStore}
            groupId={groupId}
            location={location}
            memberLimit={memberLimit}
            onFinished={refreshTerms}
            ></CreateTerm>
        </>
      : 
        <>
          <Typography variant="h3">Current Term</Typography>
          <Button
            className={classes.buttonLink}
            target="_blank"
            href={`/group/${groupId}`}
            >
            View club Page
          </Button>
          <div>
            <Dropdown value={termId} handleChange={handleChangeTerm}>
              {terms.map((term, i) => (
                <MenuItem value={term.id} key={"term_" + term.id}>
                  {(term.termSessions 
                    ? Moment(term.termSessions[0].sessionDate).format("ddd D MMM") 
                    + " - " + Moment(term.termSessions[term.termSessions.length - 1].sessionDate).format("ddd D MMM YYYY") 
                    : "Unknown")
                  }
                </MenuItem>
              ))}
              {((role == "admin") || (role == "owner")) &&
                <MenuItem value={"add_term"}>
                  + add a new term
                </MenuItem>
              }
            </Dropdown>
          </div>

          {newMembers?
            <>
              <Button
                className={classes.buttonNew}
                variant="contained"
                startIcon={<CancelIcon />}
                onClick={toggleNewMembers}
              >
                {doneAddText}
              </Button>
              <AddMembers
                termId={termId}
                termStore={termStore}
                userStore={userStore}
                setDoneAddText={setDoneAddText}
                ></AddMembers>
            </>
          : 
            <>
              <Typography variant="h4">Schedule</Typography>
              <ul className={classes.schedule}>
                {termStore.currentTerm?.termSessions?.map((session, i) => (
                  getSessionBox(session)
                ))}
              </ul>

              <OwnerClubMemTable 
                termStore={termStore}
                termId={termId}
                selected={selected}
                order={order}
                orderBy={orderBy}
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                isSelected={isSelected}
                handleClick={handleClick}
                stableSort={stableSort}
                getComparator={getComparator}
                rows={rows}
                loadingRows={loadingRows}
                setRefresh={setRefresh}
                isAdmin={(role == "admin")}
              />

              {(role == "admin") &&
                <>
                  <Button
                    className={classes.buttonNew}
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={toggleNewMembers} //() => history.push("/new-club")}
                    disabled={loadingRows || !(termId > 0)}
                  >
                    Add New Members
                  </Button>

                  <AssignTutor
                    groupId={groupId}
                    termId={termId}
                    tutors={tutors}
                    termStore={termStore}
                    setRefresh={setRefresh}
                  />
                </>
              }
            </>
          }
        </>
      }
    </>
  );
};

export default OwnerClubDash