import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useUserStore, useRbacStore, useGroupStore } from "../state";
import { makeStyles } from "@material-ui/core";
import Clubs from "../pages/clubs";

const useStyles = makeStyles((theme) => ({}));

const Groups = () => {
  const userStore = useUserStore();
  const rbacStore = useRbacStore();
  const groupStore = useGroupStore();
  const classes = useStyles();

  useEffect(() => {
    const doLogin = async () => {
      try {
        await userStore.login();

        rbacStore.getAccessControl();

        groupStore.getClubsPublic();

        window.parent.postMessage("gdc-ready", "*");
      } catch (err) {
        console.log(err);
        // TODO: Display a login failure page.
      }
    };

    doLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Clubs />
    </div>
  );
};

export default observer(Groups);
