import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button"
import { withFormik, Form } from 'formik';
import * as yup from "yup";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import ClipLoader from "react-spinners/ClipLoader";
import FormHelperText from "@material-ui/core/FormHelperText";

import TextInput from "../common/TextInput";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: '"Fredoka One", cursive',
    marginBottom: 0,
    fontSize: "1.2rem",
    WebkitFontSmoothing: "antialiased",
    color: "#465477",
  },
  underline: {
    "&:before": {
      borderBottom: "2px solid rgba(0, 0, 0, 0.42)",
    },
  },
  loginButton: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    fontSize: "0.9rem",
    paddingTop: 6,
    paddingLeft: 14,
    paddingRight: 14,
    backgroundColor: "#389aff",
    "&:hover": {
      backgroundColor: "#389aff",
    },
  },
  textButton: {
    fontSize: "0.95rem",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    backfaceVisibility: "hidden",
    MozBackfaceVisibility: "hidden",
    WebkitBackfaceVisibility: "hidden",
  },
  section: {
    marginBottom: 25,
  },
  endAdornment: { position: "absolute", right: 5 },
  iconButton: {
    padding: 2,
  },
  spinnerWrapper: {
    marginRight: 8,
    display: "flex",
  },
  errorText: {
    fontSize: "0.9rem",
  },
}));

const NewAccountForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    userStore,
    doneCreate
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const nameChanged = async () => {
    if (!touched?.username) {
      let username = (values.firstName + values.lastName).toLowerCase();
      username = await userStore.validateUsername(username, true);
      if (username) {
        setFieldValue("username", username);
      }
    }
  } 

  const handleClickShowPassword = () => {
    setFieldValue("showPassword", !values.showPassword);
  };

  return (
    <Form>
      <div className={classes.section}>
        <Typography className={classes.title}>First Name</Typography>
        <TextInput
          label="Your First Name"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName && errors.firstName}
          onBlur={e => {
            handleBlur(e);
            nameChanged();
          }}
          type=""
          name="firstName"
          placeholder="Your First Name"
          autoComplete="given-name"
          fullWidth
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.title}>Last Name</Typography>
        <TextInput
          label="Your Last Name"
          value={values.lastName}
          onChange={handleChange}
          error={touched.lastName && errors.lastName}
          onBlur={e => {
            handleBlur(e);
            nameChanged();
          }}
          type=""
          name="lastName"
          placeholder="Your Last Name"
          autoComplete="family-name"
          fullWidth
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.title}>Username</Typography>
        <TextInput
          label="Your Username"
          value={values.username}
          onChange={handleChange}
          error={touched.username && errors.username}
          onBlur={handleBlur}
          type=""
          name="username"
          placeholder="Your Username"
          fullWidth
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.title}>Email</Typography>
        <TextInput
          label="Your Email"
          value={values.email}
          onChange={handleChange}
          error={touched.email && errors.email}
          onBlur={handleBlur}
          type="email"
          name="email"
          placeholder="Your Email"
          autoComplete="email"
          fullWidth
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.title}>Password</Typography>
        <TextInput
          label="Your Game Dev Club Password"
          value={values.password}
          onChange={handleChange}
          error={touched.password && errors.password}
          onBlur={handleBlur}
          type={values.showPassword ? "" : "password"}
          name="password"
          placeholder="Your Game Dev Club Password"
          endAdornment={(
            <div className={classes.endAdornment}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                className={classes.iconButton}
              >
                {values.showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </div>
          )}
          autoComplete="new-password"
          fullWidth
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.title}>Confirm Password</Typography>
        <TextInput
          label="Confirm Your Password"
          value={values.passconf}
          onChange={handleChange}
          error={touched.passconf && errors.passconf}
          onBlur={handleBlur}
          type={values.showPassword ? "" : "password"}
          name="passconf"
          placeholder="Confirm Your Password"
          endAdornment={(
            <div className={classes.endAdornment}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                className={classes.iconButton}
              >
                {values.showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </div>
          )}
          autoComplete="new-password"
          fullWidth
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }} />
        <span
          className={classes.spinnerWrapper}
          style={{ marginRight: 8, display: "flex" }}
        >
          <ClipLoader
            loading={isSubmitting}
            size={25}
            color="#389aff"
          />
        </span>
        <Button
          size="small"
          href=""
          type="submit"
          className={classes.loginButton}
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Create Account
        </Button>
      </div>
      {errors.submit && (
                    <FormHelperText error={!!errors.submit}>
                      {errors.submit}
                    </FormHelperText>
                  )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          left: -8,
          width: "calc(100% + 16px)",
          marginTop: 25,
        }}
      >
      </div>
    </Form>
  );
};

const formikEnhancer = withFormik({
  validateOnChange: false,
  mapPropsToValues: props => ({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    passconf: "",
    email: "",
  }),
  validationSchema: (props) => {
    return yup.lazy(values => {
      return yup.object().shape({
        firstName: yup.string().required("Please enter your first name"),
        lastName: yup.string().required("Please enter your last name"),
        username: yup.string()
          .required("Please enter a username (must be unique)")
          .matches(/^(?=.{1,60}$)[a-z0-9_-]+$/gim, "Username can only contain 1-60 alphanumeric characters including dashes and/or underscores")
          .test(
            'uniqueUser',
            'This Username is not available; please choose a different one.',
            async (value) => (await props.userStore.validateUsername(value))),
        email: yup
          .string()
          .email("Email is invalid")
          .required("Please enter your email")
          .test(
            'uniqueEmail',
            'An account already exists for this email! Ask you tutor to reset your password or contact Gamefroot support.',
            async (value) => (await props.userStore.validateEmail(value))),
        password: yup
          .string("Password invalid")
          .min(8, "Password must be at least 8 characters long.")
          .max(200, "Password must be less than 200 characters long.")
          .required("Please enter a password"),
        passconf: yup.string().oneOf([yup.ref("password")], "The passwords do not match").required("Please confirm the password"),
      });
    });
  },
  handleSubmit: async (
    payload,
    { props, setSubmitting, setStatus, setErrors }
  ) => {
    setStatus(null);
    setSubmitting(true);
    try {
      let errorMessage = "";
      let newUser = await props.userStore.createUser(
          payload.firstName, payload.lastName, "", payload.email, payload.password, "", payload.username);
      if (newUser && newUser.id && newUser.id > 0) {
        setStatus({ ok: true });
        props.doneCreate();
      } else {
        if (props.userStore.errorMessage) {
          errorMessage = "Error creating account: " + props.userStore.errorMessage;
        } else {
          errorMessage = "Error creating account. Please contact Gamefroot support.";
        }
      }
      setErrors({ submit: errorMessage });
    } catch (err) {
      console.log(err);
      setErrors({
        submit:
          "Submitting the create account form failed. Please contact Gamefroot support.",
      });
    } finally {
      setSubmitting(false);
    }
  }
});

export default formikEnhancer(NewAccountForm);
