import React, { useEffect } from "react";

import { withFormik } from "formik";
import * as yup from "yup";

import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PulseLoader from "react-spinners/PulseLoader";

import Header from "../common/Header";
import Attendees from "../registration/Attendees";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container, "& .MuiFormHelperText-root": {} },
  background: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#399aff",
    padding: "90px 0px 0px 0px",
  },
  submit: {
    marginTop: "1em",
    flexDirection: "row",
    alignItems: "center",
    "& button": {
      width: "12em",
    },
    "& p": {
      display: "inline-flex",
      margin: "0px 0px 0px 1em !important",
    },
  },
}));

const AddMembers = (props) => {
  const { termId, termStore, userStore, setDoneAddText } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    status,
  } = props;

  const [openSubmitting, setOpenSubmitting] = React.useState(false);

  function SubmittingDialog(props) {
    const { open } = props;

    return (
      <Dialog open={open}>
        <DialogContent>
          <div style={{ padding: "2em" }}>
            <PulseLoader loading={true} color="rgb(67, 196, 255)" width={180} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  SubmittingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
  };

  const [attendeeCount, setAttendeeCount] = React.useState(1);

  const [completed, setCompleted] = React.useState(false);

  useEffect(() => {
    setOpenSubmitting(isSubmitting);
    if (!isSubmitting && status && status.ok) {
      setDoneAddText("Close");
      setCompleted(true);
    }
  }, [isSubmitting]);

  const handleCloseSubmitting = () => {
    setOpenSubmitting(false);
  };

  return (
    <>
      {completed ? (
        <div className={classes.container}>
          <p>Members have been added successfully.</p>
          <h3>Users created:</h3>
          <ul>
            {status.newUsers.map((user) => (
              <li key={user.id}>
                {user.username} / {user.password}
              </li>
            ))}
          </ul>
          <p>
            NB take note of these usernames and passwords! There is no email
            address associated with the accounts yet, so no way to reset the
            password if you forget it.
          </p>

          {status.errors && status.errors.length > 0 && (
            <>
              <h3>Errors:</h3>
              <ul>
                {status.errors.map((error, i) => (
                  <li key={"error_" + i}>{error}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      ) : (
        <>
          <div className={classes.container}>
            <Attendees
              values={values}
              touched={touched}
              errors={errors}
              onChange={handleChange}
              count={attendeeCount}
              countUpdate={setAttendeeCount}
              userStore={userStore}
              spaces={999} // allow admins to exceed memberLimit
            />

            <FormGroup className={classes.submit}>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Add Members
              </Button>
              {errors.submit && (
                <FormHelperText error={!!errors.submit}>
                  {errors.submit}
                </FormHelperText>
              )}
            </FormGroup>
            <SubmittingDialog
              open={openSubmitting}
              onClose={handleCloseSubmitting}
            />
          </div>
        </>
      )}
    </>
  );
};

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    attendees: [{ firstName: "", lastName: "" }],
    firstName: "admin",
    lastName: "",
    regUser: props.userStore.currentUser.id,
    paymentType: "manual",
    phone: "",
    email: "",
    coupon: "",
    price: 0.0,
    discount: 0.0,
    donation: 0.0,
    manualPayment: true,
    termsAndConditions: true,
  }),
  validationSchema: yup.object().shape({
    attendees: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup
            .string()
            .required("Please enter the attendee's first name"),
          lastName: yup
            .string()
            .required("Please enter the attendee's last name"),
        })
      )
      .ensure()
      .min(1)
      .required("Please enter an attendee"),
  }),
  handleSubmit: async (
    payload,
    { props, setSubmitting, setStatus, setErrors }
  ) => {
    setStatus(null);
    setSubmitting(true);
    try {
      let newUsers = [];
      let userErrors = [];
      let termId = props.termId;
      let regId = await props.termStore.createRegistration({...payload, termId});
      if (!regId) {
        userErrors.push("error creating registration record!");
      } else {
        await Promise.all(        
          payload.attendees.map(async (attendee) => {
  //          let termId = props.groupStore.currentClubNextTermId;
            let paymentType = "manual";
            let newUser = await props.userStore.createUser(
              attendee.firstName,
              attendee.lastName
            );
            if (newUser && newUser.id && newUser.id > 0) {
              newUsers.push(newUser);
              let userId = newUser.id;

              let result = await props.termStore.addTermMember(
                userId,
                termId,
                regId,
                paymentType
              );
              if (!result) {
                userErrors.push("error adding user to term!");
              }
            } else {
              userErrors.push(
                "error creating user: " +
                  attendee.firstName +
                  " " +
                  attendee.lastName
              );
            }
          })
        );
      }
      if (newUsers.length > 0) {
        setStatus({ ok: true, newUsers: newUsers, errors: userErrors });
      } else {
        let errorMessage =
          "Submitting the add members form failed. Please contact Gamefroot support.";
        if (userErrors.length > 0) {
          errorMessage += " Errors:";
          userErrors.forEach((error) => {
            errorMessage += " " + error + ";";
          });
        }
        setErrors({ submit: errorMessage });
      }
    } catch (err) {
      console.log(err);
      setErrors({
        submit:
          "Submitting the add members form failed. Please contact Gamefroot support.",
      });
    } finally {
      setSubmitting(false);
    }
  },
});

export default formikEnhancer(AddMembers);
