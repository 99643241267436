import firebase from "firebase/app";
import "firebase/auth";

import React, { createContext } from "react";

export const FirebaseContext = createContext({});

const configs = {
  development: {
    apiKey: "AIzaSyAjTLqUEjnFgZMX3Qc29bEhJUBskd6IHf0",
    authDomain: "gamefroot-auth-dev.firebaseapp.com",
    projectId: "gamefroot-auth-dev",
    storageBucket: "gamefroot-auth-dev.appspot.com",
    messagingSenderId: "880971393403",
    appId: "1:880971393403:web:66b0950e2682a2388c75de",
  },
  staging: {
    apiKey: "AIzaSyBH9gtRpjxzNjtaa1wIlaK9HcGQY1RrgBo",
    authDomain: "gamefroot-auth-staging.firebaseapp.com",
    projectId: "gamefroot-auth-staging",
    storageBucket: "gamefroot-auth-staging.appspot.com",
    messagingSenderId: "1073450087967",
    appId: "1:1073450087967:web:d262880cc5e6e863d6072a"
  },
  production: {
    apiKey: "AIzaSyDKoWWmTTYB6ieEPhUsoxsJbNV5iOAXH3I",
    authDomain: "gamefroot-auth-prod.firebaseapp.com",
    projectId: "gamefroot-auth-prod",
    storageBucket: "gamefroot-auth-prod.appspot.com",
    messagingSenderId: "1050189488778",
    appId: "1:1050189488778:web:47fdd061bd531e65a44373"
  }
}

const initFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(configs[process.env.REACT_APP_GAMEFROOT_ENV]);
    firebase.auth();
  }
};

export const FirebaseProvider = ({ children }) => {
  initFirebase();
  return (
    <div>
      <FirebaseContext.Provider value={{ firebase: firebase.app() }}>
        {children}
      </FirebaseContext.Provider>
    </div>
  );
}
