import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";

import PulseLoader from "react-spinners/PulseLoader";

import {
  useRbacStore,
  useGroupStore,
  useLessonStore,
  useCommentStore,
  useUserStore,
} from "../state";

import Nav from "../components/common/Nav";
import ClubDashboard from "../components/club/ClubDashboard";
import ParentDashboard from "../components/parent_dashboard/ParentDashboard";
import TitleBar from "../components/club/dashboard/TitleBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 48px)",
    backgroundColor: "white",
  },
  container: {
    paddingTop: 26,
    maxWidth: 860,
    width: "100%",
    height: "100%",
  },
}));

const Club = () => {
  const groupId = useParams().id;
  const groupStore = useGroupStore();
  const rbacStore = useRbacStore();
  const lessonStore = useLessonStore();
  const commentStore = useCommentStore();
  const userStore = useUserStore();
  const classes = useStyles();

  const [role, setRole] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [clubname, setClubname] = React.useState("Not found");

  useEffect(() => {
    const getData = async () => {
      await userStore.login();

      if (groupStore.publicClubs.length) {
        groupStore.setCurrentClub(groupId)
      } else {
        await groupStore.getGroupById(groupId);
      }

      if (groupStore.currentClub) {
        let sRole = await groupStore.getCurrentRole();
        if (!sRole) sRole = "none";
        setRole(sRole);
        setClubname(groupStore.currentClub.name)
      }
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    const roleReady = async () => {
      rbacStore.getAccessControl();
      rbacStore.setClubRole(role);

      if (groupStore.currentClub) {
        if (!rbacStore.userUnaffiliatedWithClub) {
          await lessonStore.getLessons();
          lessonStore.loadLessons(groupStore.currentClub.lessonIds);

          commentStore.initialFetch(groupId);

          await groupStore.getGroupMembers(rbacStore.canUserViewAdminTab);

          rbacStore.canUserViewClubGames && (await groupStore.getClubGames());
        }
      }

      window.parent.postMessage("gdc-ready", "*");
    };

    if (role) roleReady();
  }, [role]);

  return (
    <>
      <Nav />
      {!loading && <TitleBar name={clubname} />}
      <div className={classes.root}>
        <Container className={classes.container}>
          {!loading && groupStore.currentClub && (
            <>
            {(role === "guardian" ?
              <ParentDashboard
                groupStore={groupStore}
              />
            : 
              <ClubDashboard 
                groupStore={groupStore}
              />
            )}
            </>
          )}
          {loading && (
            <div style={{ textAlign: "center", marginTop: 84 }}>
              <PulseLoader width={180} color="rgb(67, 196, 255)" />
            </div>
          )}
          {!loading && !groupStore.currentClub && (
            <div style={{ textAlign: "center", marginTop: 84 }}>
              Unable to load club
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Club;
