import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button"
import Fade from "@material-ui/core/Fade";

import LoginForm from "./LoginForm";
import NewAccountForm from "./NewAccountForm";
import { useUserStore } from "../../state"
import LoginFiller from "./LoginFiller";
// import FederatedLogin from "./FederatedLogin";
// import ForgotPassword from "./ForgotPassword";
// import Analytics from "../../api/analytics";
// import AnalyticEvents from "../../types/AnalyticsEvents";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 40,
  },
}));

const LoginPaper = () => {
  const classes = useStyles();
  const userStore = useUserStore();
  const [showCreate, setShowCreate] = useState(false);
  const [created, setCreated] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(null);

  // useEffect(() => {
  //   if (showForgotPassword) {
  //     Analytics.track(AnalyticEvents.ACCOUNT_RECOVER);
  //   }
  // }, [showForgotPassword]);

  // const handleShowForgotPassword = () => {
  //   setShowForgotPassword(true);
  // };

  // const handleHideForgotPassword = () => {
  //   setShowForgotPassword(false);
  // };

  const handleShowCreate = () => {
    setShowCreate(true);
   };

   const doneCreate = () => {
    setShowCreate(false);
    setCreated(true);
   };

  return (
    <Paper
      style={{
        borderRadius: 5,
        overflow: "hidden",
        boxShadow:
          "0 10px 20px 0 rgba(35, 50, 127, 0.3), 0 2px 8px 0 rgba(35, 50, 127, 0.25)",
      }}
    >
      <Grid style={{ width: "calc(100% + 2px)" }} container>
        <Grid className={classes.content} item xs={7}>
          {/* {showForgotPassword ? (
            <ForgotPassword handleBack={handleHideForgotPassword} />
          ) : ( */}
            <Fade in={true} timeout={(showForgotPassword !== null) ? 300 : 0}>
              {showCreate ? (
                <NewAccountForm userStore={userStore} doneCreate={doneCreate}></NewAccountForm>
                ) : ( 
                  <>
                  {created && 
                  <p>Your account has been created; you may now log in.</p>
                  }
              <LoginForm
                // handleForgotPassword={handleShowForgotPassword}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{ color: "#389aff", marginRight: 20 }}
                  className={classes.textButton}
                  onClick={handleShowCreate}
                >
                  Create Account
                </Button>
                <div style={{ flexGrow: 1 }} />
{/*                <Button
                  onClick={handleShowForgotPassword}
                  style={{ color: "#389aff" }}
                  className={classes.textButton}
                >
                  Forgot Password?
                </Button>
*/}
<p>Forgot your password? Please<br />contact Gamefroot support.</p>
              </div>
              </>
              )}
            </Fade>
          {/* )} */}
        </Grid>
        <Grid
          className={classes.content}
          style={{ backgroundColor: "#4adaff" }}
          item
          xs={5}
        >
          {/* commented out until functionality is restored */}
          {/* <FederatedLogin /> */}
          {/* filler until above functionality is restored */}
          <LoginFiller />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default observer(LoginPaper);
