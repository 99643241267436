import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from 'react-router-dom'
import { useRbacStore } from '../../state'

const useStyles = makeStyles(theme => ({
  root: {
    height: 100,
    color: 'white'
  },
  container: {
    ...theme.container,
    display: 'flex',
    position: 'relative'
  },
  button: {
    position: 'absolute',
    transform: "translateX(-120%)"
  },
  icon: {
    color: "white",
  }
}))

const Header = ({ title, backButton }) => {
  const classes = useStyles()
  const history = useHistory()
  const rbacStore = useRbacStore()

  return (
    <div className={classes.root} style={{ backgroundColor: rbacStore.canPerformSysAdminActions ? '#3a3e4b' : '#3096FF'}}>
      <div className={classes.container}>
        {backButton &&
          <IconButton onClick={history.goBack} className={classes.button}>
            <ArrowBackRoundedIcon className={classes.icon} fontSize="large" />
          </IconButton>
        }
        <Typography variant='h1'>{title}</Typography>
      </div>
    </div >
  )
}

export default Header
