import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Nav from "../components/common/Nav";
import SearchInput from "../components/common/SearchInput";
import Dropdown from "../components/common/Dropdown";
import OutlinedButton from "../components/common/OutlinedButton";
import { Loader } from "google-maps";
import { useGroupStore } from "../state";
import ClubEntry from "../components/clubs/ClubEntryNew";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import PulseLoader from "react-spinners/PulseLoader";

const API_KEY = "AIzaSyBZImHOLK7jrB8m6zHOkprqTfatcMYlhO8";

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container, textAlign: "center" },
  blueBackground: {
    background: "#399aff",
    position: "relative",
    top: 70,
  },
  title: {
    margin: "20px 0",
    fontSize: 24,
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
}));

const Clubs = () => {
  const classes = useStyles();
  const groupStore = useGroupStore();

  const handleChange = (event) => {
    setSearchCountry(event.target.value)
  };

  const { publicClubs } = groupStore;
  const clubRef = useRef(null);

  var centreLat = -41.2919;
  var centreLong = 174.7767;
  var recentClubCount = 0;

//  const hastAtLeastThreeMembers = (club) =>
//    club.groupMembers && club.groupMembers.length > 2;
//  const postJune2021 = (club) => {
//    return (moment(club.createdAt) >= moment('1 July 2021'));
//  }
  const isClose = (id) => {
    return ((typeof distances[id] !== "undefined") && (distances[id] <= minDistance));
  }
  const isLessClose = (id) => {
    // now shows most recent clubs instead
    if (recentClubCount < 20) {
      if ((typeof distances[id] === "undefined") || (distances[id] > minDistance)) {
        recentClubCount++;
        return true;
      }
    }
//    return ((typeof distances[id] !== "undefined") && (distances[id] > minDistance) && (distances[id] <= (minDistance + 20)));
  }

  const [google, setGoogle] = React.useState();
  const mapRef = useRef(null);
  const [geocoder, setGeocoder] = React.useState();
  const [infowindow, setInfowindow] = React.useState();
  const [searchVal, setSearchVal] = React.useState('');
  const [searchCountry, setSearchCountry] = React.useState('nz');
  const [distances, setDistances] = React.useState({});
  const [minDistance, setMinDistance] = React.useState(5);

  var mapMoveTimeout;
  var mapZoomTimeout;

  useEffect(() => {
    groupStore.setCurrentClub();

    navigator.geolocation?.getCurrentPosition(function(position) {
      if (position.coords?.latitude && (centreLat === -41.2919) && (centreLong === 174.7767)) {
        goToLoc(position.coords.latitude, position.coords.longitude);
      }
    });  
  }, [])
    
  const loadMap = async () => {
    const loader = new Loader(API_KEY, { libraries: ["places"] });

    const googleTemp = await loader.load()
    setGoogle(googleTemp);
    const mapTemp = new googleTemp.maps.Map(document.getElementById("map"), {
      center: { lat: centreLat, lng: centreLong },
      zoom: 13,
    })

    let noPoi = [{
      featureType: "poi",
      stylers: [{visibility: "off"}]
    }];
    mapTemp.setOptions({styles: noPoi});

    mapTemp.addListener("zoom_changed", () => {
        // wait a second to ensure finished moving before adjusting filter
      clearTimeout(mapZoomTimeout);
      mapZoomTimeout = window.setTimeout(updateMinDistance, 1000);
    });

    mapTemp.addListener("center_changed", updateCentre);
    
    mapRef.current = mapTemp;
    updateMinDistance();

    let infowindowTemp = new googleTemp.maps.InfoWindow({
    });
    setInfowindow(infowindowTemp)

    if (publicClubs) {
      addMarkers();
    }
  };

  useEffect(loadMap, []);

  const updateMinDistance = () => {
    let newMin = Math.min(1000, Math.pow(2, 16 - mapRef.current.zoom));
    setMinDistance(newMin);
  }

  const updateCentre = () => {
    let newCentre = mapRef.current.getCenter();
    centreLat = newCentre.lat();
    centreLong = newCentre.lng();
    clearTimeout(mapMoveTimeout);
    // wait a second to ensure finished moving before recalculating distances
    mapMoveTimeout = window.setTimeout(calcDistances, 1000);
  }

  const addMarkers = async () => {
    setLoading(true);
    if (google !== undefined) {
      publicClubs
//        .filter((club) => hastAtLeastThreeMembers(club))
//        .filter((club) => postJune2021(club))
        .map((club, i) => {
          if (club.mapLat && club.mapLong) {
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(club.mapLat, club.mapLong),
              title: club.name
            });
            marker.setMap(mapRef.current);
            marker.addListener("click", () => {
              infowindow.setContent("<a href='/group/" + club.id + "'><h1>" + club.name + "</h1></a>")
              infowindow.open(mapRef.current, marker);
            });          
          }
        })
    }
    clubRef.current = publicClubs
    calcDistances();
  }

  useEffect(addMarkers, [publicClubs]);

  const goToLoc = (mapLat, mapLong) => {
    if (mapLat && mapLong) {
      mapRef.current?.panTo({ lat: mapLat, lng: mapLong });
    }
  }

  const searchMap = () => {
    if (searchVal !== '') {
      let geocoderTemp = geocoder
      if (geocoderTemp == undefined) {
        geocoderTemp = new google.maps.Geocoder()
        setGeocoder(geocoderTemp)
      }
      geocoderTemp.geocode( { 'address': searchVal, 'region': searchCountry }, function(results, status) {
        if (status == 'OK') {
          goToLoc(results[0].geometry.location.lat(), results[0].geometry.location.lng())
        } else {
          console.log('Geocode was not successful for the following reason: ' + status)
        }
      })
    }
  }

  const calcDistances = () => {
    setLoading(true);
    let newDistances = {};
    clubRef.current?.forEach((club) => {
      let iDist = 9999;
      if (club.mapLat != null && club.mapLong != null) {
        if ((club.mapLat == centreLat) && (club.mapLong == centreLong)) {
          iDist = 0;
        } else {
          let dLat = (centreLat - club.mapLat) * Math.PI / 180;
          let dLon = (centreLong - club.mapLong) * Math.PI / 180;
          let a = Math.sin(dLat/2) * Math.sin(dLat/2) 
            + Math.cos(club.mapLat * Math.PI / 180) * Math.cos(centreLat * Math.PI / 180) 
            * Math.sin(dLon/2) * Math.sin(dLon/2); 
          let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
          iDist = c * 6371;  // Radius of the earth in km
        }
      }
      newDistances[club.id] = Math.round(iDist * 10) / 10;
    });
    setDistances(newDistances);
  }

  const getClubEntry = (club, i) => {
    groupStore.setCurrentClub(club.id);
    const nextTerm = groupStore.getNextTerm();
    var members = "N/A";
    var nextDate = "Not scheduled"
    if (nextTerm) {
      if (nextTerm.termSessions?.length) {
        nextDate = moment(nextTerm.termSessions[0].sessionDate).format("Do MMMM");
      }
      members = nextTerm.termMembers?.length.toString();
    }

    return (
    <Grid item xs={6} key={i} className={classes.clubCard}>
      <ClubEntry
        key={club.id}
        i={i}
        id={club.id}
        icon={club.name.substring(0, 1).toUpperCase() || "U"}
        name={club.name.length ? club.name : "Untitled Club"}
        nextTerm={nextDate}
        organizer={club.organiserName}
        createdAt={club.createdAt}
        destination={club.location}
        groupMembers={members}
        distance={distances[club.id]}
        mapLat = {club.mapLat}
        mapLong = {club.mapLong}
        iconClick={goToLoc}
      />
    </Grid>
    )
  }

  const [loading, setLoading] = React.useState(true);
  const [loadingStarted, setLoadingStarted] = React.useState(false);
  const [nearClubs, setNearClubs] = React.useState([]);
  const [nearishClubs, setNearishClubs] = React.useState([]);

useEffect(() => {
  if (groupStore.loadingClubs) {
    setLoadingStarted(true);
  } else if (loadingStarted) {
    setNearClubs(publicClubs
                .filter((club) => isClose(club.id))
                .map((club, i) => (getClubEntry(club, i))));
    setNearishClubs(publicClubs.slice()
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .filter((club) => isLessClose(club.id))
                .map((club, i) => (getClubEntry(club, i))));
    setLoading(false);
  }
}, [groupStore.loadingClubs, distances, minDistance]);

  return (
    <>
      <Nav />
      <div className={classes.blueBackground}>
        <div className={classes.container}>
          <Typography className={classes.title}>
            Find a Game Dev Club near you
          </Typography>
          <div className={classes.flexWrapper}>
            <Dropdown value={searchCountry} handleChange={handleChange}>
            <MenuItem value="nz">New Zealand</MenuItem>
            {// <MenuItem value="au">Australia</MenuItem>
            }
            </Dropdown>
            <SearchInput
              value={searchVal}
              onInput={e => setSearchVal(e.target.value)}
              onKeyPress={e => {if (e.code === 'Enter') searchMap()}}
             />
            <OutlinedButton
              style={{ borderRadius: 20, marginLeft: 8 }}
              color="#FFF"
              onClick={searchMap}
            >
              Search
            </OutlinedButton>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div
          style={{
            width: "100%",
            marginTop: 80,
            marginBottom: 40,
            height: 400,
          }}
          id="map"
        ></div>
          <Typography variant="h2">
            Nearby Game Dev Clubs
          </Typography>
        <Grid container spacing={2} className={classes.gridContainer}>
          {loading && (
                <Grid item xs={12} className={classes.clubsLoading}>
                  <PulseLoader
                    loading={true}
                    color="rgb(67, 196, 255)"
                    width={180}
                  />
                </Grid>
          )}
          {(!loading && (nearClubs != "")) ? 
            nearClubs
          : 
            !loading &&
              <p>No clubs are near the current location.</p>
          }
        </Grid>
        {(!loading && (nearishClubs != "")) &&
          <>
            <Typography variant="h2">
              Other recently added Clubs
            </Typography>
            <Grid container spacing={2} className={classes.gridContainer}>
              {nearishClubs}
            </Grid>
          </>
        }
      </div>
    </>
  );
};

export default observer(Clubs);
