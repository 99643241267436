// logic imports
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
// global state
import { useGroupStore, useTermStore, useUserStore } from "../../state";
// material-ui component imports
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
// images/SVG imports
import CloseIcon from "@material-ui/icons/CloseRounded";
import EmailIcon from "@material-ui/icons/Email";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PulseLoader from 'react-spinners/PulseLoader'

// component scope styles
const useStyles = makeStyles(theme =>({
  title: {
    fontSize: "1.5rem",
    paddingLeft: 2,
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
  },
  closeButton: {
    marginLeft: "auto",
    marginRight: 0,
    color: "#ffffff",
    padding: 0,
  },
  sendButton: {
    float: "right",
  },
  topGrid: {
    marginTop: 10,
    marginBottom: 12,
  },
  mainGrid: {
    marginTop: 70,
  },
  bottomMargin: {
    marginBottom: 10,
  },
  popupTitle: {
    margin: "10px 30px -5px 30px",
    fontFamily: "'Fredoka One', cursive",
    WebkitFontSmoothing: "antialiased",
    fontSize: "1.5rem",
  },
  popupImage: {

  },
  popupCloseIcon: {
    position: "absolute", 
    top: 0, 
    right: 0,
  },
}));

/**fullscreen modal for use in owner dashboard broadcast email functionality*/
const OwnerClubEmailModal = (props) => {
  // hooks
  const classes = useStyles();
  const groupStore = useGroupStore();
  const termStore = useTermStore();
  const userStore = useUserStore();
  
  // local state
  const [tutorsEmails, setTutorsEmails] = React.useState(new Set())
  const [guardiansEmails, setGuardiansEmails] = React.useState(new Set())
  const [recipients, setRecipients] = React.useState(new Set());
  const [subject, setSubject] = React.useState("");
  const [body, setBody] = React.useState("");
  const [showingStatus, setShowingStatus] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [sending, setSending] = React.useState(false);

  const [isCheckedTutors, setIsCheckedTutors] = React.useState(true)
  const [isCheckedGuardians, setIsCheckedGuardians] = React.useState(true)
  const [isCheckedMe, setIsCheckedMe] = React.useState(true)
  const [recipientsMessage,setRecipientsMessage] = React.useState("")
  
  //  commented out as we believe from testing global state will be populated
  // TODO: remove commented code if no bugs show up after further testing
  // // ensure global state is populated
  // React.useEffect(() => {
  //   groupStore.getGroupMembers(groupStore.currentClub.id, true)
  // },[groupStore, props.passedToggleState])

  React.useEffect(()=>{
    const getData = async () => {  
      if (termStore.currentTerm) {
        tutorsEmails.clear();
        termStore.currentTerm.termMembers?.forEach(member => {
          if (member.role == "tutor" && member.user.email?.value) {
            tutorsEmails.add(member.user.email.value);
          } 
        });
        let guardians = await termStore.getTermGuardians(termStore.currentTerm.id);
        guardians.forEach(guardian => {
          if (guardian.user2?.email?.value) {
            guardiansEmails.add(guardian.user2.email.value);
          }
        })
      }
      getRecipients();
    };
    getData();
  },[termStore.currentTerm])


  React.useEffect(()=>{
    getRecipients();
    if(isCheckedTutors && isCheckedGuardians){
      setRecipientsMessage(`Send an email to all tutors and guardians associated with this Term`)
    } else if(isCheckedTutors && !isCheckedGuardians){
      setRecipientsMessage(`Send an email to all tutors associated with this Term`)
    } else if(!isCheckedTutors && isCheckedGuardians){
      setRecipientsMessage(`Send an email to all guardians associated with this Term`)
    } else {
      setRecipientsMessage('No recipients are selected')
    }
  },[isCheckedTutors, isCheckedGuardians]);

  // validation
  const validateHasRecipients = () => {
    // validation assumes sending an email addressed to more users than only the user sending it
    if ( isCheckedMe ) {
      // exclude sender from calculation 
      recipients.size <= 1 && setErrorMessage("You need at least one recipient to send")
      return !!(recipients.size > 1)
    } else {
      // assume all none sender email address are taken into account
      !recipients.size && setErrorMessage("You need at least one recipient to send")
      return !!recipients.size
    }
  };

  const validateHasSubject = () => {
    !subject.length && setErrorMessage("You need to enter a subject")
    return !!subject.length
  };

  const validateHasText = () => {
    !body.length && setErrorMessage("You can not send a blank email")
    return !!body.length
  };

  // supporting functions
  const queueEmails = async () => {
    setErrorMessage("");
    setSending(true);
    try {
    // map through recipients array
    await Promise.all([...recipients].map(async (recipient) => {
      // send email to each recipient
      return userStore.sendEmail(recipient, subject, body, "");
    }));
  } catch(err) {
    setErrorMessage(err.message);
  } finally {
    setSending(false);
  }
  };

  /** gather recipients email address into one list including user sending email and selected members */
  const getRecipients = () => {
    // create new set
    const emails = new Set();
    // add users email if selected
    isCheckedMe && !!userStore.currentUser.email?.value && emails.add(userStore.currentUser.email?.value);
    // add guardians emails if selected
    isCheckedGuardians && guardiansEmails.forEach(email => emails.add(email));
    // add tutors emails if selected
    isCheckedTutors && tutorsEmails.forEach(email => emails.add(email));
    // update local state
    setRecipients(emails);
  };

  // event handlers
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleBodyChange = (e) => {
    setBody(e.target.value);
  };

  const handleCheckToggle = (e) => {
    const value = e.target.value
    value === "tutors" && setIsCheckedTutors(!isCheckedTutors);
    value === "guardians" && setIsCheckedGuardians(!isCheckedGuardians);
    value === "me" && setIsCheckedMe(!isCheckedMe);
    getRecipients();
  };

  const handleSend = () => {
    if (
      // validate email has recipients
      validateHasRecipients() &&
      // validate has a subject
      validateHasSubject() &&
      // validate email is not blank
      validateHasText()
    ) {
      // queue then send emails
      queueEmails();
      // display status
      setShowingStatus(true);
    }
  };

  const handleClose = () => {
    // clean up local state
    setRecipients(new Set());
    setBody("");
    setSubject("");
    setShowingStatus(false);
    setErrorMessage(null);
    setTutorsEmails(new Set());
    setGuardiansEmails(new Set());
    // close modal
    props.passedCloseFunction();
  };

  // render statement
  return (
    <>
      <Dialog
        fullScreen
        open={props.passedToggleState}
        onClose={handleClose}
      >
        <AppBar >
          <Grid container className={classes.topGrid}>
            <Grid item xs={false} sm={1} md={2}></Grid>
            <Grid item xs={12} sm={10} md={8}>
              <Grid 
                container
                direction="row"
                alignItems="center"
              >
                <Typography className={classes.title}>Send Email to {groupStore.currentClub.name}</Typography>
                <IconButton 
                  onClick={handleClose} 
                  className={classes.closeButton} 
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={false} sm={1} md={2}></Grid>
          </Grid>
        </AppBar>

        <Grid container className={classes.mainGrid}>
          <Grid item xs={false} sm={1} md={2}></Grid>
          <Grid item xs={12} sm={10} md={8}>
            
            <FormGroup row>
              <FormControlLabel control={<Checkbox color="primary" defaultChecked value={"tutors"} onChange={handleCheckToggle}/>} label="tutors" />
              <FormControlLabel control={<Checkbox color="primary" defaultChecked value={"guardians"} onChange={handleCheckToggle} />} label="guardians" />
              <FormControlLabel control={<Checkbox color="primary" defaultChecked value={"me"} onChange={handleCheckToggle} />} label="send me a copy" />
            </FormGroup>
            
            <Typography className={classes.bottomMargin}>{recipientsMessage}</Typography>

            <TextField 
              value={subject}
              fullWidth 
              color="primary" 
              variant="outlined" 
              focused
              className={classes.bottomMargin}
              onChange={handleSubjectChange}
              label="subject"
            />

            <TextField 
              value={body}
              multiline 
              rows={15} 
              fullWidth 
              color="primary" 
              variant="outlined" 
              focused
              className={classes.bottomMargin}
              onChange={handleBodyChange}
              label="email"
            />

            <Button color="primary" className={classes.sendButton} onClick={handleSend}>
              <EmailIcon/>
              Send Email
            </Button>
            {!!errorMessage && <Typography color="error">{errorMessage}</Typography>}
          </Grid>
          <Grid item xs={false} sm={1} md={2}></Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={showingStatus}
        onClose={handleClose}
      >
          <IconButton 
            onClick={handleClose} 
            color="primary"
            className={classes.popupCloseIcon}
          >
            <CloseIcon />
          </IconButton>
        <DialogTitle className={classes.popupTitle}>{(sending
          ? "Sending Emails"
          : "Emails Sent"
        )}</DialogTitle>
        <DialogContent style={{textAlign: "center"}}>
        {(sending
          ? <PulseLoader loading={true} color="rgb(67, 196, 255)" />
          : (errorMessage 
            ? errorMessage
            : <CheckCircleIcon style={{fontSize: 50, color: "#64dd17"}} />
          )
        )}
          
        </DialogContent>
        <DialogActions>
          <Button style={{margin: "auto",}} variant="contained" color="primary" onClick={handleClose} disabled={sending}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OwnerClubEmailModal;
